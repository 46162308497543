import useApi from "../../../../Utils/BackendClient";
import { useContext, useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { FloatLabel } from "primereact/floatlabel";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";

import commonStyle from "../../CommonStyles.module.css";
import baseUserPic from "../../../../images/Random_user_pic.png";
import { OrgInfoContext } from "../../../../context/OrgInfoProvider";

const Organization = () => {

    const { fetchOrganizationDetails, uploadOrganizationLogo } = useApi();
    const toast = useRef(null);
    const orgCtx = useContext(OrgInfoContext);

    const [showUploadMenu, setShowUploadMenu] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [organizationDetails, setOrganizationDetails] = useState({
        logo_url: "",
        name:"",
        email:"",
        phone:"",
        contact_person:""
    });

    useEffect(() => {

        const getOrganizatiionDetails = async () => {

            const response = await fetchOrganizationDetails();
            if(response.status === 200){

                if(response.data?.logo_url === null){
                    response.data.logo_url = baseUserPic;
                }
                
                setOrganizationDetails(response.data);
            }
            else{
                if (response.status === 403) {
                    toast.current.show({ severity: "error", summary: "Помилка відображення організацій", detail: "Користувач не має прав переглядати цю сторінку", life: 3000 });
                }
                else {
                    toast.current.show({ severity: "error", summary: "Помилка відображення організації", detail: "Помилка сервера " + response.status, life: 3000 });
                }
            }
        }

        getOrganizatiionDetails();

    }, [fetchOrganizationDetails, reloadData]);

    const Upload = uploadEvent => {

        const callBackend = async () => {

            let formData = new FormData();
            formData.append('logo', uploadEvent.files[0], uploadEvent.files[0].name)

            const response = await uploadOrganizationLogo(formData);

            if(response.status === 201){
                orgCtx.loadOrgData();
                setReloadData(!reloadData);
            }
            else{
                if (response.status === 403) {
                    toast.current.show({ severity: "error", summary: "Помилка завантаження лого", detail: "Користувач не має прав змінювати лого", life: 3000 });
                }
                else {
                    toast.current.show({ severity: "error", summary: "Помилка завантаження лого", detail: "Помилка сервера " + response.status, life: 3000 });
                }
            }
        }

        callBackend();
        setShowUploadMenu(false);
    }

    return (<>
    <Toast/>
    <div className={commonStyle.orgContent}>
        <div className="grid">
            <div className="col-3">
                <div className={commonStyle.portraitdiv}>
                    <img className={commonStyle.portrait} alt={organizationDetails?.name} src={organizationDetails?.logo_url} style={{ borderRadius: "50%" }} />
                </div>
                {!showUploadMenu && <div className="text-center">
                    <Tooltip target=".custom-changelogo-btn" content="Змінити лого" position="left" />
                    <Tooltip target=".custom-deletelogo-btn" content="Видалити лого" position="right" />
                    <Button icon="pi pi-arrow-right-arrow-left" className={`p-button-rounded p-button-danger ${commonStyle.editButton} mt-2 custom-changelogo-btn`} onClick={() => setShowUploadMenu(true)} />
                </div>}
                {showUploadMenu && <div className="text-center mt-3">
                    <FileUpload
                        mode="basic"
                        name="demo[]"
                        accept="image/*"
                        maxFileSize={1000000}
                        customUpload
                        uploadHandler={Upload}
                    >
                    </FileUpload>
                </div>}
            </div>
            <div className="col-3">
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText
                            value={organizationDetails?.name}
                        />
                        <label>Назва організації</label>
                    </FloatLabel>
                </div>
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText
                            value={organizationDetails?.email}
                        />
                        <label>E-mail</label>
                    </FloatLabel>
                </div>
            </div>
            <div className="col-3">
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText
                            value={organizationDetails?.phone}
                        />
                        <label>Телефон</label>
                    </FloatLabel>
                </div>
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText
                            value={organizationDetails?.contact_person}
                        />
                        <label>Відповідальна особа</label>
                    </FloatLabel>
                </div>
            </div>
        </div>
    </div>
    </>)
}

export default Organization;