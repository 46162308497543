import Cookies from "js-cookie";
import EnumsContext from "../../../../context/enums-context";
import { buildGroupHierarchy, convertSumWithLatestCurrencyRate, findKeyByValue, formatCurrency } from "../../../../Utils/Utils";
import { Guid } from "js-guid";
import { useContext, useEffect, useRef, useState } from "react";
import useGetEnums from "../../../../Utils/EnumsUtils";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { TreeSelect } from "primereact/treeselect";
import { Sidebar } from "primereact/sidebar";

import commonStyle from "../../CommonStyles.module.css";
import useApi from "../../../../Utils/BackendClient";
import { TabMenu } from "primereact/tabmenu";
import HistoryElement from "../../../UIElements/HistoryElement";

const NewSale = () => {

    const currencies = ["UAH", "USD", "EUR"];
    const ctx = useContext(EnumsContext);
    const navigate = useNavigate();
    const location = useLocation();
    const toast = useRef(null);
    const { createSaleAct, deletePaymentFromAct, fetchActPayments, fetchProductsExtended, fetchRemainings, fetchSaleActDetails, fetchSaleActHistory, fetchServices, fetchServicesExtended, updateSaleAct} = useApi();

    const [visible, setVisible] = useState(false);
    const [showProductChooseMenu, setProductChooseMenu] = useState(false);
    const [isProducts, setIsProducts] = useState(false);
    const [productGroups, setProductGroups] = useState([]);
    const [serviceGroups, setServiceGroups] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showfields, setShowFields] = useState(false);
    const [unChangedRemainings, setUnChangedRemainings] = useState([]);
    const [products, setProducts] = useState();
    const [services, setServices] = useState();
    const [productsTotalRecords, setProductsTotalRecords] = useState(0);
    const [servicesTotalRecords, setServicesTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isFinalized, setIsFinalized] = useState(false);
    const [totaLActSum, setTotalActSum] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [isEdit, setIsEdit] = useState(false);
    const [actPayments, setActPayments] = useState(null);
    const [actHistory, setActHistory] = useState(null);
    const [historyTotalRecords, setHistoryTotalRecords] = useState(0);
    const [tabShowed, setTabShowed] = useState(0);
    const [actData, setActData] = useState
        ({
            cagent: null,
            division: Cookies.get("division"),
            act_date: new Date(),
            act_number_autogenerate: true,
            act_number: "",
            discount: [],
            pay_due_date: new Date(),
            status: "Драфт",
            unidentified_products: [],
            identified_products: [],
            services: []
        });
    const [productLazyState, setProductlazyState] = useState(
        {
            first: 0,
            rows: 20,
            page: 1,
            sortField: "created_at",
            sortOrder: 1,
            filters: {
                product_group: { value: null, matchMode: 'contains' },
            }
        }
    );
    const [serviceLazyState, setServicelazyState] = useState(
        {
            first: 0,
            rows: 20,
            page: 1,
            sortField: "created_at",
            sortOrder: 1,
            filters: {
                service_group: { value: null, matchMode: 'contains' },
            }
        }
    );
    const [historyLazyState, setHistoryLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1   
    });

    useGetEnums(["divisions", "clients"]);

    useEffect(() => {

        const loadProductGroups = async () => {
            const response = await fetchProductsExtended();

            if (response.status === 200) {
                const groups = buildGroupHierarchy(response.data);
                setProductGroups(groups);
            }
        }

        const loadServiceGroups = async () => {
            const response = await fetchServicesExtended();

            if (response.status === 200) {
                const groups = buildGroupHierarchy(response.data);
                setServiceGroups(groups);
            }
        }

        loadProductGroups();
        loadServiceGroups();
    }, [fetchProductsExtended, fetchServicesExtended]);

    useEffect(() => {
        if (location.state !== null) {
            console.log(location.state);
            if (location.state.id != null) {

                setIsEdit(true);

                const fetchData = async () => {

                    const response = await fetchSaleActDetails(location.state.id);

                    if(response.status === 200){

                        setIsFinalized(response.data.status !== "PENDING");
                        response.data.act_date = new Date(response.data.act_date);
                        response.data.pay_due_date = new Date(response.data.pay_due_date);
    
                        let totalValue = 0
                        if (response.data.identified_products.length > 0) {
                            totalValue = totalValue + response.data.identified_products.reduce((accumulator, element) => accumulator + element.priceUAH, 0);
                            response.data.identified_products.forEach(element => {
                                element.fe_id = Guid.newGuid().toString();
                                element.totalPrice = element.price;
                                element.totalPriceUAH = element.priceUAH;
                            });
                        }
                        if (response.data.unidentified_products.length > 0) {
                            response.data.totalPrice = response.data.price * response.data.quantity;
                            totalValue = totalValue + response.data.unidentified_products.reduce((accumulator, element) => accumulator + element.priceUAH * element.quantity, 0);
                            response.data.unidentified_products.forEach(element => {
                                element.fe_id = Guid.newGuid().toString();
                                element.totalPrice = element.price * element.quantity;
                                element.totalPriceUAH = element.priceUAH * element.quantity;
                            });
                        }
                        if (response.data.services.length > 0) {
                            totalValue = totalValue + response.data.services.reduce((accumulator, element) => accumulator + element.priceUAH, 0);
                            response.data.services.forEach(element => {
                                element.fe_id = Guid.newGuid().toString();
                                element.totalPrice = element.price;
                                element.totalPriceUAH = element.priceUAH;
                            });
                        }
    
                        let discount = 0;
                        console.log(response.data.discount);
                        if (response.data.discount.length > 0) {
                            discount = response.data.discount.reduce((accumulator, element) => accumulator + convertSumWithLatestCurrencyRate(element.currency, element.amount), 0);
                        }
    
                        setTotalActSum(totalValue);
                        setTotalDiscount(discount);
                        setActData(response.data);
                    }
                }

                fetchData();
            }
            else {
                let saleActData = {
                    cagent: null,
                    division: Cookies.get("division"),
                    currency: "UAH",
                    act_date: new Date(),
                    act_number_autogenerate: true,
                    act_number: "",
                    discount: [],
                    pay_due_date: new Date(),
                    status: "Драфт",
                    unidentified_products: [],
                    identified_products: [],
                    services: []
                };

                const item = location.state.item;

                item.fe_id = Guid.newGuid().toString();
                item.product = item.product_name;
                item.price = +item.sale_price;
                item.currency = item.sale_price_currency;
                item.priceUAH = convertSumWithLatestCurrencyRate(item.sale_price_currency, item.price)
                item.remaining_id = item.id;

                if (location.state.item.identifier !== undefined) {
                    item.totalPrice = +item.sale_price;
                    item.totalPriceUAH = item.priceUAH;
                    saleActData.identified_products = [item];
                    setTotalActSum(item.priceUAH);
                }
                else {
                    item.totalPrice = +item.sale_price * item.quantity;
                    item.totalPriceUAH = item.priceUAH * item.quantity;
                    saleActData.unidentified_products = [item];
                    setTotalActSum(item.priceUAH * item.quantity);
                }

                console.log(item);

                setActData(saleActData);
            }

        }
    }, [location.state, fetchSaleActDetails]);

    const formik = useFormik({
        initialValues: actData,
        enableReinitialize: true,
        onSubmit: (values) => {

            function transformDate(date) {
                const day = ('0' + date.getDate()).slice(-2); // Add leading zero if needed
                const month = ('0' + (date.getMonth() + 1)).slice(-2); // Month is zero-based, so add 1
                const year = date.getFullYear();

                const formattedDate = `${year}-${month}-${day}`;

                return formattedDate
            }

            function transformIdProducts(data) {

                console.log(data);

                return data.map(product => {

                    if (product.remaining_id === undefined) {
                        const index = unChangedRemainings.findIndex(function (field) {
                            return field.identifier === product.identifier;
                        });

                        product.remaining_id = unChangedRemainings[index].id;
                    }
                    delete product.id;
                    delete product.product;
                    delete product.fe_id;
                    delete product.totalPrice;
                    delete product.totalPriceUAH;
                    delete product.identifier;

                    return product;
                });
            }

            function transformUnIdProducts(data) {

                return data.map(product => {
                    if (product.isNew !== undefined) {
                        product.remaining_id = product.id;
                    }

                    delete product.id;
                    delete product.fe_id;
                    delete product.totalPrice;
                    delete product.totalPriceUAH;
                    delete product.isNew;

                    return product;
                });
            }

            function transFormServices(data) {
                return data.map(product => {
                    if (product.isNew !== undefined) {
                        product.service_id = product.id;
                    }

                    delete product.id;
                    delete product.product;
                    delete product.fe_id;

                    return product;
                });
            }

            values.act_date = transformDate(values.act_date);
            values.pay_due_date = transformDate(values.pay_due_date);
            values.identified_products = transformIdProducts(values.identified_products);
            if (values.act_number_autogenerate) {
                delete values.act_number;
            }
            values.unidentified_products = transformUnIdProducts(values.unidentified_products);
            values.services = transFormServices(values.services);
            console.log(values);

            const saveChanges = async () => {

                const response = isEdit ? await updateSaleAct(location.state.id, values) : await createSaleAct(values);
                const successStatus = isEdit ? 200 : 201;

                let toastMessage = {
                    severity: "error", summary: isEdit ? "Помилка редагування" : "Помилка створення",
                    detail: "Акт не " + (isEdit ? "відредаговано. " : " створено. ") + " Код помилки: " + response.status, life: 3000
                };

                if(response.status === successStatus){
                    toastMessage = isEdit ? { severity: "success", summary: "Успіх редагування", detail: "Акт успішно відредаговано", life: 3000 } :
                    { severity: "success", summary: "Успіх створення", detail: "Акт успішно створено", life: 3000 }
                }

                navigate("/sales", { state: { toast: toastMessage } });
            }

            saveChanges();
        }
    });

    const addDiscountHandler = () => {
        const discount = [
            ...formik.values.discount,
            { currency: "UAH", amount: 0 }
        ]

        formik.setFieldValue('discount', discount);
    }

    const handleCurrencyChange = (index, value) => {
        const discount = [...formik.values.discount];
        discount[index].currency = value;
        formik.setFieldValue('discount', discount);

        const discountValue = discount.reduce((accumulator, element) => accumulator + convertSumWithLatestCurrencyRate(element.currency, element.amount), 0);

        setTotalDiscount(discountValue);
    };

    const handleAmountChange = (index, value) => {
        const amount = parseFloat(value) || 0;

        const discount = [...formik.values.discount];
        discount[index].amount = amount;
        formik.setFieldValue('discount', discount);
        console.log(discount);
        const discountValue = discount.reduce((accumulator, element) => accumulator + convertSumWithLatestCurrencyRate(element.currency, element.amount), 0);
        console.log(discountValue);
        setTotalDiscount(discountValue);
    };

    const sideBarFormik = useFormik({
        initialValues: {
            type: '',
            product: "",
            product_group: "",
            id: "",
            identifier: "",
            quantity: "",
            currency: "",
            itemPrice: "",
            price: "",
            totalPrice: "",
            priceUAH: "",
            totalPriceUAH: "",
            discount: []
        },
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (values.type !== "SERVICE") {
                if (values.type === "PIECE") {
                    if (values.quantity === undefined) {
                        errors.quantity = "Введіть кількість товару"
                    }
                    else if (+values.quantity <= 0) {
                        errors.quantity = "Кількість товарів не повинна бути меншою від 1 шт."
                    }
                    else if (+values.total_quantity < +values.quantity) {
                        errors.quantity = "Кількість товарів не повинна бути більшою ніж на складі: " + +values.total_quantity
                    }
                }

                if (values.price === "" || +values.price <= 0) {
                    errors.price = "Введіть вартість товару вищу від 0"
                }

                if (values.priceUAH === "" || +values.priceUAH <= 0) {
                    errors.priceUAH = "Введіть вартість товару вищу від 0"
                }
            }

            return errors;
        },
        onSubmit: (values) => {

            let newValue = {
                id: values.id,
                fe_id: Guid.newGuid().toString(),
                product: values.product,
                currency: values.currency,
                price: +values.price,
                totalPrice: +values.totalPrice,
                priceUAH: convertSumWithLatestCurrencyRate(values.currency, +values.price),
                totalPriceUAH: + values.totalPriceUAH,
            };

            console.log(newValue);

            if (values.type === "INDIVIDUAL") {
                newValue.identifier = values.identifier;
                newValue.remaining_id = values.remaining_id;
                //remove from remainings    
                const index = products.findIndex(function (field) {
                    return field.identifier === values.identifier;
                });
                products.splice(index, 1);

                const updatedIdentifiedProducts = [...formik.values.identified_products, newValue];
                formik.setFieldValue('identified_products', updatedIdentifiedProducts);
            }
            else if (values.type === "PIECE") {
                newValue.quantity = values.quantity;
                newValue.isNew = true;

                const index = products.findIndex(function (field) {
                    return field.product_name === newValue.product;
                });

                products[index].total_quantity = products[index].total_quantity - values.quantity;
                const updatedUnIdentifiedProducts = [...formik.values.unidentified_products, newValue];
                formik.setFieldValue('unidentified_products', updatedUnIdentifiedProducts);
            }
            else {
                newValue.service = values.product;
                newValue.isNew = true;
                const updatedServices = [...formik.values.services, newValue];
                formik.setFieldValue('services', updatedServices);
            }

            setTotalActSum(totaLActSum + +values.totalPriceUAH);

            setShowFields(false);
            setProductChooseMenu(true);
            sideBarFormik.resetForm();
        }
    })

    const DeleteFieldHandler = (rowData) => {

        const isId = Object.hasOwn(rowData, 'identifier');
        const isQuantity = Object.hasOwn(rowData, 'quantity');

        let fields = [];
        let name = '';

        if (isId) {
            fields = formik.values.identified_products;
            name = "identified_products";
        }
        else if (isQuantity) {
            fields = formik.values.unidentified_products;
            name = "unidentified_products";
        }
        else {
            fields = formik.values.services;
            name = "services";
        }

        const updatedFields = [...fields];

        const index = fields.findIndex(function (field) {
            return field.fe_id === rowData.fe_id;
        });

        if (isId) {
            const product = unChangedRemainings.find(product => product.identifier === fields[index].identifier);
            setProducts([...products, product]);
        }

        setTotalActSum(totaLActSum - +fields[index].totalPriceUAH);

        updatedFields.splice(index, 1);
        formik.setFieldValue(name, updatedFields);
    };

    const AddButtonHandler = (isProduct) => {
        setVisible(true);
        setProductChooseMenu(true);
        setIsProducts(isProduct);
    }

    const closeSideBarHandler = () => {
        setVisible(false);
        setShowFields(false);
        setSelectedItem(null);
        setProducts([]);
        setServices([]);
    }

    const setGroupValue = (id) => {
        setSelectedItem(id);
        setLoading(true);

        let filterGroup = null;

        if (id !== null && id !== undefined) {
            if (id.toString().includes('-')) {
                var keys = id.split('-');
                filterGroup = keys[(keys.length - 1)];
            }
            else {
                filterGroup = id;
            }
        }

        if(isProducts){
            productLazyState.filters.product_group.value = filterGroup;
        }
        else{
            serviceLazyState.filters.service_group.value = filterGroup;
        }

        const getGroupData = async () => {
            const response = isProducts ? await fetchRemainings(productLazyState) : await fetchServices(serviceLazyState);

            if (response.status === 200) {
                if (isProducts) {
                    const unchangedProducts = [...unChangedRemainings, ...response.data.results];
                    setUnChangedRemainings(unchangedProducts);
                    setProducts(response.data.results);
                    setProductsTotalRecords(response.data.count);
                }
                else {
                    setServices(response.data.results);
                    setServicesTotalRecords(response.data.count);
                }

                setLoading(false);
            }
        }

        getGroupData()
    }

    const onProductsPage = event => {
        event.page = event.page + 1;
        setProductlazyState(event);
    }

    const onServicePage = event => {
        event.page = event.page + 1;
        setServicelazyState(event);
    }

    const BackToProductsHandler = () => {
        setShowFields(false);
        setProductChooseMenu(true);

        sideBarFormik.resetForm();
    }

    const AddProductToTableHandler = rowData => {

        if (rowData.service_group != null) {
            sideBarFormik.setFieldValue('type', "SERVICE");
            sideBarFormik.setFieldValue('product', rowData.name);
            sideBarFormik.setFieldValue('currency', rowData.currency)
            sideBarFormik.setFieldValue('price', rowData.price)
            sideBarFormik.setFieldValue('totalPrice', rowData.price)
            sideBarFormik.setFieldValue('priceUAH', convertSumWithLatestCurrencyRate(rowData.currency, rowData.price))
            sideBarFormik.setFieldValue('totalPriceUAH', convertSumWithLatestCurrencyRate(rowData.currency, rowData.price))
        }
        else {
            sideBarFormik.setFieldValue('product', rowData.product_name);
            sideBarFormik.setFieldValue('currency', rowData.sale_price_currency)
            sideBarFormik.setFieldValue('price', rowData.sale_price)
            sideBarFormik.setFieldValue('totalPrice', rowData.sale_price)
            sideBarFormik.setFieldValue('priceUAH', convertSumWithLatestCurrencyRate(rowData.sale_price_currency, rowData.sale_price))
            sideBarFormik.setFieldValue('totalPriceUAH', convertSumWithLatestCurrencyRate(rowData.sale_price_currency, rowData.sale_price))

            if (rowData.identifier != null) {
                sideBarFormik.setFieldValue('type', "INDIVIDUAL");
                sideBarFormik.setFieldValue('identifier', rowData.identifier);
            }
            else {
                sideBarFormik.setFieldValue('type', "PIECE");
            }
        }

        sideBarFormik.setFieldValue('id', rowData.id);
        sideBarFormik.setFieldValue("quantity", rowData.quantity === undefined ? 1 : rowData.quantity);
        sideBarFormik.setFieldValue("total_quantity", rowData.total_quantity);

        setProductChooseMenu(false);
        setShowFields(true);
    }

    const setQuantity = (quantity) => {
        sideBarFormik.setFieldValue("quantity", quantity);
        const totalPrice = quantity * sideBarFormik.values.price
        sideBarFormik.setFieldValue("totalPrice", totalPrice);
        sideBarFormik.setFieldValue("totalPriceUAH", convertSumWithLatestCurrencyRate(sideBarFormik.values.currency, totalPrice));
    }

    const UnbindPayment = async (paymentId) => {

        const request = {
            act_id: location.state.id,
            payment_id: paymentId
        }

        const response = await deletePaymentFromAct(request);

        console.log(response);
        if(response.status === 204){
            
            const index = actPayments.findIndex(payment => payment.id === paymentId);
            if (index !== -1) {
                // Create a new array with the element removed
                const newPayments = [...actPayments.slice(0, index), ...actPayments.slice(index + 1)];
                console.log(newPayments);
                
                // Set the updated state
                setActPayments(newPayments);
            }

            toast.current.show({ severity: "success", summary: "Платіж відвязано", detail: "Платіж відвязано від акту", life: 3000 });

        }
        else{
            toast.current.show({ severity: "error", summary: "Помилка відвязки платежу", detail: "Платіж не відвязано від акту. Код помилки: " + response.status, life: 3000 });
        }
    }

    const getSaleActPayments = async (id) => {

        const response = await fetchActPayments(location.state.id);

        if (response.status === 200) {
            setActPayments(response.data.results);
        }
        else {
            toast.current.show({ severity: "error", summary: "Помилка отримання платежів", detail: "Помилка сервера " + response.status, life: 3000 });
        }
    }

    const getSaleActHistory = async (id, state) => {

        const response = await fetchSaleActHistory(id, state);

        console.log(response);

        if (response.status === 200) {
            setActHistory(response.data.results);
            setHistoryTotalRecords(response.data.count);
        }
        else {
            toast.current.show({ severity: "error", summary: "Помилка отримання історії", detail: "Помилка сервера " + response.status, life: 3000 });
        }
    }

    const onHistoryPage = (event) => {
        event.page = event.page + 1;
        getSaleActHistory(location.state.id, event);

        setHistoryLazyState(event);
    }

    const items = [
        {
            label: 'Деталі акту продажу',
            icon: 'pi pi-book',
            command: () => {
                setTabShowed(0);
            },
        },
        ...(isFinalized
            ? [
                {
                    label: 'Платежі',
                    icon: 'pi pi-money-bill',
                    command: () => {
                        setTabShowed(1);
                        getSaleActPayments(location.state.id);
                    },
                },
            ]
            : []),
        ...(isEdit
            ? [
                {
                    label: 'Історія',
                    icon: 'pi pi-history',
                    command: () => {
                        setTabShowed(2);
                        getSaleActHistory(location.state.id, historyLazyState);
                    },
                },
            ]
            : []),
    ];

    const mainToolbarLeftTemplate = () => {
        
        let name = "Створення нового замовлення";
        
        if(isEdit){
            name = "Редагування замовлення"

            if(isFinalized){
                name = "Інформація про замовлення"
            }
        }
        return (<h3>{name}</h3>);
    }

    const mainToolbarRightTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                {!isFinalized && <Button label="Зберегти" severity="success" className={commonStyle.addButton} type="submit" />}
                <Button label="Вийти" severity="secondary" className={commonStyle.closeButton} type="button" onClick={() => navigate("/sales")} />
            </div>
        );
    }

    const setTableHeader = (tablename) => {
        return (
            <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                <span className="text-xl text-900 font-bold">{tablename}</span>
            </div>
        );
    }

    const TableFooter = (data) => {

        const totalValue = data.reduce((accumulator, element) => accumulator + element.totalPriceUAH, 0);

        return (
            <div className={commonStyle.footerContainer}>
                <label>Cума: {formatCurrency(totalValue, "UAH")}</label>
            </div>)
    }

    const tableActionBodyTemplate = (rowData) => {
        return (<div className="flex flex-wrap gap-2">
            {!isFinalized && <Button
                icon="pi pi-trash"
                type="button"
                className={`p-button-rounded p-button-danger ${commonStyle.deleteButton}`}
                tooltip="Видалити з таблиці"
                tooltipOptions={{ showOnDisabled: true, position: "left" }}
                onClick={() => DeleteFieldHandler(rowData)}
            />}
        </div>
        );
    };

    const contentToolbarRightTemplate = () => {
        return (<>
            <div className="flex flex-wrap gap-2">
                <Button label="+Додати товар" severity="success" className={commonStyle.addButton} type="button" onClick={() => AddButtonHandler(true)} />
                <Button label="+Додати послугу" severity="success" className={commonStyle.addButton} type="button" onClick={() => AddButtonHandler(false)} />
            </div>
        </>);
    }

    const paymentActionBodyTemplate = (rowData) => {
        return (<Button
            icon="pi pi-times"
            type="button"
            className={`p-button-rounded p-button-danger ${commonStyle.deleteButton}`}
            tooltip="Відв'язати платіж"
            tooltipOptions={{ position: "top" }}
            onClick={() => UnbindPayment(rowData.id)}
        />
        );
    }

    const actionBodyTemplate = (rowData) => {

        const allItemsAreBooked = rowData.service_group === undefined && rowData.total_quantity === rowData.booked;

        return (<div className="flex flex-wrap gap-2">
            <Button
                icon="pi pi-plus"
                type="button"
                className={`p-button-rounded ${allItemsAreBooked ? commonStyle.closeButton : commonStyle.addButton}`}
                disabled={allItemsAreBooked}
                tooltip={allItemsAreBooked ? "Товар використано в акті" : "Додати"}
                tooltipOptions={{ showOnDisabled: true, position: "left" }}
                onClick={() => AddProductToTableHandler(rowData)}
            />
        </div>
        );
    };

    return (<form onSubmit={formik.handleSubmit}>
        <Toast ref={toast} />
        <Toolbar className="mb-1" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate}></Toolbar>
        <TabMenu model={items} />
        {tabShowed === 0 && <div className="grid mt-2">
            <div className={`${commonStyle.leftMenu} col-5 md:col-4 lg:col-3`}>
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <Dropdown value={ctx.divisions[formik.values.division]}
                                onChange={(e) => formik.setFieldValue('division', findKeyByValue(ctx.divisions, e.value))}
                                options={Object.values(ctx.divisions)}
                                disabled={isFinalized}
                                showClear
                                className={formik.errors.division && formik.touched.division ? 'p-invalid w-full' : "w-full"} />
                            <label>Підрозділ</label>
                        </FloatLabel>
                        {formik.errors.division && formik.touched.division && (<small className={commonStyle.errorSmall}>{formik.errors.division}</small>)}
                    </div>
                </div>
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <Dropdown value={ctx.clients[formik.values.cagent]}
                                onChange={(e) => formik.setFieldValue('cagent', findKeyByValue(ctx.clients, e.value))}
                                options={Object.values(ctx.clients)}
                                disabled={isFinalized}
                                showClear
                                className={formik.errors.cagent && formik.touched.cagent ? 'p-invalid w-full' : "w-full"} />
                            <label>Контрагент</label>
                        </FloatLabel>
                        {formik.errors.cagent && formik.touched.cagent && (<small className={commonStyle.errorSmall}>{formik.errors.cagent}</small>)}
                    </div>
                </div>
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-1">
                        <FloatLabel>
                            <Calendar value={formik.values.act_date}
                                onChange={(e) => { formik.setFieldValue('act_date', e.target.value) }}
                                disabled={isFinalized}
                                showIcon />
                            <label>Дата акту</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-1">
                        <FloatLabel>
                            <Calendar value={formik.values.pay_due_date}
                                disabled={isFinalized}
                                onChange={(e) => { formik.setFieldValue('pay_due_date', e.target.value) }}
                                showIcon />
                            <label>Очікувана дата оплати</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className={`${commonStyle.formInput} ${commonStyle.nomargin} flex flex-wrap p-2 align-items-center gap-3`}>
                    <div className="flex-1 flex flex-column gap-2">
                        <span className="font-bold">Знижка</span>
                    </div>
                    <Button
                        icon="pi pi-plus"
                        rounded
                        text
                        aria-label="Filter"
                        tooltip="Додати знижку"
                        disabled={isFinalized}
                        tooltipOptions={{ showOnDisabled: true }}
                        type="button"
                        onClick={addDiscountHandler}
                    />
                </div>
                {formik.values.discount.length > 0 && formik.values.discount.map((currencyAmount, index) => (
                    <div key={index} className={`${commonStyle.formInput} ${commonStyle.marginBottom}`}>
                        <div className="p-inputgroup flex-1">
                            <span className={`${commonStyle.currencySpan} p-inputgroup-addon`}>
                                <Dropdown
                                    disabled={isFinalized}
                                    className={`${commonStyle.currencyDropDown} w-full`}
                                    value={currencyAmount.currency}
                                    onChange={e => handleCurrencyChange(index, e.value)}
                                    options={currencies}
                                />
                            </span>
                            <FloatLabel>
                                <InputText
                                    onChange={e => handleAmountChange(index, e.target.value)}
                                    disabled={isFinalized}
                                    name="amount"
                                    value={currencyAmount.amount}
                                    className={formik.errors.price && formik.touched.price ? 'p-invalid' : ''}
                                />
                                <label></label>
                            </FloatLabel>
                            {formik.errors.price && formik.touched.price && (<small className={commonStyle.errorSmall}>{formik.errors.price}</small>)}
                        </div>
                    </div>))}
                {!isEdit && <div className={commonStyle.checkBoxInput}>
                    <div className={`${commonStyle.checkboxStyle} p-inputgroup flex-1 md:w-22rem"`}>
                        <Checkbox
                            name="act_number_autogenerate"
                            onChange={(e) => { formik.setFieldValue('act_number_autogenerate', !formik.values.act_number_autogenerate) }}
                            checked={formik.values.act_number_autogenerate}
                        />
                        <label>Автоматично генерувати номер акту</label>
                    </div>
                </div>}
                {!formik.values.act_number_autogenerate && <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <InputText value={formik.values.act_number}
                                onChange={(e) => { formik.setFieldValue('act_number', e.target.value) }}
                                disabled={isFinalized}
                                className={formik.errors.act_number && formik.touched.act_number ? 'p-invalid w-full' : "w-full"} />
                            <label>Номер акту</label>
                        </FloatLabel>
                        {formik.errors.act_number && formik.touched.act_number && (<small className={commonStyle.errorSmall}>{formik.errors.act_number}</small>)}
                    </div>
                </div>}
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <InputText value={formik.values.status === "FINALIZED" ? "Фіналізований" : "Драфт"}
                                disabled
                                className="w-full" />
                            <label>Статус</label>
                        </FloatLabel>
                    </div>
                </div>
            </div>
            <div className="col-7 md:col-8 lg:col-9">
                {!isFinalized && <Toolbar className="mb-4" end={contentToolbarRightTemplate}></Toolbar>}
                {formik.values.identified_products.length > 0 && <DataTable value={formik.values.identified_products}
                    header={setTableHeader("Товари індивідуального обліку")} footer={() => TableFooter(formik.values.identified_products)} tableStyle={{ minWidth: '50rem' }}>
                    <Column field="product" header="Продукт" style={{ width: '22%' }} />
                    <Column field="identifier" header="Ідентифікатор" style={{ width: '15%' }} />
                    <Column field="price" header="Ціна за одиницю" body={(rowdata) => formatCurrency(rowdata.price, rowdata.currency)} style={{ width: '15%' }} />
                    <Column field="priceUAH" header="Ціна за одиницю, грн" body={(rowdata) => formatCurrency(rowdata.priceUAH, "UAH")} style={{ width: '15%' }} />
                    <Column field="totalPrice" header="Загальна ціна" body={(rowData) => formatCurrency(rowData.totalPrice, rowData.currency)} style={{ width: '15%' }} />
                    <Column field="totalPriceUAH" header="Загальна ціна, грн" body={(rowData) => formatCurrency(rowData.totalPriceUAH, "UAH")} style={{ width: '15%' }} />
                    <Column field="action" body={tableActionBodyTemplate} style={{ width: '12%' }} />
                </DataTable>}
                {formik.values.unidentified_products.length > 0 && <DataTable value={formik.values.unidentified_products}
                    header={setTableHeader("Товари поштучного обліку")} footer={() => TableFooter(formik.values.unidentified_products)} tableStyle={{ minWidth: '50rem' }}>
                    <Column field="product" header="Продукт" style={{ width: '22%' }}></Column>
                    <Column field="quantity" header="Кількість" style={{ width: '15%' }}></Column>
                    <Column field="price" header="Ціна за одиницю" body={(rowdata) => formatCurrency(rowdata.price, rowdata.currency)} style={{ width: '15%' }} />
                    <Column field="priceUAH" header="Ціна за одиницю, грн" body={(rowdata) => formatCurrency(rowdata.priceUAH, "UAH")} style={{ width: '15%' }} />
                    <Column field="totalPrice" header="Загальна ціна" body={(rowData) => formatCurrency(rowData.totalPrice, rowData.currency)} style={{ width: '15%' }} />
                    <Column field="totalPriceUAH" header="Загальна ціна, грн" body={(rowData) => formatCurrency(rowData.totalPriceUAH, "UAH")} style={{ width: '15%' }} />
                    <Column field="action" body={tableActionBodyTemplate} style={{ width: '12%' }} />
                </DataTable>}
                {formik.values.services.length > 0 && <DataTable value={formik.values.services}
                    header={setTableHeader("Послуги")} footer={() => TableFooter(formik.values.services)} tableStyle={{ minWidth: '50rem' }}>
                    <Column field="service" header="Послуга" style={{ width: '29%' }}></Column>
                    <Column field="price" header="Ціна в валюті розрахунку" body={(rowdata) => formatCurrency(rowdata.price, rowdata.currency)} style={{ width: '29%' }}></Column>
                    <Column field="priceUAH" header="Ціна в гривні" body={(rowdata) => formatCurrency(rowdata.priceUAH, "UAH")} style={{ width: '29%' }}></Column>
                    <Column field="action" body={tableActionBodyTemplate} style={{ width: '13%' }} />
                </DataTable>}
                {(formik.values.unidentified_products.length > 0 || formik.values.identified_products.length > 0 || formik.values.identified_products.length > 0) && <><div className={commonStyle.footerContainer}>
                    <h3>Загальна сума акту: {formatCurrency(totaLActSum, "UAH")}</h3>
                </div>
                    <div className={commonStyle.footerContainer}>
                        <h3>Загальна сума акту зі знижкою: {formatCurrency(+totaLActSum - +totalDiscount, "UAH")}</h3>
                    </div>
                </>}
            </div>
        </div>}
        {(tabShowed === 1 && isFinalized) &&
            <DataTable value={actPayments} emptyMessage="Платежів по акту не знайдено">
                <Column field="id" header="ID" style={{ width: '10%' }} />
                <Column field="date" header="Дата оплати" style={{ width: '20%' }} />
                <Column field="cagent_name" header="Контрагент" body={(rowData) => rowData.cagent_name === null ? "(Клієнта не вказано)" : rowData.cagent_name} style={{ width: '20%' }} />
                <Column field="distribution_sum" header="Сума" body={(rowdata) => formatCurrency(rowdata.distribution_sum, rowdata.currency)} style={{ width: '20%' }} />
                <Column field="distribution_sum_UAH" header="Сума, грн" body={(rowdata) => formatCurrency(rowdata.distribution_sum_UAH, "UAH")} style={{ width: '20%' }} />
                <Column field="action" body={paymentActionBodyTemplate} style={{ width: '10%' }} />
            </DataTable>}
        {tabShowed === 2 && <HistoryElement data={actHistory} lazyState={historyLazyState} totalRecords={historyTotalRecords} onHistoryPage={onHistoryPage} isAct={true}/>}
        <Sidebar visible={visible} position="right" onHide={() => closeSideBarHandler()} className={commonStyle.sidebar}>
            {showfields && <Button label="<<<" type="button" className={commonStyle.blankButton} onClick={() => BackToProductsHandler()} />}
            <form onSubmit={sideBarFormik.handleSubmit}>
                {selectedItem === null && <label>Виберіть групу товарів для вибору товару</label>}
                <div className="p-inputgroup flex-column mt-4">
                    <FloatLabel>
                        <TreeSelect
                            name="set_group"
                            value={selectedItem}
                            onChange={(e) => setGroupValue(e.value)}
                            options={isProducts ? productGroups : serviceGroups}
                            className="w-full" />
                        <label>Назва групи</label>
                    </FloatLabel>
                </div>
                {(showProductChooseMenu && isProducts) && <DataTable value={products} header={setTableHeader("Товари")} className="mt-4" rows={productLazyState.rows} paginator onPage={onProductsPage} first={productLazyState.first} loading={loading} totalRecords={productsTotalRecords} rowsPerPageOptions={[20, 50]}>
                    <Column field="product_name" header="Назва продукту" />
                    <Column field="total_quantity" header="Кількість" />
                    <Column field="identifier" header="Ідентифікатор" />
                    <Column field="action" body={actionBodyTemplate} />
                </DataTable>}
                {(showProductChooseMenu && !isProducts) && <DataTable value={services} header={setTableHeader("Послуги")} className="mt-4" rows={serviceLazyState.rows} paginator onPage={onServicePage} first={serviceLazyState.first} loading={loading} totalRecords={servicesTotalRecords} rowsPerPageOptions={[20, 50]}>
                    <Column field="name" header="Назва послуги" />
                    <Column field="action" body={actionBodyTemplate} />
                </DataTable>}
                {showfields && (<>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column md:w-22rem">
                            <FloatLabel>
                                <InputText
                                    name="product"
                                    value={sideBarFormik.values.product}
                                    disabled
                                />
                                <label>Назва товару/послуги</label>
                            </FloatLabel>
                        </div>
                    </div>
                    {sideBarFormik.values.type === "INDIVIDUAL" && (<>
                        <div className={commonStyle.formInput}>
                            <div className="p-inputgroup flex-column md:w-22rem">
                                <FloatLabel>
                                    <InputText
                                        name="identifier"
                                        value={sideBarFormik.values.identifier}
                                        disabled
                                    />
                                    <label>Ідентифікатор</label>
                                </FloatLabel>
                            </div>
                        </div>
                    </>)}
                    {sideBarFormik.values.type === "PIECE" && (<>
                        <div className={commonStyle.formInput}>
                            <div className="p-inputgroup flex-column md:w-22rem">
                                <FloatLabel>
                                    <InputText
                                        name="quantity"
                                        value={sideBarFormik.values.quantity || ""}
                                        onChange={(e) => { setQuantity(e.target.value) }}
                                        onBlur={sideBarFormik.handleBlur}
                                        className={sideBarFormik.errors.quantity && sideBarFormik.touched.quantity ? 'p-invalid' : ''}
                                    />
                                    <label>Кількість</label>
                                </FloatLabel>
                                {sideBarFormik.errors.quantity && sideBarFormik.touched.quantity && (<small className={commonStyle.errorSmall}>{sideBarFormik.errors.quantity}</small>)}
                            </div>
                        </div>
                    </>)}
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column md:w-22rem">
                            <FloatLabel>
                                <Dropdown value={sideBarFormik.values.currency}
                                    onChange={(e) => sideBarFormik.setFieldValue('currency', e.value)}
                                    options={currencies}
                                    showClear
                                    className={sideBarFormik.errors.currency && sideBarFormik.touched.currency ? 'p-invalid w-full' : "w-full"} />
                                <label>Валюта</label>
                            </FloatLabel>
                            {sideBarFormik.errors.currency && sideBarFormik.touched.currency && (<small className={commonStyle.errorSmall}>{sideBarFormik.errors.currency}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column md:w-22rem">
                            <FloatLabel>
                                <InputText
                                    name="price"
                                    value={sideBarFormik.values.price}
                                    onChange={(e) => { sideBarFormik.setFieldValue('price', e.target.value) }}
                                    className={sideBarFormik.errors.price && sideBarFormik.touched.price ? 'p-invalid' : ''}
                                />
                                <label>Вартість за одиницю в валюті розрахунку</label>
                            </FloatLabel>
                            {sideBarFormik.errors.price && sideBarFormik.touched.price && (<small className={commonStyle.errorSmall}>{sideBarFormik.errors.price}</small>)}
                        </div>
                    </div>
                    {sideBarFormik.values.type !== "SERVICE" && <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column md:w-22rem">
                            <FloatLabel>
                                <InputText
                                    name="totalPrice"
                                    value={sideBarFormik.values.totalPrice}
                                    onChange={(e) => { sideBarFormik.setFieldValue('totalPrice', e.target.value) }}
                                    className={sideBarFormik.errors.totalPrice && sideBarFormik.touched.totalPrice ? 'p-invalid' : ''}
                                />
                                <label>Загальна вартість в валюті розрахунку</label>
                            </FloatLabel>
                            {sideBarFormik.errors.totalPrice && sideBarFormik.touched.totalPrice && (<small className={commonStyle.errorSmall}>{sideBarFormik.errors.totalPrice}</small>)}
                        </div>
                    </div>}
                    {sideBarFormik.values.type !== "SERVICE" && <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column md:w-22rem">
                            <FloatLabel>
                                <InputText
                                    name="totalPriceUAH"
                                    value={sideBarFormik.values.totalPriceUAH}
                                    onChange={(e) => { sideBarFormik.setFieldValue('totalPriceUAH', e.target.value) }}
                                    className={sideBarFormik.errors.totalPriceUAH && sideBarFormik.touched.totalPriceUAH ? 'p-invalid' : ''}
                                />
                                <label>Загальна вартість в гривні</label>
                            </FloatLabel>
                            {sideBarFormik.errors.totalPriceUAH && sideBarFormik.touched.totalPriceUAH && (<small className={commonStyle.errorSmall}>{sideBarFormik.errors.totalPriceUAH}</small>)}
                        </div>
                    </div>}
                    <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button label="Додати" severity="success" type="submit" autoFocus />
                    </div>
                </>)}
            </form>
        </Sidebar>
    </form>);
}

export default NewSale;