import { buildGroupHierarchy, findKeyByValue } from "../../../../Utils/Utils";
import useGetEnums from "../../../../Utils/EnumsUtils";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import Cookies from "js-cookie";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";

import classes from "../../CommonStyles.module.css";
import { Toast } from "primereact/toast";
import { TabMenu } from "primereact/tabmenu";
import EnumsContext from "../../../../context/enums-context";

import commonStyle from "../../CommonStyles.module.css";
import { Sidebar } from "primereact/sidebar";
import { TreeSelect } from "primereact/treeselect";
import useApi from "../../../../Utils/BackendClient";
import HistoryElement from "../../../UIElements/HistoryElement";
import { InputNumber } from "primereact/inputnumber";

const NewWriteOff = () => {

    const ctx = useContext(EnumsContext);
    const location = useLocation();
    const navigate = useNavigate();
    const toast = useRef(null);

    const { createWriteOffAct, fetchRemainings, fetchProductsExtended, fetchWriteOffActDetails, fetchWriteOffActHistory, updateWriteOffAct} = useApi();

    const [actHistory, setActHistory] = useState(null);
    const [historyTotalRecords, setHistoryTotalRecords] = useState(0);
    const [isEdit, setIsEdit] = useState(false);
    const [isFinalized, setIsFinalized] = useState(false);
    const [loading, setLoading] = useState(false);
    const [productGroups, setProductGroups] = useState([]);
    const [products, setProducts] = useState([]);
    const [productsTotalRecords, setProductsTotalRecords] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    const [tabShowed, setTabShowed] = useState(0);
    const [unChangedRemainings, setUnChangedRemainings] = useState([]);
    const [visible, setVisible] = useState(false);
    const [actData, setActData] = useState({
        division: Cookies.get("division"),
        act_number_autogenerate: true,
        act_number: '',
        act_date: new Date(),
        status: "Драфт",
        identified_products: [],
        unidentified_products: [],
    });
    const [historyLazyState, setHistoryLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1
    });
    const [productLazyState, setProductlazyState] = useState(
        {
            first: 0,
            rows: 20,
            page: 1,
            sortField: "created_at",
            sortOrder: 1,
            filters: {
                product_group: { value: null, matchMode: 'contains' },
            }
        }
    );

    useGetEnums(["divisions"]);

    useEffect(() => {
        if (location.state !== null) {

            setIsEdit(true);

            const fetchData = async () => {

                const response = await fetchWriteOffActDetails(location.state.id);

                if (response.status === 200) {
                    console.log(response.data);

                    setIsFinalized(response.data.status !== "PENDING");
                    response.data.act_date = new Date(response.data.act_date);

                    setActData(response.data);
                }
            }

            fetchData();
        }
    }, [location.state, fetchWriteOffActDetails]);

    useEffect(() => {

        const loadProductGroups = async () => {
            const response = await fetchProductsExtended();

            if (response.status === 200) {
                const groups = buildGroupHierarchy(response.data);
                setProductGroups(groups);
            }
        }

        loadProductGroups();

    }, [fetchProductsExtended]);

    const ReturnButtonHandler = () => {
        navigate("/writeoff");
    }

    const formik = useFormik({
        initialValues: actData,
        enableReinitialize: true,
        onSubmit: async (values) => {

            function transformDate(date) {
                const day = ('0' + date.getDate()).slice(-2); // Add leading zero if needed
                const month = ('0' + (date.getMonth() + 1)).slice(-2); // Month is zero-based, so add 1
                const year = date.getFullYear();

                const formattedDate = `${year}-${month}-${day}`;

                return formattedDate
            }

            values.act_date = transformDate(values.act_date);

            if (values.act_number_autogenerate) {
                delete values.act_number;
            }

            console.log(values);

            const response = !isEdit ? await createWriteOffAct(values) : await updateWriteOffAct(values.id, values);

            let toastMessage = {
                severity: "error", summary: isEdit ? "Помилка редагування" : "Помилка створення",
                detail: "Акт не " + (isEdit ? "відредаговано. " : " створено. ") + " Код помилки: " + response.status, life: 3000
            };

            if (isEdit && response.status === 200) {
                toastMessage = { severity: "success", summary: "Успіх редагування", detail: "Акт успішно відредаговано", life: 3000 }
            }
            else if (!isEdit && response.status === 201) {
                toastMessage = { severity: "success", summary: "Успіх створення", detail: "Акт успішно створено", life: 3000 }
            }

            navigate("/writeoff", { state: { toast: toastMessage } });
        }
    });

    const items = [
        {
            label: 'Деталі акту списання',
            icon: 'pi pi-book',
            command: () => {
                setTabShowed(0);
            },
        },
        ...(isEdit
            ? [
                {
                    label: 'Історія',
                    icon: 'pi pi-history',
                    command: () => {
                        setTabShowed(2);
                        getWriteOffActHistory(location.state.id, historyLazyState);
                    },
                },
            ]
            : []),
    ];

    const DeleteFieldHandler = (id, fields, name) => {

        const updatedFields = [...fields];

        const index = fields.findIndex(function (field) {
            return field.fe_id === id
        });

        updatedFields.splice(index, 1);
        formik.setFieldValue(name, updatedFields);
    };

    const AddProductHandler = () => {
        setVisible(true);
    }

    const setGroupValue = (id) => {
        setSelectedItem(id);
        setLoading(true);

        let filterGroup = null;

        if (id !== null && id !== undefined) {
            if (id.toString().includes('-')) {
                var keys = id.split('-');
                filterGroup = keys[(keys.length - 1)];
            }
            else {
                filterGroup = id;
            }
        }

        productLazyState.filters.product_group.value = filterGroup;

        const getGroupData = async () => {
            const response = await fetchRemainings(productLazyState);

            if (response.status === 200) {
                const unchangedProducts = [...unChangedRemainings, ...response.data.results];
                setUnChangedRemainings(unchangedProducts);
                response.data.results.forEach(result => {
                    result.quantity = 1;
                });
                setProducts(response.data.results);
                setProductsTotalRecords(response.data.count);

                setLoading(false);
            }
        }

        getGroupData()
    }

    const getWriteOffActHistory = async (id, state) => {

        const response = await fetchWriteOffActHistory(id, state);

        console.log(response);

        if (response.status === 200) {
            setActHistory(response.data.results);
            setHistoryTotalRecords(response.data.count);
        }
        else {
            toast.current.show({ severity: "error", summary: "Помилка отримання історії", detail: "Помилка сервера " + response.status, life: 3000 });
        }
    }

    const onProductsPage = event => {
        event.page = event.page + 1;
        setProductlazyState(event);
    }

    const onHistoryPage = (event) => {
        event.page = event.page + 1;
        getWriteOffActHistory(location.state.id, event);

        setHistoryLazyState(event);
    }

    const AddProductToTableHandler = rowData => {

        console.log(rowData);
        const productToPass = {};

        productToPass.product = rowData.product_name;
        productToPass.remaining_id = rowData.id;

        if (!rowData.identifier) {
            productToPass.quantity = rowData.quantity;
            productToPass.note = rowData.note;

            const products = [...formik.values.unidentified_products];

            products.push(productToPass);

            formik.setFieldValue("unidentified_products", products);
        }
        else {
            productToPass.identifier = rowData.identifier;
            const products = [...formik.values.identified_products];

            products.push(productToPass);

            formik.setFieldValue("identified_products", products)
        }
    }

    const mainToolbarLeftTemplate = () => {

        let name = "Створення нового акту списання";

        if (isEdit) {
            name = "Редагування акту списання №" + formik.values.act_number;

            if (isFinalized) {
                name = "Інформація про акт списання №" + formik.values.act_number;
            }
        }
        return (<h3>{name}</h3>);
    }

    const mainToolbarRightTemplate = () => {
        return (<>
            <div className="flex flex-wrap gap-2">
                {!isFinalized && <Button label="Зберегти" severity="success" className={commonStyle.addButton} type="submit" />}
                <Button label="Вийти" severity="secondary" className={commonStyle.closeButton} type="button" onClick={ReturnButtonHandler} />
            </div>
        </>);
    }

    const contentToolbarRightTemplate = () => {
        return (<>
            <div className="flex flex-wrap gap-2">
                <Button label="+Додати товар" severity="success" className={commonStyle.addButton} type="button" onClick={AddProductHandler} />
            </div>
        </>);
    }

    const setTableHeader = (tablename) => {
        return (
            <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                <span className="text-xl text-900 font-bold">{tablename}</span>
            </div>
        );
    }

    const tableActionBodyTemplate = (rowData) => {

        var isId = Object.hasOwn(rowData, 'identifier');

        return (<div className="flex flex-wrap gap-2">
            {!isFinalized && <Button
                icon="pi pi-trash"
                type="button"
                className={`p-button-rounded p-button-danger ${commonStyle.deleteButton}`}
                tooltip="Видалити з таблиці"
                tooltipOptions={{ showOnDisabled: true }}
                onClick={() => DeleteFieldHandler(rowData.fe_id, isId ? formik.values.identified_products : formik.values.unidentified_products, isId ? "identified_products" : "unidentified_products")}
            />}
        </div>
        );
    };

    const priceEditor = (options) => {
        console.log(options);
        return (
            <InputNumber
                inputStyle={{ width: '100%' }}
                value={options.value}
                onValueChange={(e) => {
                    const updatedData = [...products];
                    updatedData[options.rowIndex].quantity = e.value;
                    setProducts(updatedData);
                }}
                min={1}
                max={options.rowData.total_quantity - options.rowData.booked}
            />
        );
    };

    const actionBodyTemplate = (rowData) => {

        const allItemsAreBooked = rowData.service_group === undefined && rowData.total_quantity === rowData.booked;

        return (<div className="flex flex-wrap gap-2">
            <Button
                icon="pi pi-plus"
                type="button"
                className={`p-button-rounded ${allItemsAreBooked ? commonStyle.closeButton : commonStyle.addButton}`}
                disabled={allItemsAreBooked}
                tooltip={allItemsAreBooked ? "Товар використано в акті" : "Додати"}
                tooltipOptions={{ showOnDisabled: true, position: "left" }}
                onClick={() => AddProductToTableHandler(rowData)}
            />
        </div>
        );
    };

    return (<form onSubmit={formik.handleSubmit}>
        <Toast ref={toast} />
        <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate} />
        <TabMenu model={items} />
        {tabShowed === 0 && <div className="grid">
            <div className={`${classes.leftMenu} col-3`}>
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <Dropdown value={ctx.divisions[formik.values.division]}
                                onChange={(e) => formik.setFieldValue('division', findKeyByValue(ctx.divisions, e.value))}
                                options={Object.values(ctx.divisions)}
                                disabled={isFinalized}
                                showClear
                                className={formik.errors.division && formik.touched.division ? 'p-invalid w-full' : "w-full"} />
                            <label>Підрозділ</label>
                        </FloatLabel>
                        {formik.errors.division && formik.touched.division && (<small className={commonStyle.errorSmall}>{formik.errors.division}</small>)}
                    </div>
                </div>
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-1">
                        <FloatLabel>
                            <Calendar value={formik.values.act_date}
                                onChange={(e) => { formik.setFieldValue('act_date', e.target.value) }}
                                disabled={isFinalized}
                                showIcon />
                            <label>Дата акту</label>
                        </FloatLabel>
                    </div>
                </div>
                {!isEdit && <div className={commonStyle.checkBoxInput}>
                    <div className={`${commonStyle.checkboxStyle} p-inputgroup flex-1 md:w-22rem"`}>
                        <Checkbox
                            name="act_number_autogenerate"
                            onChange={(e) => { formik.setFieldValue('act_number_autogenerate', !formik.values.act_number_autogenerate) }}
                            checked={formik.values.act_number_autogenerate}
                        />
                        <label>Автоматично генерувати номер акту</label>
                    </div>
                </div>}
                {!formik.values.act_number_autogenerate && <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <InputText value={formik.values.act_number}
                                onChange={(e) => { formik.setFieldValue('act_number', e.target.value) }}
                                disabled={isFinalized}
                                className={formik.errors.act_number && formik.touched.act_number ? 'p-invalid w-full' : "w-full"} />
                            <label>Номер акту</label>
                        </FloatLabel>
                        {formik.errors.act_number && formik.touched.act_number && (<small className={commonStyle.errorSmall}>{formik.errors.act_number}</small>)}
                    </div>
                </div>}
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <InputText value={formik.values.status === "FINALIZED" ? "Фіналізований" : "Драфт"}
                                disabled
                                className="w-full" />
                            <label>Статус</label>
                        </FloatLabel>
                    </div>
                </div>
            </div>
            <div className="col-9">
                {!isFinalized && <Toolbar className="mb-4" end={contentToolbarRightTemplate}></Toolbar>}
                {(formik.values.identified_products.length > 0) && <DataTable value={formik.values.identified_products} header={setTableHeader("Товари індивідуального обліку")}
                    tableStyle={{ minWidth: '50rem' }}>
                    <Column field="product" header="Продукт" style={{ width: '40%' }} />
                    <Column field="identifier" header="Ідентифікатор" style={{ width: '15%' }} />
                    <Column field="note" header="Примітка" style={{ width: '30%' }} />
                    <Column field="action" body={tableActionBodyTemplate} style={{ width: '15%' }} />
                </DataTable>}
                {(formik.values.unidentified_products.length > 0) && <DataTable value={formik.values.unidentified_products} header={setTableHeader("Товари поштучного обліку")}
                    tableStyle={{ minWidth: '50rem' }}>
                    <Column field="product" header="Продукт" style={{ width: '40%' }} />
                    <Column field="quantity" header="Кількість" style={{ width: '15%' }} />
                    <Column field="note" header="Примітка" style={{ width: '30%' }} />
                    <Column field="action" body={tableActionBodyTemplate} style={{ width: '15%' }} />
                </DataTable>}
            </div>
        </div>
        }
        {tabShowed === 2 && <HistoryElement data={actHistory} lazyState={historyLazyState} totalRecords={historyTotalRecords} onHistoryPage={onHistoryPage} isAct={true} />}
        <Sidebar visible={visible} position="right" onHide={() => setVisible(false)} className={commonStyle.sidebar}>
            {selectedItem === null && <label>Виберіть групу товарів для вибору товару</label>}
            <div className="p-inputgroup flex-column mt-4">
                <FloatLabel>
                    <TreeSelect
                        name="set_group"
                        value={selectedItem}
                        onChange={(e) => setGroupValue(e.value)}
                        options={productGroups}
                        className="w-full" />
                    <label>Назва групи</label>
                </FloatLabel>
            </div>
            <DataTable value={products} header={setTableHeader("Товари")} className="mt-4" rows={productLazyState.rows}
                paginator onPage={onProductsPage} first={productLazyState.first} loading={loading} totalRecords={productsTotalRecords} rowsPerPageOptions={[20, 50]}
                editMode="cell">
                <Column field="product_name" header="Назва продукту" />
                <Column field="identifier" header="Ідентифікатор" />
                <Column field="total_quantity" body={(rowData) => rowData.total_quantity + "(" + rowData.booked + ")"} header="Кількість(резерв)" />
                <Column field="quantity" editor={(options) => priceEditor(options)} header="До списання"/>
                <Column field="action" body={actionBodyTemplate} />
            </DataTable>
        </Sidebar>
    </form>);
}

export default NewWriteOff;