import { Card } from "primereact/card";
import { useContext, useEffect, useRef, useState } from "react";
import useApi from "../../../../Utils/BackendClient";
import { UserPermissionContext } from "../../../../context/UserPermissionProvider";

import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import { formatCurrency } from "../../../../Utils/Utils";
import { Guid } from "js-guid";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const useWidget = (fetchFunction, initialDates, toast, errorMessage, globalDates) => {
    const [data, setData] = useState(null);
    const [result, setResult] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [dates, setDates] = useState(initialDates);
  
    useEffect(() => {
      const activeDates = globalDates || dates;
      if (activeDates[0] !== null) {
        const fetchData = async () => {
          try {
            const response = await fetchFunction(activeDates);
            if (response.status === 200) {
              setData(response.data);
              setResult(calculateResult(response.data));
              setLoaded(true);
            } else {
              throw new Error(response.status === 403 ? "Access denied" : `Error ${response.status}`);
            }
          } catch (error) {
            toast.current.show({
              severity: "error",
              summary: errorMessage,
              detail: error.message,
              life: 3000,
            });
            setData([]);
            setResult(calculateResult(null));
          }
        };
        fetchData();
      }
    }, [fetchFunction, dates, globalDates, toast, errorMessage]);
  
    return { data, result, loaded, dates, setDates };
  };

const calculateResult = (widgetData) => {
  let result = 0;
  if (widgetData !== null) {
    if (widgetData.previous_value !== 0) {
      result = Math.round((widgetData.current_value / widgetData.previous_value) * 100 - 100);
    } else if (widgetData.current_value !== 0) {
      result = 100;
    }
  }
  const style = result === 0 ? "" : result > 0 ? "text-green-500" : "text-red-500";
  return { result, style };
};

const WidgetCard = ({ data, result, loaded, dates, setDates, icon, color, currency }) => (
  <Card className="mb-0">
    {!loaded ? (
      <div className="card flex justify-content-center">
        <ProgressSpinner />
      </div>
    ) : (
      <>
        <div className="flex justify-content-between mb-2">
          <div>
            <span className="block text-500 font-medium mb-3">{data?.name}</span>
            <div className="text-900 font-medium text-xl">
              {currency
                ? data?.current_value.toLocaleString("ua-UA", { style: "currency", currency })
                : data?.current_value}
            </div>
          </div>
          <div
            className={`flex align-items-center justify-content-center bg-${color}-100 border-round`}
            style={{ width: "3rem", height: "3rem" }}
          >
            <i className={`pi ${icon} text-${color}-500 text-xl`} />
          </div>
        </div>
        <span className="font-medium">в період </span>
        <Calendar
          dateFormat="dd/mm/yy"
          value={dates}
          onChange={(e) => setDates(e.value)}
          selectionMode="range"
          readOnlyInput
          hideOnRangeSelection
          inputStyle={{ border: "none", paddingLeft: "0" }}
        />
        <div>
          <span className={`${result?.style} font-medium`}>{result?.result}% </span>
          <span className="text-500">порівняно з аналогічним періодом до цього</span>
        </div>
      </>
    )}
  </Card>
);

const DashBoards = () => {
    const { fetchCostsWidget, fetchPayableWidget, fetchProductCountWidget, fetchNetProfitWidget, fetchReceivableWidget, fetchRevenueWidget, fetchServicesGivenWidget } = useApi();
    const permCtx = useContext(UserPermissionContext);
    const toast = useRef(null);
    const [globalDates, setGlobalDates] = useState(null);
    const [receivables, setReceivables] = useState(null);
    const [receivablesLoaded, setReceivablesLoaded] = useState(false);
    const [payables, setPayables] = useState(null);
    const [payablesLoaded, setPayablesLoaded] = useState(false);
    const [netProfit, setNetProfit] = useState(null);
    const [netProfitLoaded, setNetProfitLoaded] = useState(null);
  
    const initialDates = (() => {
      const today = new Date();
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(today.getDate() - 7);
      return [sevenDaysAgo, today];
    })();

    const formatGlobalDates = () => {
        if(globalDates === null || globalDates[1] === null){
            return null
        }

        return globalDates;
    }

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetchReceivableWidget();
          if (response.status === 200) {
            setReceivables(response.data);
            setReceivablesLoaded(true);
          } else {
            throw new Error(response.status === 403 ? "Access denied" : `Error ${response.status}`);
          }
        } catch (error) {
          toast.current.show({
            severity: "error",
            summary: "Помилка завантаження віджету дебіторської заборгованості",
            detail: error.message,
            life: 3000,
          });
        }
      };
      fetchData();
    }, [fetchReceivableWidget]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetchPayableWidget();
          if (response.status === 200) {
            setPayables(response.data);
            setPayablesLoaded(true);
          } else {
            throw new Error(response.status === 403 ? "Access denied" : `Error ${response.status}`);
          }
        } catch (error) {
          toast.current.show({
            severity: "error",
            summary: "Помилка завантаження віджету дебіторської заборгованості",
            detail: error.message,
            life: 3000,
          });
        }
      };
      fetchData();
    }, [fetchPayableWidget]);

    useEffect(() => {
      const fetchData = async () => {

        const today = new Date();
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(today.getDate() - 7);
      
        const dates = (globalDates !== null && globalDates[1] !== null) ? globalDates : [sevenDaysAgo, today];

        try {
          const response = await fetchNetProfitWidget(dates);
          if (response.status === 200) {
            setNetProfit(response.data);
            console.log("called");
            setNetProfitLoaded(true);
          } else {
            throw new Error(response.status === 403 ? "Access denied" : `Error ${response.status}`);
          }
        } catch (error) {
          toast.current.show({
            severity: "error",
            summary: "Помилка завантаження віджету чистого прибутку",
            detail: error.message,
            life: 3000,
          });
        }
      };
      fetchData();
    }, [fetchNetProfitWidget, globalDates]);

    const expenses = useWidget(fetchCostsWidget, initialDates, toast, "Помилка віджету витрат", formatGlobalDates());
    const products = useWidget(fetchProductCountWidget, initialDates, toast, "Помилка віджету кількості проданих товарів", formatGlobalDates());
    const services = useWidget(fetchServicesGivenWidget, initialDates, toast, "Помилка віджету кількості наданих послуг", formatGlobalDates());
    const revenue = useWidget(fetchRevenueWidget, initialDates, toast, "Помилка віджету доходу", formatGlobalDates());
  
    const synchronizeDates = (newDates) => {
        setGlobalDates(newDates);
        if(newDates[1] !== null){
            expenses.setDates(newDates);
            products.setDates(newDates);
            services.setDates(newDates);
            revenue.setDates(newDates);
        }
    };
  
    return (
      <>
        <Toast ref={toast} />
        {permCtx.permissions?.includes("dashboards.view_dashboard") && ( <><div>
            <Card className="mb-3">
              <span className="font-medium">Період: </span>
              <Calendar
                dateFormat="dd/mm/yy"
                value={globalDates}
                onChange={(e) => synchronizeDates(e.value)}
                selectionMode="range"
                readOnlyInput
                hideOnRangeSelection
                className="ml-2"
              />
            </Card>
          </div>
          <div className="grid">
            <div className="col-12 lg:col-6 xl:col-3" key="expenses">
              <WidgetCard {...expenses} icon="pi-shopping-cart" color="blue" currency="UAH" />
            </div>
            <div className="col-12 lg:col-6 xl:col-3" key="products">
              <WidgetCard {...products} icon="pi-phone" color="orange" />
            </div>
            <div className="col-12 lg:col-6 xl:col-3" key="services">
              <WidgetCard {...services} icon="pi-wrench" color="cyan" />
            </div>
            <div className="col-12 lg:col-6 xl:col-3" key="revenue">
              <WidgetCard {...revenue} icon="pi-money-bill" color="green" currency="UAH" />
            </div>
            <div className="col-12 lg:col-6">
              <Card className="mb-0" style={{ minHeight: "348px" }}>
                {!receivablesLoaded ? (
                  <div className="card flex justify-content-center">
                    <ProgressSpinner />
                  </div>
                ) : (
                  <>
                    <span className="block text-500 font-medium mb-3">{receivables?.name}</span>
                    <div className="flex justify-content-between border-bottom-1 surface-border p-3">
                      <span className="font-semibold text-color-secondary text-xl">Загальна сума</span>
                      <span className="text-green-600 text-2xl font-bold">{formatCurrency(receivables?.sum, "UAH")}</span>
                    </div>
                    <div className="p-3">
                      {receivables?.top_cagents.length === 0 && <span className="text-color-secondary"> Немає дебіторської заборгованості</span>}
                      {receivables?.top_cagents.map(cagent =>
                        <div className="flex align-items-center justify-content-between py-2" key={Guid.newGuid()}>
                          <div className="flex gap-3 text-lg align-items-center">
                            <i className="pi pi-user text-color-secondary" />
                            <span className="text-color-secondary">{cagent?.cagent_name ?? "(Невідомий клієнт)"}</span>
                          </div>
                          <div className="flex items-center gap-2">
                            <span className="font-semibold text-base">{formatCurrency(cagent?.sum, "UAH")}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </>)}
              </Card>
            </div>
            <div className="col-12 lg:col-6">
              <Card className="mb-0" style={{ minHeight: "348px" }}>
                {!payablesLoaded ? (
                  <div className="card flex justify-content-center">
                    <ProgressSpinner />
                  </div>
                ) : (
                  <>
                    <span className="block text-500 font-medium mb-3">{payables?.name}</span>
                    <div className="flex justify-content-between border-bottom-1 surface-border p-3">
                      <span className="font-semibold text-color-secondary text-xl">Загальна сума</span>
                      <span className="text-red-600 text-2xl font-bold">{formatCurrency(payables?.sum, "UAH")}</span>
                    </div>
                    <div className="p-3">
                      {payables?.top_cagents.length === 0 && <span className="text-color-secondary"> Немає кредиторської заборгованості</span>}
                      {payables?.top_cagents.map(cagent =>
                        <div key={Guid.newGuid()} className="flex align-items-center justify-content-between py-2">
                          <div className="flex gap-3 text-lg align-items-center">
                            <i className="pi pi-user text-color-secondary" />
                            <span className="text-color-secondary">{cagent?.cagent_name ?? "(Невідомий клієнт)"}</span>
                          </div>
                          <div className="flex items-center gap-2">
                            <span className="font-semibold text-base">{formatCurrency(cagent?.sum, "UAH")}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </>)}
              </Card>
            </div>
            <div className="col-12 lg:col-6">
              <Card className="mb-0" style={{ minHeight: "348px" }}>
                {!netProfitLoaded ? (
                  <div className="card flex justify-content-center">
                    <ProgressSpinner />
                  </div>
                ) : (
                  <>
                    <span className="block text-500 font-medium mb-3">{netProfit?.name}</span>
                    <div className="flex justify-content-between border-bottom-1 surface-border p-3">
                      <span className="font-semibold text-color-secondary text-xl">Загальна сума</span>
                      <span className="text-green-600 text-2xl font-bold">{formatCurrency(netProfit?.net_profit_in_UAH, "UAH")}</span>
                    </div>
                    <div className="p-3">
                      {netProfit?.net_profit_in_currencies?.length === 0 && <span className="text-color-secondary"> Немає чистого прибутку</span>}
                      <DataTable value={Object.values(netProfit.net_profit_in_currencies)}>
                        <Column field="currency" header="Валюта" style={{ width: '10%' }}/>
                        <Column field="profit" header="Дохід" body={rowdata => formatCurrency(rowdata.profit, rowdata.currency)} align="right" style={{ width: '30%' }}/>
                        <Column field="cost" header="Витрати" body={rowdata => formatCurrency(rowdata.cost, rowdata.currency)} align="right" style={{ width: '30%' }}/>
                        <Column field="net_profit" header="Прибуток" body={rowdata => formatCurrency(rowdata.net_profit, rowdata.currency)} align="right" style={{ width: '30%' }}/>
                      </DataTable>
                    </div>
                  </>)}
              </Card>
            </div>
          </div>
        </>)}
      </>
    );
  };

export default DashBoards;