import EnumsContext from "../../../../context/enums-context";
import { buildGroupHierarchy, findGroupCode, formatCurrency, getGroupCode, statusList } from "../../../../Utils/Utils";
import HistoryElement from "../../../UIElements/HistoryElement";
import ProductStatusCircle from "../../../UIElements/ProductStatusCircle";
import useApi from "../../../../Utils/BackendClient";
import { useContext, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import useGetEnums from "../../../../Utils/EnumsUtils";
import { useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { FloatLabel } from "primereact/floatlabel";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import { Sidebar } from "primereact/sidebar";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Tree } from "primereact/tree";
import { TreeSelect } from "primereact/treeselect";

import commonStyle from "../../CommonStyles.module.css";
import { UserPermissionContext } from "../../../../context/UserPermissionProvider";
import { TabMenu } from "primereact/tabmenu";



const Services = () => {

    const fieldTypes = ["STRING", "INTEGER", "FLOAT", "DATE"];

    const navigate = useNavigate();
    const ctx = useContext(EnumsContext);
    const permCtx = useContext(UserPermissionContext);
    const toast = useRef(null);
    const { changeServiceStatus, createServiceGroup, deleteService, deleteServiceGroup, fetchServiceGroupDetails, fetchServices, fetchServicesExtended, fetchServiceGroupHistory, updateServiceGroup } = useApi();

    const [loading, setLoading] = useState(true);
    const [services, setServices] = useState();
    const [totalRecords, setTotalRecords] = useState(0);
    const [reloadData, setReloadData] = useState(true);
    const [onlyActive, setOnlyActive] = useState(false);
    const [serviceGroupsLoading, setServiceGroupsLoading] = useState(true);
    const [sortedGroups, setSortedGroups] = useState([]);
    const [treeHierarchy, setTreeHierarchy] = useState([]);
    const [selectedServices, setSelectedServices] = useState(null);
    const [reloadServiceGroups, setReloadServiceGroups] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [visible, setVisible] = useState(false);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [showServiceGroupMenu, setShowServiceGroupMenu] = useState(true);
    const [serviceGroupHistory, setServiceGroupHistory] = useState([]);
    const [historyTotalRecords, setHistoryTotalRecords] = useState(0);
    const [tabShowed, setTabShowed] = useState(0);
    const [group, setGroup] = useState({
        name: '',
        root_group: null,
        description: '',
        is_active: null,
        fields: [],
    });
    const [tableFilters, setTableFilters] = useState({
        service_group: { value: null, matchMode: 'equals', name: '' },
        name: {value: "", matchMode: 'equals'},
        service_article: {value: "", matchMode: 'equals'}
    });
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "created_at",
        sortOrder: 1,
        filters: {
            service_group: { value: null, matchMode: 'equals' },
            name: {value: "", matchMode: 'equals'},
            service_article: {value: "", matchMode: 'equals'}
        }
    });
    const [historyLazyState, setHistoryLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1   
    });

    useGetEnums(["service_groups"]);

    useEffect(() => {

        const fetchData = async () => {

            const response = await fetchServices(lazyState);

            if (response.status !== 200) {
                toast.current.show({ severity: "error", summary: "Помилка завантаження списку", detail: "Помилка сервера " + response.status, life: 3000 });
                setTotalRecords(0);
                setServices([]);
            }
            else {
                setTotalRecords(response.data.count);
                setServices(response.data.results);
            }

            setLoading(false);
        }

        fetchData();

    }, [lazyState, reloadData, fetchServices, onlyActive]);

    useEffect(() => {
        const getProductGroups = async () => {
            const response = await fetchServicesExtended();

            if (response.status === 200) {

                console.log(response.data);
                const groups = buildGroupHierarchy(response.data);

                const buildTreeHierarchy = (groups) => {
                    return groups.map(group => {
                        const groupBlock = {
                            key: group.id,
                            label: group.group_name,
                            droppable: false,
                            is_active: group.is_active,
                            children: group.sub_groups.length > 0 ? buildTreeHierarchy(group.sub_groups) : undefined,
                        };

                        return groupBlock;
                    });
                };

                const hierarchy = buildTreeHierarchy(response.data || []);

                setTreeHierarchy(hierarchy);
                setSortedGroups(groups);
                setServiceGroupsLoading(false);
            }
            else {
                setSortedGroups([]);
            }
        }

        getProductGroups();
    }, [fetchServicesExtended, reloadServiceGroups]);

    const serviceGroupFormik = useFormik({
        initialValues: group,
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (values.name === null || values.name === "") {
                errors.name = "Назва групи не повинна бути пустою";
            }

            return errors;
        },

        onSubmit: async (values) => {

            if (values.root_group !== undefined && values.root_group !== null) {
                if (values.root_group.toString().includes('-')) {
                    var keys = values.root_group.split('-');
                    values.root_group = keys[(keys.length - 1)];
                }
            }
            else {
                values.root_group = null;
            }

            if (values.is_active === "Активний") {
                values.is_active = true;
            }
            else {
                values.is_active = false;
            }

            const response = isEdit ? await updateServiceGroup(values.id, values) : await createServiceGroup(values);

            const successStatus = isEdit ? 200 : 201;

            let toastMessage = {
                severity: "error", summary: isEdit ? "Помилка редагування" : "Помилка створення",
                detail: "Групу не " + (isEdit ? "відредаговано. " : " створено. ") + "Помилка сервера " + response.status, life: 3000
            };

            if (response.status === successStatus) {
                toastMessage = isEdit ? { severity: "success", summary: "Успіх редагування", detail: "Групу успішно відредаговано", life: 3000 } :
                    { severity: "success", summary: "Успіх створення", detail: "Групу успішно створено", life: 3000 }

                serviceGroupFormik.resetForm();
                HideSideBarWithReload();
            }

            toast.current.show(toastMessage);
        }
    });

    const fieldsFormik = useFormik({
        initialValues: {
            name: '',
            field_type: null,
            mandatory: false
        },
        validate: values => {
            const errors = {};

            if (values.name === null || values.name === "") {
                errors.name = "Назва поля не повинна бути пустою";
            }

            if (values.field_type === null || values.field_type === '') {
                errors.field_type = "Виберіть тип поля";
            }

            return errors;
        },
        onSubmit: values => {
            console.log(values);

            if (serviceGroupFormik.values.fields.length === 0) {
                serviceGroupFormik.setFieldValue('fields', [values]);
            }
            else {
                serviceGroupFormik.setFieldValue('fields', [...serviceGroupFormik.values.fields, values])
            }
            fieldsFormik.resetForm();
            setDialogVisible(false);
        }
    });

    const getServiceHistory = async (id, state) => {
        
        const response = await fetchServiceGroupHistory(id, state);

        console.log(response);

        if(response.status === 200){
            setServiceGroupHistory(response.data.results);
            setHistoryTotalRecords(response.data.count);
        }
        else{
            toast.current.show({ severity: "error", summary: "Помилка отримання історії", detail: "Помилка сервера " + response.status, life: 3000 });
        }
    }

    const items = [
        {
            label: 'Налаштуванна полів',
            icon: 'pi pi-book',
            command: () => {
                setTabShowed(0);
            },
        },
        {
            label: 'Опис',
            icon: 'pi pi-comment',
            command: () => {
                setTabShowed(1);
            },
        },
        ...(isEdit
            ? [
                {
                    label: 'Історія',
                    icon: 'pi pi-history',
                    command: () => {
                        setTabShowed(2);
                        getServiceHistory(serviceGroupFormik.values?.id, historyLazyState);
                    },
                },
            ]
            : []),
    ];

    const ChangeServiceGroupStatusHandler = (rowData) => {

        const action = rowData.is_active ? "deactivate" : "activate";

        const callApi = async () => {

            const response = await changeServiceStatus(rowData.id, action);

            if (response.status !== 200) {
                toast.current.show({ severity: "error", summary: "Помилка зміни статусу", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else {
                toast.current.show({ severity: 'success', summary: rowData.is_active ? 'Деактивація' : "Активація", detail: rowData.is_active ? 'Сервіс деактивовано' : "Сервіс активовано", life: 3000 });
            }

            setReloadData(!reloadData);
        }

        callApi();
    }

    const DeleteServiceHandler = (serviceIds, isMultiple) => {

        const deleteData = async () => {

            const response = await deleteService({ ids : serviceIds});

            if (response.status !== 204)
            {
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else {
                toast.current.show({ severity: "success", summary: 'Видалення', detail: `Товар успішно видалено`, life: 3000 });

                if(isMultiple) { setSelectedServices(null); } 
            }

            setReloadData(!reloadData);
        }

        deleteData();
    }

    const CreateProductHandler = () => {
        navigate('/services/createservice');
    }

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const onHistoryPage = (event) => {
        event.page = event.page + 1;
        getServiceHistory(serviceGroupFormik.values.id, event);

        setHistoryLazyState(event);
    }

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const onFilter = (event) => {
        event.page = 1;
        setTableFilters(event.filters);
    }

    const confirmDelete = (event, ids, isMultiple) => {
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: ids.length > 1 ? 'Підтвердіть видлення груп послуг' : 'Підтвердіть видалення групи послуг',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            accept: () => { DeleteServiceHandler(ids, isMultiple) }
        });
    }

    const changeStatus = (event, rowData) => {
        console.log("Here!");
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: rowData.is_active ? 'Підтвердження деактивації групи' : 'Підтвердження активації групи',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            accept: () => { ChangeServiceGroupStatusHandler(rowData) }
        });
    }

    const EditServiceHandler = rowData => {
        const route = '/services/editservice/' + rowData.id;
        navigate(route, { state: { id: rowData.id } });
    }

    const OnlyActiveHandler = (event) => {
        setOnlyActive(event);
    }

    const handleGroupAdding = () => {
        setVisible(true);
    }

    const HideSideBar = () => {
        setGroup({
            name: '',
            root_group: null,
            description: '',
            is_active: null,
            fields: [],
        });
        setTabShowed(0);
        setIsEdit(false);
        setVisible(false);
    }

    const HideSideBarWithReload = () => {
        setServiceGroupsLoading(true);
        setReloadServiceGroups(!reloadServiceGroups);
        
        HideSideBar();
    }

    const applyFilterFromMenu = (node) => {
        const filters = tableFilters;
        filters.service_group.value = node.key;
        filters.service_group.name = node.label;
        setTableFilters(filters);

        const state = lazyState;
        state.filters.service_group.value = node.key;
        state.filters.service_group.name = node.label;
        setlazyState(state);

        setReloadData(!reloadData);
    }
    
    const handleEdit = (node) => {

        const getServiceGroupData = async () => {

            const response = await fetchServiceGroupDetails(getGroupCode(node.key));

            if (response.status === 200) {

                response.data.is_active = response.data.is_active ? "Активний" : "Деактивований";
                response.data.root_group = findGroupCode(sortedGroups, response.data.root_group);

                setIsEdit(true);
                setGroup(response.data);
                setVisible(true);
            }
        }

        getServiceGroupData();
    };

    const handleDelete = (node) => {
        const deleteData = async () => {

            const request = {ids: [getGroupCode(node.key)]};

            const response = await deleteServiceGroup(request);

            if (response.status !== 204){
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else
            {
                setServiceGroupsLoading(true);
                setReloadServiceGroups(!reloadServiceGroups);
                toast.current.show({ severity: "success", summary: 'Видалення', detail: 'Групу товарів успішно видалено', life: 3000 });
            }

            setReloadData(!reloadData);
        }

        deleteData();
    };

    const confirmGroupDelete = (event, rowData) => {
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: 'Підтвердження видалення групи послуг',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            accept: () => { handleDelete(rowData) }
        });
    }

    const filterApply = () => {
        const editedLazyState = lazyState;
        editedLazyState.filters = tableFilters;
        setlazyState(editedLazyState);
        setReloadData(!reloadData)
    }

    const clearFilter = (paramName, value) => {
        setlazyState(prevState => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                [paramName]: {
                    ...prevState.filters[paramName],
                    value: value
                }
            }
        }));
    }

    const clearFilterFromChips = (paramName, value) => {
        clearFilter(paramName, value);
        setTableFilters(prevState => ({
            ...prevState,
            [paramName]: {
                value: value
            }
        }));
    }

    const formatFilterChips = () => {
        const element = [];

        Object.keys(lazyState.filters).forEach((filterKey) => {
            const filter = lazyState.filters[filterKey];

            if (filter.value && filter.value !== "") {

                let translatedKey = filterKey;
                let changedValue = filter.value;
                let removeValue = null;


                if (filterKey === "service_group") {
                    translatedKey = "Назва групи";
                    changedValue = filter.name;
                }
                else if(filterKey === "name") {
                    translatedKey = "Назва послуги";
                    removeValue = "";
                }
                else if (filterKey === "service_article") {
                    translatedKey = "Артикул";
                    removeValue = "";
                }

                element.push(
                    <Chip
                        key={filterKey}
                        label={`${translatedKey}: ${changedValue}`}
                        removable
                        onRemove={() => clearFilterFromChips(filterKey, removeValue)} // Optional: To handle removal
                    />
                );
            }
        });

        return <div className="flex flex-wrap gap-2">{element}</div>;
    }

    const DeleteFieldHandler = (fieldName) => {

        const updatedFields = [...serviceGroupFormik.values.fields];

        const index = serviceGroupFormik.values.fields.findIndex(function (field) {
            return field.field_name === fieldName
        });

        updatedFields.splice(index, 1);
        serviceGroupFormik.setFieldValue('fields', updatedFields);
    };

    const nodeTemplate = (node, options) => {
        return (
            <div className="flex justify-content-between align-items-center w-full">
                <div className="flex col-7 items-center">
                    <ProductStatusCircle rowData={node} type="statusCircleSmall" />
                    <span className="ml-2">{node.label}</span> {/* Optional margin for spacing */}
                </div>
                <div className="col-5 flex justify-content-end align-items-center">
                    <Button
                        icon="pi pi-pencil"
                        severity="secondary"
                        size="small"
                        text
                        tooltip="Редагувати групу"
                        tooltipOptions={{ position: "top" }}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(node);
                        }}
                    />
                    <Button
                        icon="pi pi-trash"
                        severity="secondary"
                        text
                        tooltip="Видалити групу"
                        tooltipOptions={{ position: "top" }}
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            confirmGroupDelete(e, node)
                        }}
                    />
                </div>
            </div>
        );
    };

    const tableHeader = (
        <div className="flex justify-content-between align-items-center w-full">
            {formatFilterChips()}
            <div className="flex justify-content-center align-items-center gap-2">
                <InputSwitch checked={onlyActive} onChange={(e) => OnlyActiveHandler(e.value)} />
                <span>Лише активні</span>
            </div>
        </div>
    );

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            {selectedServices?.length > 0 && <Button label="Видалити" severity="danger" disabled={selectedServices?.some(obj => obj.can_be_deleted === false)}
            className={selectedServices?.some(obj => obj.can_be_deleted === false) ? commonStyle.closeButton : commonStyle.deleteButton}
            onClick={(event) => confirmDelete(event, selectedServices.map(item => item.id), true)} />}
            <Button label="Додати послугу" severity="success" className={commonStyle.addButton} onClick={CreateProductHandler} />
        </div>)
    }

    const rootGroupTemplate = (rowData) => {
        return ctx.service_groups[rowData.service_group];
    }

    const actionBodyTemplate = (rowData) => {
        return (<div className="flex flex-wrap gap-2">
            <Button
                icon={rowData.is_active ? "pi pi-times" : "pi pi-check"}
                className={`p-button-rounded ${rowData.is_active ? commonStyle.warningTag + " p-button-warning" : commonStyle.addButton + " p-button-success"}`}
                tooltip={rowData.is_active ? "Деактивувати" : "Активувати"}
                tooltipOptions={{ position: "top" }}
                onClick={(event) => changeStatus(event, rowData)}
            />
            <Button
                icon="pi pi-trash"
                className={`p-button-rounded p-button-danger ${rowData.can_be_deleted ? commonStyle.deleteButton : commonStyle.closeButton}`}
                tooltip={rowData.can_be_deleted ? "Видалити" : "Неможливо видалити"}
                tooltipOptions={{ showOnDisabled: true,  position: "top" }}
                disabled={!rowData.can_be_deleted}
                onClick={(event) => confirmDelete(event, [rowData.id], false)}
            />
            <Button
                icon="pi pi-pencil"
                className={`p-button-rounded p-button-info ${commonStyle.editButton}`}
                tooltip="Редагувати"
                tooltipOptions={{ position: "top" }}
                onClick={() => EditServiceHandler(rowData)}
            />
        </div>
        );
    };

    const mainToolbarLeftTemplate = () => {
        return (<h3>Створення групи послуг</h3>);
    }

    const mainToolbarRightTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Зберегти" severity="success" className={commonStyle.addButton} type="submit" />
            </div>
        );
    }

    const rightToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap justify-content-end">
                <Button label="Додати" icon="pi pi-plus" type="button" className={commonStyle.addButton} onClick={() => setDialogVisible(true)} severity="success" />
            </div>
        );
    };

    const ObligatoryBodyTemplate = (product) => {

        if (product.mandatory) {
            return <Tag className={commonStyle.successTag} value='Так' severity="success" style={{ width: '20%' }} />;
        }

        return <Tag className={commonStyle.deletedTag} value="Ні" severity="danger" style={{ width: '20%' }}></Tag>;
    };

    //filter templates
    const inputTemplate = (options) => {
        return (
            <InputText
                value={options.value}
                onChange={
                    (e) => options.filterApplyCallback(e.target.value)
                }
            />
        );
    }

    const fieldsActionBodyTemplate = (rowData) => {
        return (<div className="flex flex-wrap gap-2">
            <Button
                icon="pi pi-trash"
                type="button"
                className={`p-button-rounded p-button-danger ${commonStyle.deleteButton}`}
                onClick={() => DeleteFieldHandler(rowData.field_name)}
            />
        </div>
        );
    };

    const serviceGroupMenuClass = () => {
        if(!permCtx.permissions?.includes("services.view_servicegroup")){
            return "col-12 justify-content-end";
        }
        else if(!showServiceGroupMenu){
            return "col-12 flex flex-row";
        }
        else{
            return "col-7 md:col-8 xl:col-9 justify-content-end";
        }
    }

    return (<>
        <Toast ref={toast} />
        <Toolbar className="mb-4" end={toolbarRightTemplate}></Toolbar>
        <div className='grid'>
        {(permCtx.permissions?.includes("services.view_servicegroup") && showServiceGroupMenu) && <div className={`${showServiceGroupMenu ? "col-5 md:col-4 xl:col-3" : "col-1"} ${serviceGroupsLoading ? "flex align-items-center justify-content-center" : ""}`} style={{ height: '100vh' }}>
                {serviceGroupsLoading ? (
                    <ProgressSpinner />
                ) : (
                    <>
                        <div className='flex justify-content-between mr-4 ml-4'>
                        <Button icon="pi pi-step-backward-alt"
                                severity="secondary" 
                                outlined 
                                tooltip="Приховати групи послуг"
                                tooltipOptions={{ position: "top" }}
                                onClick={() => setShowServiceGroupMenu(!showServiceGroupMenu)}
                            />
                            <Button icon="pi pi-plus"
                                severity="success"
                                tooltip="Додати групу"
                                tooltipOptions={{ position: "top" }}
                                onClick={handleGroupAdding}
                                className={`${commonStyle.addButton}`}
                            />
                        </div>
                        <Tree
                            value={treeHierarchy}
                            selectionMode="single"
                            dragdropScope="demo"
                            onDragDrop={(e) => setTreeHierarchy(e.value)}
                            onSelect={e => applyFilterFromMenu(e.node)}
                            className="w-full"
                            filter
                            filterMode="lenient"
                            filterPlaceholder="Пошук групи"
                            nodeTemplate={nodeTemplate}  // Use the nodeTemplate here
                        />
                    </>
                )}
            </div>}
            <div className={serviceGroupMenuClass()}>
                {!showServiceGroupMenu && <Button icon={`pi pi-step-forward-alt`}
                    severity="secondary"
                    outlined
                    tooltip="Показати групи товарів"
                    tooltipOptions={{ position: "top" }}
                    onClick={() => setShowServiceGroupMenu(!showServiceGroupMenu)}
                    className="mr-1"
                />}
                <DataTable value={services} stripedRows dataKey="id" lazy selectionMode="checkbox" selection={selectedServices} onSelectionChange={(e) => setSelectedServices(e.value)}
                    rows={lazyState.rows} paginator onPage={onPage} onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                    first={lazyState.first} loading={loading} totalRecords={totalRecords} header={tableHeader} onFilter={onFilter} filters={tableFilters} rowsPerPageOptions={[10, 20, 50]}
                    emptyMessage="Послуг не знайдено" style={{ width: '100%' }} >
                    <Column selectionMode="multiple" headerStyle={{ width: '3%' }} />
                    <Column field="name" header="Назва послуги" sortable filter filterElement={inputTemplate} onFilterApplyClick={filterApply} onFilterClear={() => clearFilter("name", "")} showFilterMatchModes={false} style={{ width: '27%' }}/>
                    <Column field="service_group" style={{ width: '20%' }} header="Назва групи" sortable body={rootGroupTemplate} />
                    <Column field="service_article" header="Артикул" sortable filter filterElement={inputTemplate} onFilterApplyClick={filterApply} onFilterClear={() => clearFilter("service_article", "")} showFilterMatchModes={false} style={{ width: '10%' }}/>
                    <Column field="price" style={{ width: '10%' }} header="Ціна" sortable body={rowData => formatCurrency(rowData.price, rowData.currency)} />
                    <Column field="priceUAH" style={{ width: '10%' }} header="Ціна, UAH" sortable body={rowData => formatCurrency(rowData.priceUAH, "UAH")} />
                    <Column field="is_active" style={{ width: '5%' }} header="Статус" body={rowData => <ProductStatusCircle rowData={rowData} type="statusCircle" />} />
                    <Column field="action" body={actionBodyTemplate} style={{ width: '15%' }} />
                </DataTable>
            </div>
            <ConfirmPopup group="headless" />
            <Sidebar visible={visible} position="right" className={commonStyle.pageSideBar} onHide={() => HideSideBar()}>
                <form onSubmit={serviceGroupFormik.handleSubmit}>
                    <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate}></Toolbar>
                    <div className="grid">
                        <div className={`${commonStyle.leftMenu} col-3`}>
                            <div className={commonStyle.formInput}>
                                <div className="p-inputgroup flex flex-column">
                                    <FloatLabel>
                                        <InputText
                                            name="name"
                                            value={serviceGroupFormik.values.name}
                                            onChange={(e) => { serviceGroupFormik.setFieldValue('name', e.target.value) }}
                                            className={serviceGroupFormik.errors.name && serviceGroupFormik.touched.name ? 'p-invalid' : ''}
                                        />
                                        <label>Назва групи</label>
                                    </FloatLabel>
                                    {serviceGroupFormik.errors.name && serviceGroupFormik.touched.name && (<small className={commonStyle.errorSmall}>{serviceGroupFormik.errors.name}</small>)}
                                </div>
                            </div>
                            <div className={commonStyle.formInput}>
                                <div className="p-inputgroup flex flex-column">
                                    <FloatLabel>
                                        <TreeSelect name="root_group" value={serviceGroupFormik.values.root_group} onChange={(e) => serviceGroupFormik.setFieldValue('root_group', e.value)} options={sortedGroups} showClear className="w-full" />
                                        <label>Батьківська група</label>
                                    </FloatLabel>
                                </div>
                            </div>
                            <div className={commonStyle.formInput}>
                                <div className="p-inputgroup flex flex-column">
                                    <FloatLabel>
                                        <Dropdown name="status" value={serviceGroupFormik.values.is_active} onChange={(e) => serviceGroupFormik.setFieldValue('is_active', e.value)} options={statusList} showClear className="w-full" />
                                        <label>Статус</label>
                                    </FloatLabel>
                                </div>
                            </div>
                        </div>
                        <div className="col-9">
                            <TabMenu model={items} />
                            {tabShowed === 0 && <DataTable header={rightToolbarTemplate} value={serviceGroupFormik.values.fields} rows={10} tableStyle={{ minWidth: '60rem' }} >
                                <Column field="name" header="Назва поля" sortable></Column>
                                <Column field="field_type" header="Тип поля" sortable></Column>
                                <Column field="mandatory" dataType="bool" align="center" body={ObligatoryBodyTemplate} header="Обов'язковість" ></Column>
                                <Column field="action" body={fieldsActionBodyTemplate} />
                            </DataTable>}
                            {tabShowed === 1 && <div className={commonStyle.tableStyle}>
                                <InputTextarea className={commonStyle.fullWidth} value={serviceGroupFormik.values.description} onChange={(e) => { serviceGroupFormik.setFieldValue('description', e.target.value) }} rows={10} />
                            </div>}
                            {tabShowed === 2 && <HistoryElement data={serviceGroupHistory} lazyState={historyLazyState} totalRecords={historyTotalRecords} onHistoryPage={onHistoryPage} isAct={false}/>}
                        </div>
                    </div>
                    <Dialog header="Додати поле до групи послуг"
                        visible={dialogVisible}
                        position="top"
                        style={{ width: '30vw', display: 'flex', flexDirection: 'column' }}
                        onHide={() => setDialogVisible(false)}
                        draggable={false}
                        resizable={false}
                    >
                        <form onSubmit={fieldsFormik.handleSubmit} style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                            <div className={commonStyle.dialogFormInput}>
                                <div className="p-inputgroup flex flex-column gap-1 md:w-22rem">
                                    <FloatLabel>
                                        <InputText
                                            name="name"
                                            value={fieldsFormik.values.name}
                                            onChange={(e) => { fieldsFormik.setFieldValue('name', e.target.value) }}
                                            className={fieldsFormik.errors.name && fieldsFormik.touched.name ? 'p-invalid' : ''}
                                        />
                                        <label>Назва поля</label>
                                    </FloatLabel>
                                    {fieldsFormik.errors.name && fieldsFormik.touched.name && (<small className={commonStyle.errorSmall}>{fieldsFormik.errors.name}</small>)}
                                </div>
                            </div>
                            <div className={commonStyle.dialogFormInput}>
                                <div className="p-inputgroup flex flex-column gap-1 md:w-22rem">
                                    <FloatLabel>
                                        <Dropdown
                                            name="field_type"
                                            value={fieldsFormik.values.field_type}
                                            onChange={(e) => fieldsFormik.setFieldValue('field_type', e.value)}
                                            options={fieldTypes}
                                            showClear
                                            className={fieldsFormik.errors.field_type && fieldsFormik.touched.field_type ? 'p-invalid w-full' : 'w-full'}
                                        />
                                        <label>Тип поля</label>
                                    </FloatLabel>
                                    {fieldsFormik.errors.field_type && fieldsFormik.touched.field_type && (<small className={commonStyle.errorSmall}>{fieldsFormik.errors.field_type}</small>)}
                                </div>
                            </div>
                            <div className={commonStyle.dialogFormInput}>
                                <div className={`${commonStyle.checkboxStyle} p-inputgroup flex-1 md:w-22rem"`}>
                                    <Checkbox
                                        name="mandatory"
                                        onChange={(e) => { fieldsFormik.setFieldValue('mandatory', !fieldsFormik.values.mandatory) }}
                                        checked={fieldsFormik.values.mandatory}
                                    />
                                    <label>Обовязковість</label>
                                </div>
                            </div>
                            <div className={commonStyle.dialogSubmit}>
                                <Button label="Додати поле" icon="pi pi-check" type="submit" className={commonStyle.addButton} severity="success" autoFocus />
                            </div>
                        </form>
                    </Dialog>
                </form>
            </Sidebar>
        </div>
    </>)
}

export default Services;