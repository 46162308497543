import Cookies from "js-cookie";

export const findKeyByValue = (array, value) => {

    if(!Array.isArray(array)){
        array = [array];
    }

    for (const member of array) {
        const entry = Object.entries(member).find(([key, val]) => val === value);
        if (entry) {
            return entry[0];
        }
    }
    return null; // Return null if the value is not found
};

export const convertSumWithLatestCurrencyRate = (currency, value) => {
    if(currency === "USD"){
        return value * Cookies.get("USDRate");
    }
    else if(currency === "EUR"){
        return value * Cookies.get("EURRate");
    }
    else{
        return value;
    }
}

export const formatCurrency = (value, currency) => {
    const locale = currency === "UAH" ? "ua-UA" : "en-EN";

    return value?.toLocaleString(locale, { style: 'currency', currency: currency });
};

export const formatShortDate = (unformattedDate) => {
    const date = new Date(unformattedDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}

export const formatDate = (unformattedDate) => {
    const date = new Date(unformattedDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
}

export const buildGroupHierarchy = (data) => {

    const buildGroupHierarchy = (group, parentId = '') => {
        const newGroup = {
            key: parentId ? `${parentId}-${group.id}` : group.id,
            label: group.group_name,
            data: group.id,
            children: group.sub_groups && group.sub_groups.length > 0
                ? group.sub_groups.map(subgroup => buildGroupHierarchy(subgroup, `${parentId ? `${parentId}-${group.id}` : group.id}`))
                : null,
        };
        return newGroup;
    };

    return data.map(group => buildGroupHierarchy(group))
}

export const findGroupCode = (groups, targetId) => {
    // Recursive helper function to search through the hierarchy
    const search = (group, parentPath = '') => {
        const currentPath = parentPath ? `${parentPath}-${group.data}` : `${group.data}`;
        
        // Check if the current group's data matches the target ID
        if (group.data === targetId) {
            return currentPath;
        }

        // If the group has children, search within them
        if (group.children && group.children.length > 0) {
            for (let child of group.children) {
                const result = search(child, currentPath);
                if (result) {
                    return result;  // Return the full path if found
                }
            }
        }

        return null;  // Return null if not found
    };

    // Iterate over all top-level groups to start the search
    for (let group of groups) {
        const fullPath = search(group);
        if (fullPath) {
            return fullPath;  // Return the full path if found
        }
    }

    return null;  // Return null if the target ID was not found
};