import { useCallback } from 'react';
import useAuthenticatedRequest from './TokenrRefreshUtils';
import { formatShortDate, getGroupCode } from './Utils';

const useApi = () => {
    const authenticatedRequest = useAuthenticatedRequest();

    const handleApiCall = useCallback(async (url, options) => {
        const response = await authenticatedRequest(url, options);
        const clientResponse = {};
        clientResponse.status = response.status;
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            try{
                clientResponse.data = await response.json();
            }
            catch(error){
                console.log("Error parsing response", error)
            }
        }
        return clientResponse;
    }, [authenticatedRequest]);

    const handleApiCallUnParsed = useCallback(async (url, options) => {
        const response = await authenticatedRequest(url, options);

        return response;
    }, [authenticatedRequest]);

    const appendFilterToUrl = (url, filterKey, filterValue) => {
        return (filterValue !== null && filterValue !== undefined && filterValue !== "") ? `${url}&${filterKey}=${encodeURIComponent(filterValue)}` : url;
    };

    //accounting_payments controller
    const createPayment = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}accounting_payments/create/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCall]);

    const deletePaymentFromAct = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}accounting_payments/delete_payment_on_act_distribution/`;
        return handleApiCallUnParsed(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCallUnParsed]);

    const deletePaymentFromTransaction = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}accounting_payments/delete_payment_on_transaction_distribution/`;
        return handleApiCallUnParsed(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCallUnParsed]);

    const distributePaymentOnTransaction = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}accounting_payments/distribute_existing_payment_on_transaction/`;
        return handleApiCallUnParsed(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCallUnParsed]);

    const fetchActPayments = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}accounting_payments/get_payments_by_act/?act_id=${id}`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchActUnpaidBalance = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}accounting_payments/get_act_unpaid_balance/?act_id=${id}`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const fetchPaymentHistory = useCallback((id, state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}accounting_payments/get_history/${id}/?page=${state.page}&page_size=${state.rows}`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchPayments = useCallback((state) => {
        console.log(state);
        let url = `${process.env.REACT_APP_BACKEND_URL}accounting_payments/get_list/?page=${state.page}&page_size=${state.rows}`;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);
        url = appendFilterToUrl(url, "cagent", state.filters.cagent?.value);
        url = appendFilterToUrl(url, "method", state.filters.method?.value);
        
        if(state.filters.status?.value){
            state.filters.status.value.forEach(status => {
                url = appendFilterToUrl(url, "status", status);
            });
        }

        if (state.filters.date?.value && state.filters.date?.value[1] !== null)
        {
            url = appendFilterToUrl(url, "start_payment_date", formatShortDate(state.filters.date?.value[0]));
            url = appendFilterToUrl(url, "end_payment_date", formatShortDate(state.filters.date?.value[1] ?? state.filters.date?.value[0]));
        }

        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const fetchPaymentTransactions = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}accounting_payments/get_payment_distributed_transactions/?payment_id=${id}`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    //accounting_transactions controller
    const createMutualSettlementByClient = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}accounting_transactions/create_offset_by_cagent/`;
        return handleApiCallUnParsed(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCallUnParsed]);

    const fetchTransactions = useCallback((state) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}accounting_transactions/get_list/?page=${state.page}&page_size=${state.rows}`;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);
        url = appendFilterToUrl(url, "type", state.filters.type?.value);
        url = appendFilterToUrl(url, "pay_status", state.filters.pay_status?.value);

        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const fetchTransactionPayments = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}accounting_transactions/get_distributed_payments/?transaction_id=${id}`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    //auth controller
    const changeUserStatus = useCallback((id, action) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}auth/${action}/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const createUser = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}auth/create_user/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);

    const fetchUserData = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}auth/users/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchUserActivity = useCallback((id, state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}auth/get_user_activity/${id}/?page=${state.page}&page_size=${state.rows}`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchUserHistory = useCallback((id, state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}auth/get_user_history/${id}/?page=${state.page}&page_size=${state.rows}`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchUserLogins = useCallback((id, state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}auth/get_user_login_data/${id}/?page=${state.page}&page_size=${state.rows}`;
        console.log(url);
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchUsersList = useCallback((state) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}auth/users_list/?page=${state.page}&page_size=${state.rows}`;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const fetchUserPermissions = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}auth/get_user_permissions/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const updateUser = useCallback((id, request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}auth/update_user/${id}/update/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);

    //cagents controller
    const createCagent = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}cagents/create/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCall]);

    const deleteCagent = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}cagents/delete/${id}/`;
        return handleApiCall(url, { method: 'DELETE' });
    },[handleApiCall]);

    const fetchCagentDetails = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}cagents/get_details/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchCagentHistory = useCallback((id, state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}cagents/get_history/${id}/?page=${state.page}&page_size=${state.rows}`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchClients = useCallback((state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}cagents/get_clients/?page=${state.page}&page_size=${state.rows}`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const fetchPartners = useCallback((state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}cagents/get_partners/?page=${state.page}&page_size=${state.rows}`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const fetchSettlements = useCallback((state) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}cagents/get_settlements/?page=${state.page}&page_size=${state.rows}`;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);
        url = appendFilterToUrl(url, "name", state.filters.name?.value);
        console.log(url);
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const updateCagent = useCallback((id, request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}cagents/update/${id}/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCall]);

    //client_return_acts controller
    const createClientReturnAct = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}client_return_acts/create/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCall]);

    const fetchClientReturnActDetails = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}client_return_acts/get_details/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchClientReturnActHistory = useCallback((id, state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}client_return_acts/get_history/${id}/?page=${state.page}&page_size=${state.rows}`;
        console.log(url);
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchClientReturnActs = useCallback((state) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}client_return_acts/get_list/?page=${state.page}&page_size=${state.rows}`;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);
        url = appendFilterToUrl(url, "cagent", state.filters.cagent?.value);
        url = appendFilterToUrl(url, "division", state.filters.division?.value);
        url = appendFilterToUrl(url, "pay_status", state.filters.pay_status?.value);
        url = appendFilterToUrl(url, "start_act_date", state.filters.start_date?.value);
        url = appendFilterToUrl(url, "end_act_date", state.filters.end_date?.value);

        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const finalizeClientReturnAct = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}client_return_acts/finalize/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const deleteClientReturnAct = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}client_return_acts/delete/${id}/`;
        return handleApiCall(url, { method: 'DELETE' });
    },[handleApiCall]);

    const updateClientReturnAct = useCallback((id, request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}client_return_acts/update/${id}/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCall]);

    //currency controller
    const addCurrencyRate = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}currency/add_exchange_rate/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCall]);

    const fetchCurrenciesList = useCallback(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}currency/get_currencies_list/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchCurrencyHistory = useCallback((currency, state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}currency/get_history/?currency=${currency}&page=${state.page}&page_size=${state.rows}`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    //dashboards controller
    const fetchCostsWidget = useCallback((dates) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}dashboards/costs_widget/`;
        if(dates != null){
            url += `?start_date=${formatShortDate(dates[0])}&end_date=${formatShortDate(dates[1])}`
        }
        console.log(url);
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchNetProfitWidget = useCallback((dates) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}dashboards/net_profit_widget/`;
        if(dates != null){
            url += `?start_date=${formatShortDate(dates[0])}&end_date=${formatShortDate(dates[1])}`
        }
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchPayableWidget = useCallback(() => {
        let url = `${process.env.REACT_APP_BACKEND_URL}dashboards/payable_widget/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchProductCountWidget = useCallback((dates) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}dashboards/count_products_widget/`;
        if(dates != null){
            url += `?start_date=${formatShortDate(dates[0])}&end_date=${formatShortDate(dates[1])}`
        }
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchReceivableWidget = useCallback(() => {
        let url = `${process.env.REACT_APP_BACKEND_URL}dashboards/receivable_widget/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);
    
    const fetchRevenueWidget = useCallback((dates) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}dashboards/revenue_widget/`;
        if(dates != null){
            url += `?start_date=${formatShortDate(dates[0])}&end_date=${formatShortDate(dates[1])}`
        }
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchServicesGivenWidget = useCallback((dates) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}dashboards/count_services_widget`;
        if(dates != null){
            url += `?start_date=${formatShortDate(dates[0])}&end_date=${formatShortDate(dates[1])}`
        }
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    //distributor_return_acts
    const createDistributorReturnAct = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}distributor_return_acts/create/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall])

    const deleteDistributorReturnAct = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}distributor_return_acts/delete/${id}/`;
        return handleApiCall(url,
            {
                method: 'DELETE'
            });
    }, [handleApiCall]);

    const fetchDistributorReturnActs = useCallback((state) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}distributor_return_acts/get_list/?page=${state.page}&page_size=${state.rows}`;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);

        url = appendFilterToUrl(url, "pay_status", state.filters.pay_status?.value);
        url = appendFilterToUrl(url, "status", state.filters.status?.value);
        url = appendFilterToUrl(url, "cagent", state.filters.cagent?.value);
        url = appendFilterToUrl(url, "division", state.filters.division?.value);

        console.log(url);

        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const fetchDistributorReturnActDetails = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}distributor_return_acts/get_details/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchDistributorReturnActHistory = useCallback((id, state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}distributor_return_acts/get_history/${id}/?page=${state.page}&page_size=${state.rows}`;
        console.log(url);
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const finalizeDistributorReturnAct = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}distributor_return_acts/finalize/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const updateDistributorReturnAct = useCallback((id, request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}distributor_return_acts/update/${id}/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);

    //divisions controller
    const changeDivisionStatus = useCallback((id, action) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}divisions/${action}/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const createDivision = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}divisions/create/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCall]);

    const deleteDivision = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}divisions/delete/${id}/`;
        return handleApiCall(url, { method: 'DELETE' });
    },[handleApiCall]);

    const fetchDivisionDetails = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}divisions/get_division/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchDivisionHistory = useCallback((id, state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}divisions/get_history/${id}/?page=${state.page}&page_size=${state.rows}`;
        console.log(url);
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchDivisions = useCallback((state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}divisions/get_list/?page=${state.page}&page_size=${state.rows}`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const updateDivision = useCallback((id, request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}divisions/update/${id}/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);

    //enums controller
    const fetchPermissions = useCallback(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}enums/permissions/`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const fetchProductsExtended = useCallback(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}enums/products_extended/`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const fetchServicesExtended = useCallback(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}enums/services_extended/`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const fetchUserEnum = useCallback(() =>{
        const url = `${process.env.REACT_APP_BACKEND_URL}enums/users/`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall])
    
    //organization controller
    const fetchOrganizationDetails = useCallback(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}organization/get_details/`;
        return handleApiCall(url, { method: "GET"});
    },[handleApiCall]);

    const uploadOrganizationLogo = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}organization/update_logo/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                redirect: "follow",
                body: request
            });
    },[handleApiCall]);

    //products controller
    const changeProductGroupStatus = useCallback((id, action) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}products/groups/${action}/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const changeProductStatus = useCallback((id, action) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}products/products/${action}/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const createProduct = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}products/products/create/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCall]);

    const createProductGroup = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}products/groups/create/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCall]);

    const deleteProduct = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}products/products/delete/`;
        return handleApiCall(url,
            {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);

    const deleteProductGroup = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}products/groups/delete/`;
        return handleApiCall(url, 
            {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            }
        );
    },[handleApiCall]);

    const fetchProductDetails = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}products/products/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchProductGroupDetails = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}products/groups/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchProductGroupHistory = useCallback((id, state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}products/groups/get_history/${id}/?page=${state.page}&page_size=${state.rows}`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchProductGroups = useCallback((onlyActive, state) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}products/groups/?page=${state.page}&page_size=${state.rows}`;
        url = onlyActive ? url + "&is_active=" + onlyActive : url;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);

        url = appendFilterToUrl(url, "group_name", encodeURIComponent(state.filters.group_name?.value));
        url = appendFilterToUrl(url, "root_group", getGroupCode(state.filters.root_group?.value));
        url = appendFilterToUrl(url, "created_by", state.filters.created_by?.value);

        if (state.filters.created_at?.value && state.filters.created_at?.value[1] !== null)
        {
            url = appendFilterToUrl(url, "created_at_start_date", formatShortDate(state.filters.created_at?.value[0]));
            url = appendFilterToUrl(url, "created_at_end_date", formatShortDate(state.filters.created_at?.value[1] ?? state.filters.created_at?.value[0]));
        }

        console.log(url);
        return handleApiCall(url, { method: "GET"});
    }, [handleApiCall]);

    const fetchProductHistory = useCallback((id, state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}products/products/get_history/${id}/?page=${state.page}&page_size=${state.rows}`;
        console.log(url);
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchProducts = useCallback((onlyActive, state) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}products/products/?page=${state.page}&page_size=${state.rows}`;
        url = onlyActive ? url + "&is_active=" + onlyActive : url;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);

        url = appendFilterToUrl(url, "product_name", state.filters.product_name?.value);
        url = appendFilterToUrl(url, "product_group", getGroupCode(state.filters.product_group?.value));
        url = appendFilterToUrl(url, "product_article", state.filters.product_article?.value);
        url = appendFilterToUrl(url, "created_by", state.filters.created_by?.value);

        if (state.filters.created_at?.value && state.filters.created_at?.value[1] !== null) {
            url = appendFilterToUrl(url, "created_at_start_date", formatShortDate(state.filters.created_at?.value[0]));
            url = appendFilterToUrl(url, "created_at_end_date", formatShortDate(state.filters.created_at?.value[1] ?? state.filters.created_at?.value[0]));
        }

        console.log(url);

        return handleApiCall(url, { method: "GET"});
    }, [handleApiCall]);

    const updateProductGroup = useCallback((id, request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}products/groups/${id}/update/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);

    const updateProduct = useCallback((id, request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}products/products/${id}/update/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);

    //receive_acts controller
    const createReceptionAct = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}receive_acts/create/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCall]);

    const deleteReceptionAct = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}receive_acts/delete/${id}/`;
        return handleApiCall(url, { method: 'DELETE' });
    },[handleApiCall]);

    const fetchReceptionActDetails = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}receive_acts/get_details/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchReceptionActHistory = useCallback((id, state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}receive_acts/get_history/${id}/?page=${state.page}&page_size=${state.rows}`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchReceptionActs = useCallback((state) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}receive_acts/get_list/?page=${state.page}&page_size=${state.rows}`;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);
        url = appendFilterToUrl(url, "cagent", state.filters.cagent?.value);
        url = appendFilterToUrl(url, "division", state.filters.division?.value);
        url = appendFilterToUrl(url, "pay_status", state.filters.pay_status?.value);
        url = appendFilterToUrl(url, "start_act_date", state.filters.start_date?.value);
        url = appendFilterToUrl(url, "end_act_date", state.filters.end_date?.value);

        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const finalizeReceptionAct = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}receive_acts/make_finalized/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const updateReceptionAct = useCallback((id, request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}receive_acts/update/${id}/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);

    //remainings controller
    const fetchPurchases = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}remainings/get_last_prices_by_remaining/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);

    const fetchRemainingQRCode = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}remainings/get_pdf_with_qr_code/`;
        return handleApiCallUnParsed(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCallUnParsed]);

    const fetchRemainings = useCallback((state) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}remainings/get_remainings/?page=${state.page}&page_size=${state.rows}`;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);

        url = appendFilterToUrl(url, "product_group", state.filters.product_group?.value);
        url = appendFilterToUrl(url, "product_name", state.filters.product_name?.value);
        url = appendFilterToUrl(url, "partner_id", state.filters.partner_id?.value);

        console.log(url);
        return handleApiCall(url, { method: "GET"});
    }, [handleApiCall]);

    const updateProductPrice = useCallback((request) => {

        const url = `${process.env.REACT_APP_BACKEND_URL}remainings/update_sale_price/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);
    
    //roles controller
    const createUserRole = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}roles/create/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCall]);

    const deleteUserRole = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}roles/delete/${id}/`;
        return handleApiCall(url, { method: 'DELETE' });
    },[handleApiCall]);

    const fetchUserRoleDetails = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}roles/get_details/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchUserRoles = useCallback((state) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}roles/get_list/`;
        url = state === undefined ? url : url + "?page=" + state.page + "&page_size=" + state.rows;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const updateUserRole = useCallback((id, request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}roles/update/${id}/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);

    //sale_acts controller
    const createSaleAct = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}sale_acts/create/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCall]);

    const deleteSaleAct = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}sale_acts/delete/${id}/`;
        return handleApiCall(url, { method: 'DELETE' });
    },[handleApiCall]);

    const fetchSaleActDetails = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}sale_acts/get_details/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchSaleActHistory = useCallback((id, state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}sale_acts/get_history/${id}/?page=${state.page}&page_size=${state.rows}`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchSaleActs = useCallback((state) =>{
        let url = `${process.env.REACT_APP_BACKEND_URL}sale_acts/get_list/?page=${state.page}&page_size=${state.rows}`;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);
        url = appendFilterToUrl(url, "cagent", state.filters.cagent?.value);
        url = appendFilterToUrl(url, "division", state.filters.division?.value);
        url = appendFilterToUrl(url, "pay_status", state.filters.pay_status?.value);
        url = appendFilterToUrl(url, "status", state.filters.status?.value);

        if (state.filters.date?.value && state.filters.date?.value[1] !== null) {
            url = appendFilterToUrl(url, "start_act_date", formatShortDate(state.filters.date?.value[0]));
            url = appendFilterToUrl(url, "end_act_date", formatShortDate(state.filters.date?.value[1] ?? state.filters.date?.value[0]));
        }
        console.log(url);
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const finalizeSaleAct = useCallback((id) =>{
        const url = `${process.env.REACT_APP_BACKEND_URL}sale_acts/make_finalized/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const updateSaleAct = useCallback((id, request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}sale_acts/update/${id}/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);

    //services controller
    const changeServiceGroupStatus = useCallback((id, action) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}services/groups/${action}/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const changeServiceStatus = useCallback((id, action) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}services/services/${action}/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const createService = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}services/services/create/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCall]);

    const createServiceGroup = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}services/groups/create/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCall]);

    const deleteService = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}services/services/delete/`;
        return handleApiCall(url, 
            {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request) 
            });
    }, [handleApiCall])

    const deleteServiceGroup = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}services/groups/delete/`;
        return handleApiCall(url, 
            {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request) 
            });
    }, [handleApiCall]);

    const fetchServiceDetails = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}services/services/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const fetchServiceGroupDetails = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}services/groups/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchServiceGroupHistory = useCallback((id, state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}services/groups/get_history/${id}/?page=${state.page}&page_size=${state.rows}`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchServiceGroups = useCallback((onlyActive, state) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}services/groups/?page=${state.page}&page_size=${state.rows}`;
        url = onlyActive ? url + "&is_active=" + onlyActive : url;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);
        return handleApiCall(url, { method: "GET"});
    }, [handleApiCall]);

    const fetchServiceHistory = useCallback((id, state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}services/services/get_history/${id}/?page=${state.page}&page_size=${state.rows}`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchServices = useCallback((state) => {
        console.log(state);
        let url = `${process.env.REACT_APP_BACKEND_URL}services/services/?page=${state.page}&page_size=${state.rows}`;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);

        url = appendFilterToUrl(url, "service_group", state.filters.service_group?.value);
        url = appendFilterToUrl(url, "name", state.filters.name?.value);
        url = appendFilterToUrl(url, "service_article", state.filters.service_article?.value);

        console.log(url);

        return handleApiCall(url, { method: "GET"});
    },[handleApiCall]);

    const updateService = useCallback((id, request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}services/services/${id}/update/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);

    const updateServiceGroup = useCallback((id, request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}services/groups/${id}/update/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);

    //write_off_acts
    const createWriteOffAct = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}write_off_acts/create/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCall]);

    const deleteWriteOffAct = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}write_off_acts/delete/${id}/`;
        return handleApiCall(url, { method: 'DELETE' });
    },[handleApiCall]);

    const fetchWriteOffActDetails = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}write_off_acts/get_details/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchWriteOffActs = useCallback((state) =>{
        let url = `${process.env.REACT_APP_BACKEND_URL}write_off_acts/get_list/?page=${state.page}&page_size=${state.rows}`;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);
       
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const finalizeWriteOffAct = useCallback((id) =>{
        const url = `${process.env.REACT_APP_BACKEND_URL}write_off_acts/finalize/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchWriteOffActHistory = useCallback((id, state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}write_off_acts/get_history/${id}/?page=${state.page}&page_size=${state.rows}`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const updateWriteOffAct = useCallback((id, request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}write_off_acts/update/${id}/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);

    return {
        addCurrencyRate,
        changeDivisionStatus,
        changeProductGroupStatus,
        changeProductStatus,
        changeServiceGroupStatus,
        changeServiceStatus,
        changeUserStatus,
        createCagent,
        createClientReturnAct,
        createDistributorReturnAct,
        createDivision,
        createMutualSettlementByClient,
        createPayment,
        createProduct,
        createProductGroup,
        createReceptionAct,
        createSaleAct,
        createService,
        createServiceGroup,
        createUser,
        createUserRole,
        createWriteOffAct,
        deleteCagent,
        deleteClientReturnAct,
        deleteDistributorReturnAct,
        deleteDivision,
        deletePaymentFromAct,
        deletePaymentFromTransaction,
        deleteProduct,
        deleteProductGroup,
        deleteReceptionAct,
        deleteSaleAct,
        deleteService,
        deleteServiceGroup,
        deleteUserRole,
        deleteWriteOffAct,
        distributePaymentOnTransaction,
        fetchActPayments,
        fetchActUnpaidBalance,
        fetchCagentDetails,
        fetchCagentHistory,
        fetchClientReturnActDetails,
        fetchClientReturnActHistory,
        fetchClientReturnActs,
        fetchClients,
        fetchCostsWidget,
        fetchCurrenciesList,
        fetchCurrencyHistory,
        fetchDivisions,
        fetchNetProfitWidget,
        fetchOrganizationDetails,
        fetchDistributorReturnActDetails,
        fetchDistributorReturnActHistory,
        fetchDistributorReturnActs,
        fetchDivisionDetails,
        fetchDivisionHistory,
        fetchPartners,
        fetchPayableWidget,
        fetchPaymentHistory,
        fetchPayments,
        fetchPaymentTransactions,
        fetchPermissions,
        fetchProductCountWidget,
        fetchProductDetails,
        fetchProductGroupDetails,
        fetchProductGroupHistory,
        fetchProductGroups,
        fetchProductHistory,
        fetchProducts,
        fetchProductsExtended,
        fetchPurchases,
        fetchReceivableWidget,
        fetchReceptionActs,
        fetchReceptionActDetails,
        fetchReceptionActHistory,
        fetchRemainingQRCode,
        fetchRemainings,
        fetchRevenueWidget,
        fetchSaleActDetails,
        fetchSaleActHistory,
        fetchSaleActs,
        fetchServiceDetails,
        fetchServiceHistory,
        fetchServices,
        fetchServicesExtended,
        fetchServicesGivenWidget,
        fetchServiceGroupDetails,
        fetchServiceGroupHistory,
        fetchServiceGroups,
        fetchSettlements,
        fetchTransactionPayments,
        fetchTransactions,
        fetchUserActivity,
        fetchUserData,
        fetchUserEnum,
        fetchUserHistory,
        fetchUserLogins,
        fetchUsersList,
        fetchUserPermissions,
        fetchUserRoleDetails,
        fetchUserRoles,
        fetchWriteOffActDetails,
        fetchWriteOffActHistory,
        fetchWriteOffActs,
        finalizeClientReturnAct,
        finalizeDistributorReturnAct,
        finalizeReceptionAct,
        finalizeSaleAct,
        finalizeWriteOffAct,
        updateCagent,
        updateClientReturnAct,
        updateDistributorReturnAct,
        updateDivision,
        updateProduct,
        updateProductGroup,
        updateProductPrice,
        updateReceptionAct,
        updateSaleAct,
        updateService,
        updateServiceGroup,
        updateUser,
        updateUserRole,
        updateWriteOffAct,
        uploadOrganizationLogo
    };
};

export default useApi;