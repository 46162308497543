import { useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { UserPermissionContext } from "../context/UserPermissionProvider";

import Cookies from "js-cookie";
import NavigationBar from "./Navigation/NavigationBar";
import PageBar from "./content/PageBar";

import { Sidebar } from "primereact/sidebar";

import Authorization from "./Authorization/Authorization";
//Dashboards
import DashBoards from "./content/PageContent/DashBoards/DashBoards";
// Products and services
import ProductGroups from "./content/PageContent/ProductGroups";
import ProductGroupsCreation from "./content/PageContent/ProductsAndServices/ProductGroupsCreation";
import Products from "./content/PageContent/ProductsAndServices/Products";
import ProductCreation from "./content/PageContent/ProductsAndServices/ProductCreation";
import ServiceGroups from "./content/PageContent/ProductsAndServices/ServiceGroups";
import ServiceGroupCreation from "./content/PageContent/ProductsAndServices/ServiceGroupCreation";
import Services from "./content/PageContent/ProductsAndServices/Services";
import ServiceCreation from "./content/PageContent/ProductsAndServices/ServiceCreation";
//Accounting
import ProductsReception from "./content/PageContent/Accounting/ProductsReception";
import NewProductsReception from "./content/PageContent/Accounting/NewProductsReception";
import ProductsMove from "./content/PageContent/ProductsMove";
import NewProductsMove from "./content/PageContent/NewProductsMove";
import LeftOvers from "./content/PageContent/Accounting/LeftOvers";
import Writeoffs from "./content/PageContent/Accounting/Writeoffs";
import NewWriteOff from "./content/PageContent/Accounting/NewWriteOff";
//Product sales
import Sales from "./content/PageContent/Sales/Sales";
import NewSale from "./content/PageContent/Sales/NewSale";
import ProductsReturn from "./content/PageContent/Sales/ProductsReturn";
import NewProductsReturn from "./content/PageContent/Sales/NewProductsReturn";
//CounterAgents 
import Partners from "./content/PageContent/CounterAgents/Partners";
import Clients from "./content/PageContent/CounterAgents/Clients";
import NewCounterAgent from "./content/PageContent/CounterAgents/NewCounterAgent";
//Bookkeeping
import Transactions from "./content/PageContent/BookKeeping/Transactions";
import Payments from "./content/PageContent/BookKeeping/Payments";
//Settings
import Organization from "./content/PageContent/Settings/Organization";
import Users from "./content/PageContent/Settings/Users";
import NewUser from "./content/PageContent/Settings/NewUser";
import UserRoles from "./content/PageContent/Settings/UserRoles";
import NewUserRole from "./content/PageContent/Settings/NewUserRole";
import Divisions from "./content/PageContent/Settings/Divisions";
import NewDivision from "./content/PageContent/Settings/NewDivision";
import Currencies from "./content/PageContent/Settings/Currencies";
import CurrencyRates from "./content/PageContent/Settings/CurrencyRates";

import classes from "./Layout.module.css";
import { OrgInfoContext } from "../context/OrgInfoProvider";
import DistributorReturns from "./content/PageContent/Accounting/DistributorReturns";
import DistributorReturnDetails from "./content/PageContent/Accounting/DistributorReturnDetails";
import Settlements from "./content/PageContent/BookKeeping/Settlements";
import HomePage from "./content/PageContent/HomePage";

const Layout = params => {

  const navigate = useNavigate();
  const {loadPermissions, permissions} = useContext(UserPermissionContext);
  const {loadOrgData, orgLogo}= useContext(OrgInfoContext);

  const [navBarVisible, setNavBarVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1180);
  const [visible, setVisible] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1180);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (permissions === null) {
      loadPermissions(Cookies.get("userId"));
    }
  }, [loadPermissions, permissions]);

  useEffect(() => {
    if(orgLogo === null){
      loadOrgData();
    }
  },[loadOrgData, orgLogo]);

  const UnloginHandler = () =>{
    console.log("unloginned");
    params.onLogoff(false);
  }

  const ChangeBarStateHandler = () => {
    if(isMobile){
      setVisible(!visible);
    }
    else{
      setNavBarVisible(!navBarVisible);
    }
  }
  
  const SwitchPagesHandler = () => {
    setVisible(!visible);
  }

  const mainContentStyle = () => {
    if(isMobile){
      return "col-12";
    }
    else{
      return navBarVisible ? "col-10" : "col-12"
    }
  }

  return (
    <div className="grid">
      {isMobile && <Sidebar 
        visible={visible} 
        position="left" 
        onHide={() => setVisible(false)}
        content={() => (<>
          <div className="flex align-items-center justify-content-center pt-3">
              <img src={orgLogo} height="80" alt='iHome' className={classes.logoStyle} onClick={() => navigate("/")}/>
          </div>
          <NavigationBar switchPage={SwitchPagesHandler}/>
        </>)}
      />}
      {(!isMobile && navBarVisible) && <div className="col-2">
        <div className={classes.companyLogo}>
            <img src={orgLogo} alt={"IHomeLogo"} width={205} height={125} onClick={() => navigate("/")}/>
        </div>
        <NavigationBar switchPage={SwitchPagesHandler} />
      </div>}
      <div className={mainContentStyle()}>
        <PageBar setUnloginned={UnloginHandler} changeNavBarState={ChangeBarStateHandler}/>
        <Routes>
          <Route exact path="/authorization" element={<Authorization />}/>
          <Route exact path="/" element={permissions?.includes("dashboards.view_dashboard") ? <DashBoards /> : <HomePage/>} />
          <Route exact path="/widgets" element={<DashBoards />} />
          <Route exact path="/productgroups" element={<ProductGroups />} />
          <Route exact path="/productgroups/createproductgroup" element={<ProductGroupsCreation />} />
          <Route exact path="/productgroups/editproductgroup/:id" element={<ProductGroupsCreation />} />
          <Route exact path="/products" element={<Products />} />
          <Route exact path="/products/createproduct" element={<ProductCreation />}/>
          <Route exact path="/products/editproduct/:id" element={<ProductCreation />}/>
          <Route exact path="/servicegroups" element={<ServiceGroups/>}/>
          <Route exact path="/servicegroups/createservicegroup" element={<ServiceGroupCreation/>}/>
          <Route exact path="/servicegroups/editgroup/:id" element={<ServiceGroupCreation />} /> 
          <Route exact path="/services" element={<Services/>}/>
          <Route exact path="/services/createservice" element={<ServiceCreation/>}/>      
          <Route exact path="/services/editservice/:id" element={<ServiceCreation/>}/>      
          <Route exact path="/productsreception" element={<ProductsReception />}/>
          <Route exact path="/productsreception/newreception" element={<NewProductsReception />}/>
          <Route exact path="/productsreception/editreception/:id" element={<NewProductsReception />}/>
          <Route exact path="/productsmove" element={<ProductsMove />}/>
          <Route exact path="/productsmove/newproductsmove" element={<NewProductsMove />}/>
          <Route exact path="/leftovers" element={<LeftOvers />}/>
          <Route exact path="/writeoff" element={<Writeoffs />}/>
          <Route exact path="/writeoff/newwriteoff" element={<NewWriteOff />}/>
          <Route exact path="/writeoff/editwriteoff/:id" element={<NewWriteOff />}/>
          <Route exact path="/distributorreturns" element={<DistributorReturns />}/>
          <Route exact path="/distributorreturns/newreturn" element={<DistributorReturnDetails />}/>
          <Route exact path="/distributorreturns/editreturn/:id" element={<DistributorReturnDetails />}/>
          <Route exact path="/productsreturn" element={<ProductsReturn />}/>
          <Route exact path="/productsreturn/newproductsreturn" element={<NewProductsReturn />}/>
          <Route exact path="/productsreturn/editproductsreturn/:id" element={<NewProductsReturn />}/>
          <Route exact path="/sales" element={<Sales />}/>
          <Route exact path="/sales/newsale" element={<NewSale/>}/>
          <Route exact path="/sales/editsale/:id" element={<NewSale/>}/>
          <Route exact path="/partners" element={<Partners/>}/>
          <Route exact path="/clients" element={<Clients/>}/>
          <Route exact path="/partners/newcounteragent" element={<NewCounterAgent/>}/>
          <Route exact path="/clients/newcounteragent" element={<NewCounterAgent/>}/>
          <Route exact path="/clients/editcounteragent/:id" element={<NewCounterAgent/>}/>
          <Route exact path="/partners/editcounteragent/:id" element={<NewCounterAgent/>}/>
          <Route exact path="/transactions" element={<Transactions/>}/>
          <Route exact path="/payments" element={<Payments/>}/>
          <Route exact path="/settlements" element={<Settlements/>}/>
          <Route exact path="/organization" element={<Organization />}/>
          <Route exact path="/users" element={<Users />}/>
          <Route exact path="/users/newuser" element={<NewUser/>}/>
          <Route exact path="/users/edituser/:id" element={<NewUser />} />
          <Route exact path="/roles" element={<UserRoles/>}/>
          <Route exact path="/roles/newuserrole" element={<NewUserRole/>}/>
          <Route exact path="/roles/edituserrole/:id" element={<NewUserRole/>}/>
          <Route exact path="/divisions" element={<Divisions/>}/>
          <Route exact path="/divisions/newdivision" element={<NewDivision/>}/>
          <Route exact path="/divisions/editdivision/:id" element={<NewDivision/>}/>
          <Route exact path="/currencies" element={<Currencies/>}/>
          <Route exact path="/currencies/currencyrates/:id" element={<CurrencyRates />} />
        </Routes>
      </div>
    </div>
  );
};

export default Layout;