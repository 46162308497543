import useApi from "../../../../Utils/BackendClient";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { convertSumWithLatestCurrencyRate, formatCurrency } from "../../../../Utils/Utils";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner"
import { PanelMenu } from "primereact/panelmenu";
import { Sidebar } from "primereact/sidebar";
import { Toast } from "primereact/toast";

import commonStyle from "../../CommonStyles.module.css";

const LeftOvers = () => {
    
    const currencies = ["USD", "UAH", "EUR"];
    const navigate = useNavigate();
    const toast = useRef(null);
    const { fetchProductsExtended, fetchRemainingQRCode, fetchRemainings, updateProductPrice,  } = useApi();

    const [productCategoriesLoading, setProductCategoriesLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [leftovers, setLeftOvers] = useState(null);
    const [loading, setLoading] = useState(true);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 20,
        page: 1,
        sortField: null,
        sortOrder: null,
    });
    const [filterGroup, setFilterGroup] = useState(null);
    const [expandedKeys, setExpandedKeys] = useState({});
    const [visible, setVisible] = useState(false);
    const [changePriceSiderBarVisible, setChangePriceSiderBarVisible] = useState(false);
    const [saleItem, setSaleItem] = useState([]);
    const [reloadData, setReoladData] = useState(false);

    useEffect(() => {
        const fetchData = async () => {

            const response = await fetchProductsExtended();

            if(response.status === 200){
                setProducts(response.data);
                setProductCategoriesLoading(false);
            }
        }

        fetchData();

    }, [fetchProductsExtended]);

    useEffect(() => {

        const fetchData = async () => {

            const response = await fetchRemainings(lazyState, filterGroup);

            if(response.status === 200)
            {
                setTotalRecords(response.data.count);
                setLeftOvers(response.data.results);
                setLoading(false);
            }
        }

        fetchData();
    }, [lazyState, filterGroup, fetchRemainings, reloadData]);

    const sideBarFormik = useFormik({
        initialValues: saleItem,
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (values.quantity === null || values.quantity === undefined || values.quantity === "") {
                errors.quantity = "Введіть кількість";
            }

            return errors;
        },
        onSubmit: (values) => {
            setVisible(false);
            createNewSale(values);
        }
    });

    const changePriceFormik = useFormik({
        initialValues: {
            remaining_id: "",
            accounting_type: "",
            product_name: "",
            use_product_sale_price: "",
            custom_sale_price_currency: "",
            custom_sale_price: "",
        },
        enableReinitialize: true,
        validate: values => {
            const errors = {};
            return errors
        },
        onSubmit: (values) => {

            const changePrice = async () => {

                const response = await updateProductPrice(values);

                if (response.status !== 200) {
                    toast.current.show({ severity: "error", summary: "Помилка зміна ціни товару", detail: "Помилка сервера " + response.status, life: 3000 });
                }
                else {
                    toast.current.show({ severity: "success", summary: 'Зміна ціни', detail: 'Ціна товару успішно змінена', life: 3000 });
                }

                setChangePriceSiderBarVisible(false);
                setReoladData(!reloadData);
            }

            changePrice();
        }
    });

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const expandedKeysHandler = data => {
        console.log(data);
        setExpandedKeys(data);
    }

    const getProductIerarchy = () => {
        if (products === null) {
            return [];
        }

        console.log(products);

        let ierarchy = [];

        products.forEach(group => {
            let groupBlock = {
                key: group.id,
                label: group.group_name,
            }

            if (group.sub_groups.length > 0) {

                groupBlock.items = [];

                const groupId = group.id;

                group.sub_groups.forEach(subgroup => {

                    let subgroupBlock = {
                        key: groupId + "_" + subgroup.id,
                        label: subgroup.group_name,
                    }

                    if (subgroup.sub_groups.length > 0) {

                        const subgroupId = subgroup.id;

                        subgroupBlock.items = [];

                        subgroup.sub_groups.forEach(subGroup2 => {

                            let subGroupSecondBlock = {
                                key: groupId + "_" + subgroupId + "_" + subGroup2.id,
                                label: subGroup2.group_name,
                            }

                            if (subGroup2.sub_groups.length > 0) {

                                subGroupSecondBlock.items = [];

                                subGroup2.sub_groups.forEach(subGroup3 => {
                                    let subGroupThirdBlock = {
                                        key: subGroup3.id,
                                        label: subGroup3.group_name,
                                        command: () => {
                                            setFilterGroup(subGroup3.id);
                                        }
                                    }

                                    subGroupSecondBlock.items = [...subGroupSecondBlock.items, subGroupThirdBlock];
                                });
                            }
                            else {
                                subGroupSecondBlock.command = () => {
                                    setFilterGroup(subGroup2.id);
                                }
                            }

                            subgroupBlock.items = [...subgroupBlock.items, subGroupSecondBlock];
                        });
                    }
                    else {
                        subgroupBlock.command = () => {
                            setFilterGroup(subgroup.id);
                        }
                    }

                    groupBlock.items = [...groupBlock.items, subgroupBlock];
                })
            }
            else {
                groupBlock.command = () => {
                    setFilterGroup(group.id);
                }
            }

            ierarchy = [...ierarchy, groupBlock];
        });

        console.log(ierarchy);

        return ierarchy;
    }

    const changePriceButtonHandler = rowData => {
        changePriceFormik.setFieldValue('remaining_id', rowData.id);
        changePriceFormik.setFieldValue('product_name', rowData.product_name);
        changePriceFormik.setFieldValue('accounting_type', rowData.identifier === undefined ? "PIECE" : "INDIVIDUAL");
        changePriceFormik.setFieldValue('use_product_sale_price', rowData.use_product_sale_price);
        changePriceFormik.setFieldValue('custom_sale_price_currency', rowData.sale_price_currency);
        changePriceFormik.setFieldValue('custom_sale_price', rowData.sale_price);
        setChangePriceSiderBarVisible(true);
    }

    const printPriceTag = products => {

        const fetchData = async () => {

            let request = [];
            products.forEach(product => {
                const element = {
                    remaining_id: product.id,
                    accounting_type: product.identifier === undefined ? "PIECE" : "INDIVIDUAL"
                }
                request.push(element);
            });

            const response = await fetchRemainingQRCode(request);
            if(response.status === 200){
                console.log(response);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
        
                // Set a filename
                link.download = `QR_codes.pdf`; // Change based on your needs
                document.body.appendChild(link);
                link.click();
        
                // Clean up
                link.remove();
                window.URL.revokeObjectURL(url);

                setSelectedProducts(null);
            }
            else{
                toast.current.show({ severity: 'error', summary: 'Помилка завантаження', detail: 'Помилка завантадення цінника', life: 3000 });
            }
        }

        fetchData()
    }

    const ClearFilterHandler = () => {
        setFilterGroup(null);
        setExpandedKeys({});
    }

    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <Button icon="pi pi-refresh"
             rounded 
             className={commonStyle.editButton}
             tooltip="Cкинути фільтри"
             tooltipOptions={{ position: "top" }}
             onClick={() => ClearFilterHandler()}
            />
            {(selectedProducts && selectedProducts.length > 0) &&<Button
                className={commonStyle.warningTag}
                severity="warning"
                tooltipOptions={{ position: "top" }}
                onClick={() => printPriceTag(selectedProducts)}
            >
                Друкувати стікери
            </Button>}
        </div>
    );

    const UAHPriceTemplate = (rowData) => {
        return formatCurrency(convertSumWithLatestCurrencyRate(rowData.sale_price_currency, rowData.sale_price), "UAH");
    }

    const newSaleButtonHandler = rowData => {
        if (rowData.identifier !== undefined) {
            createNewSale(rowData);
        }
        else {
            rowData.isNew = true;
            rowData.quantity = 1;
            setSaleItem(rowData);
            setVisible(true);
        }
    }

    const createNewSale = rowData => {
        const route = '/sales/newsale/';
        navigate(route, { state: { item: rowData } });
    }

    const actionBodyTemplate = rowData => {
        return (<>
            <Button
                icon="pi pi-shopping-cart"
                className={`p-button-rounded p-button-success ${rowData.total_quantity !== rowData.booked ? commonStyle.addButton : commonStyle.closeButton}`}
                tooltip={rowData.total_quantity !== rowData.booked ? "Створити акт" : "Товари зарезервовані"}
                tooltipOptions={{ showOnDisabled: true, position: "top" }}
                disabled={rowData.total_quantity === rowData.booked}
                onClick={() => newSaleButtonHandler(rowData)}
            />
            <Button
                icon="pi pi-pencil"
                className={`p-button-rounded p-button-info ${rowData.total_quantity !== rowData.booked ? commonStyle.editButton : commonStyle.closeButton}`}
                tooltip={rowData.total_quantity !== rowData.booked ? "Редагувати ціну" : "Товари зарезервовані"}
                tooltipOptions={{ showOnDisabled: true, position: "top" }}
                disabled={rowData.total_quantity === rowData.booked}
                onClick={() => changePriceButtonHandler(rowData)}
            />
            <Button
                icon="pi pi-barcode"
                className={`p-button-rounded p-button-warning ${commonStyle.warningTag}`}
                tooltip="Друкувати цінники"
                tooltipOptions={{ showOnDisabled: true, position: "top" }}
                onClick={() => printPriceTag([rowData])}
            />  
        </>)
    }

    console.log(selectedProducts);

    return (<>
        <Toast ref={toast} />
        <div className="grid">
            <div className={`col-2 xlg:col-3 ${productCategoriesLoading ? "flex align-items-center justify-content-center" : ""}`} style={{ height: '100vh' }}>
                {productCategoriesLoading ? (
                    <ProgressSpinner />
                ) : (
                    <PanelMenu
                        model={getProductIerarchy()}
                        expandedKeys={expandedKeys}
                        onExpandedKeysChange={expandedKeysHandler}
                        className="w-full"
                    />
                )}
            </div>
            <div className="col-10 xlg:col-9">
                <DataTable value={leftovers} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage} header={header}
                    first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]}
                    selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                    emptyMessage="Товарів не знайдено" tableStyle={{ minWidth: '60rem' }} >
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}/>
                    <Column field="product_name" header="Товар" sortable />
                    <Column field="note" header="Примітка"/>
                    <Column field="division_name" header="Підрозділ" />
                    <Column field="identifier" header="Ідентифікатор" sortable />
                    <Column field="total_quantity" header="Кількість" sortable />
                    <Column field="booked" header="Зарезервовано" sortable />
                    <Column field="sale_price" header="Вартість продажу" body={(rowData) => formatCurrency(rowData.sale_price, rowData.sale_price_currency)}></Column>
                    <Column field="sale_price" header="Вартість продажу, грн" body={UAHPriceTemplate}></Column>
                    <Column field="action" style={{ width: '15%' }} body={actionBodyTemplate} />
                </DataTable>
            </div>
            <Sidebar visible={visible} position="right" onHide={() => setVisible(false)} className={commonStyle.shortSideBar}>
                <form onSubmit={sideBarFormik.handleSubmit}>
                    <div className={commonStyle.sideBarInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="quantity"
                                    value={sideBarFormik.values.quantity}
                                    onChange={(e) => { sideBarFormik.setFieldValue('quantity', e.target.value) }}
                                    className={sideBarFormik.errors.quantity && sideBarFormik.touched.quantity ? 'p-invalid' : ''}
                                />
                                <label>Кількість</label>
                            </FloatLabel>
                            {sideBarFormik.errors.quantity && sideBarFormik.touched.quantity && (<small className={commonStyle.errorSmall}>{sideBarFormik.errors.quantity}</small>)}
                        </div>
                    </div>
                    <div>
                        <Button label="Створити акт" severity="success" type="submit" className={`${commonStyle.sideBarButton} ${commonStyle.addButton}`} autoFocus />
                    </div>
                </form>
            </Sidebar>
            <Sidebar visible={changePriceSiderBarVisible} position="right" onHide={() => setChangePriceSiderBarVisible(false)} className={commonStyle.shortSideBar}>
                <form onSubmit={changePriceFormik.handleSubmit}>
                    <div className={commonStyle.sideBarInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="product_name"
                                    value={changePriceFormik.values.product_name}
                                    onChange={(e) => { changePriceFormik.setFieldValue('product_name', e.target.value) }}
                                    disabled
                                />
                                <label>Назва товару</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className={commonStyle.sideBarInput}>
                        <div className={`${commonStyle.checkboxStyle} p-inputgroup flex-1"`}>
                            <Checkbox
                                name="use_product_sale_price"
                                onChange={(e) => { changePriceFormik.setFieldValue('use_product_sale_price', !changePriceFormik.values.use_product_sale_price) }}
                                checked={changePriceFormik.values.use_product_sale_price}
                            />
                            <label>Використовувати ціну продажу з довідника</label>
                        </div>
                    </div>
                    {!changePriceFormik.values.use_product_sale_price && <>
                        <div className={commonStyle.sideBarInput}>
                            <div className="p-inputgroup flex-column">
                                <FloatLabel>
                                    <Dropdown value={changePriceFormik.values.custom_sale_price_currency}
                                        onChange={(e) => changePriceFormik.setFieldValue('custom_sale_price_currency', e.value)}
                                        options={currencies}
                                        showClear
                                        className={changePriceFormik.errors.custom_sale_price_currency && changePriceFormik.touched.custom_sale_price_currency ? 'p-invalid w-full' : "w-full"} />
                                    <label>Валюта продажу</label>
                                </FloatLabel>
                                {changePriceFormik.errors.custom_sale_price_currency && changePriceFormik.touched.custom_sale_price_currency && (<small className={commonStyle.errorSmall}>{changePriceFormik.errors.custom_sale_price_currency}</small>)}
                            </div>
                        </div>
                        <div className={commonStyle.sideBarInput}>
                            <div className="p-inputgroup flex-column">
                                <FloatLabel>
                                    <InputText
                                        name="custom_sale_price"
                                        value={changePriceFormik.values.custom_sale_price}
                                        onChange={(e) => { changePriceFormik.setFieldValue('custom_sale_price',e.target.value) }}
                                        className={changePriceFormik.errors.custom_sale_price && changePriceFormik.touched.custom_sale_price ? 'p-invalid' : ''}
                                    />
                                    <label>Ціна продажу в валюті розрахунку, за одиницю</label>
                                </FloatLabel>
                                {changePriceFormik.errors.custom_sale_price && changePriceFormik.touched.custom_sale_price && (<small className={commonStyle.errorSmall}>{changePriceFormik.errors.custom_sale_price}</small>)}
                            </div>
                        </div>
                    </>}
                    <div>
                        <Button label="Змінити ціну" severity="success" type="submit" className={`${commonStyle.sideBarButton} ${commonStyle.addButton}`} autoFocus />
                    </div>
                </form>
            </Sidebar>
        </div>
    </>);
}

export default LeftOvers;