import useApi from "../../../../Utils/BackendClient";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import ActStatusCircle from "../../../UIElements/StatusCircle";
import { formatDate } from "../../../../Utils/Utils";
import { SplitButton } from "primereact/splitbutton";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

import commonStyle from "../../CommonStyles.module.css";

const Writeoffs = () => {

    const { deleteWriteOffAct, fetchWriteOffActs, finalizeWriteOffAct } = useApi();

    const location = useLocation();
    const navigate = useNavigate();
    const toast = useRef(null);
    const buttonRef = useRef(null);
    const [acts, setActs] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [reloadData, setReloadData] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 932);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "created_at",
        sortOrder: 1
    });

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 932);
      };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    useEffect(() => {
        if (location.state !== null) {
            toast.current.show(location.state.toast);
        }
        location.state = null;
    }, [location.state, location]);

    useEffect(() => {
        const fetchData = async () => {

            const response = await fetchWriteOffActs(lazyState);

            if (response.status === 200) {

                setTotalRecords(response.data.count);
                setActs(response.data.results);
                console.log(response.data.results);

            }
            else {
                setActs([]);
                toast.current.show({ severity: "error", summary: "Помилка завантаження списку актів", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            setLoading(false);
        }

        fetchData();

    }, [reloadData, lazyState, fetchWriteOffActs]);

    const CreateNewWriteOffButtonHandler = () => {
        navigate("/writeoff/newwriteoff");
    }

    const DeleteButtonHandler = async (rowData) => {

        const response = await deleteWriteOffAct(rowData.id);

        if (response.status !== 204) {
            toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
        }
        else {
            toast.current.show({ severity: "success", summary: 'Видалення', detail: 'Акт успішно видалено', life: 3000 });
        }
        setReloadData(!reloadData);
    }

    const EditWriteOffActHandler = (rowData) => {
        const route = '/writeoff/editwriteoff/' + rowData.id;
        navigate(route, { state: { id: rowData.id } });
    }

    const FinalizeActHandler = async (rowData) => {

        const response = await finalizeWriteOffAct(rowData.id);

        if (response.status !== 200) {
            toast.current.show({ severity: "error", summary: "Помилка фіналізації", detail: "Помилка сервера " + response.status, life: 3000 });
        }
        else {
            toast.current.show({ severity: 'success', summary: 'Фіналізація', detail: 'Акт фіналізовано', life: 3000 });
        }

        setReloadData(!reloadData);
    }

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const confirmDelete = (rowData) => {
        confirmDialog({
            group: 'headless',
            header: 'Підтвердження',
            message: 'Підтвердіть видалення акту',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            defaultFocus: 'accept',
            accept: () => { DeleteButtonHandler(rowData) }
        });
    }

    const confirmFinalize = (event, rowData) => {
        confirmDialog({
            group: 'headless',
            header: 'Підтвердження',
            message: 'Після фіналізації акту, буде відсутня можливість його редагування',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            defaultFocus: 'accept',
            accept: () => { FinalizeActHandler(rowData) }
        });
    }

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Додати акт списання" severity="success" className={commonStyle.addButton} onClick={CreateNewWriteOffButtonHandler} />
        </div>)
    }

    const formatAdditionalButtons = (rowData) => {
        let buttons = [];
        if (rowData.status !== "FINALIZED") {
            buttons.push({
                label: 'Фіналізувати',
                icon: 'pi pi-lock',
                command: (event) => { confirmFinalize(event, rowData) }
            });
        }

        if (rowData.can_be_deleted) {
            buttons.push({
                label: 'Видалити',
                icon: 'pi pi-trash',
                command: () => { confirmDelete(rowData) }
            });
        }

        buttons = buttons.length > 0 ? buttons : null;
        return buttons
    };

    const tableHeader = (
        <div className="flex justify-content-between align-items-center w-full">
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        const buttons = formatAdditionalButtons(rowData);

        const buttonLabel = rowData.status !== "FINALIZED" ? "Редагувати" : "Деталі акту";
        const buttonIcon = rowData.status !== "FINALIZED" ? "pi pi-pencil" : "pi pi-eye";

        return (
            <>
                {buttons !== null ? (
                    <SplitButton
                        label={!isMobile ? buttonLabel : null}
                        icon={buttonIcon}
                        onClick={() => EditWriteOffActHandler(rowData)}
                        model={buttons}
                        text
                        ref={buttonRef} // Set the ref here
                    />
                ) : (
                    <Button
                        label={!isMobile ? buttonLabel : null}
                        icon={buttonIcon}
                        onClick={() => EditWriteOffActHandler(rowData)}
                        text
                    />
                )}
            </>
        );
    };

    const mobileActBody = rowData => {
        return (
            <div className="flex justify-content-between flex-wrap">
                <div className="grid w-8">
                    <div className="col-2 flex justify-content-center align-items-center">
                        <ActStatusCircle rowData={rowData} />
                    </div>
                    <div className="col-10">
                        <div className="flex-column">
                            <div>Акт №{rowData.act_number}</div>
                            <div><i className="pi pi-clock"/> {rowData.act_date}</div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-content-center align-items-center">
                    {actionBodyTemplate(rowData)}
                </div>
            </div>
        )
    }

    return (<>
        <Toolbar className="mb-4" end={toolbarRightTemplate}></Toolbar>
        <Toast ref={toast} />
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                {!isMobile && <DataTable value={acts} header={tableHeader} lazy rows={lazyState.rows} paginator onPage={onPage} onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                    first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]} emptyMessage="Актів не знайдено">
                    <Column field="act_number" header="Номер акту" />
                    <Column field="act_date" dataType="date" header="Дата акту" />
                    <Column field="status" header="Статус" body={rowData => <ActStatusCircle rowData={rowData} />} />
                    <Column field="created_by" header="Менеджер" sortable />
                    <Column field="created_at" header="Дата" body={(rowData) => formatDate(rowData.created_at)} sortable />
                    <Column field="action" body={actionBodyTemplate} />
                </DataTable>}
                {isMobile && <DataTable value={acts} header={tableHeader} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage} onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                    first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]} paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                     emptyMessage="Актів не знайдено" >
                    <Column field="act_number" headerStyle={{ display: 'none' }} body={mobileActBody} />
                </DataTable>} 
            </div>
            <ConfirmDialog group="headless" />
        </div>
    </>);
}

export default Writeoffs;