import EnumsContext from '../../../../context/enums-context';
import { buildGroupHierarchy, findGroupCode, formatCurrency, formatShortDate, getGroupCode, statusList } from '../../../../Utils/Utils';
import HistoryElement from '../../../UIElements/HistoryElement';
import ProductStatusCircle from '../../../UIElements/ProductStatusCircle';
import { UaLocale } from '../../../../Utils/TranslationUtils';
import useApi from '../../../../Utils/BackendClient';
import { useContext, useEffect, useRef, useState } from 'react';
import { UserPermissionContext } from '../../../../context/UserPermissionProvider';
import useGetEnums from '../../../../Utils/EnumsUtils';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';

import { addLocale, locale } from 'primereact/api';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { FloatLabel } from 'primereact/floatlabel';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressSpinner } from 'primereact/progressspinner';
import { RadioButton } from 'primereact/radiobutton';
import { Tag } from 'primereact/tag';
import { TabMenu } from 'primereact/tabmenu';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Tree } from 'primereact/tree';
import { TreeSelect } from 'primereact/treeselect';
import { Sidebar } from 'primereact/sidebar';

import "./AdditionalStyles.css";
import commonStyle from "../../CommonStyles.module.css";


const Products = () => {

    const fieldTypes = ["STRING", "INTEGER", "FLOAT", "DATE"];

    const navigate = useNavigate();
    const location = useLocation();
    const ctx = useContext(EnumsContext);
    const permCtx = useContext(UserPermissionContext);
    const { changeProductStatus, createProductGroup, deleteProduct, deleteProductGroup, fetchProducts, fetchProductsExtended, fetchProductGroupDetails, fetchProductGroupHistory, updateProductGroup } = useApi();
    const toast = useRef(null);

    const [loading, setLoading] = useState(true);
    const [productCategoriesLoading, setProductCategoriesLoading] = useState(true);
    const [products, setProducts] = useState();
    const [totalRecords, setTotalRecords] = useState(0);
    const [reloadData, setReloadData] = useState(true);
    const [onlyActive, setOnlyActive] = useState(false);
    const [sortedGroups, setSortedGroups] = useState([]);
    const [treeHierarchy, setTreeHierarchy] = useState([]);
    const [selectedField, setSelectedField] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [productGroupEdit, setProductGroupEdit] = useState(false);
    const [productGroupsLoaded, setProductGroupsLoaded] = useState(false);
    const [visible, setVisible] = useState(false);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [position, setPosition] = useState("top");
    const [reloadProductGroups, setReloadProductGroups] = useState(false);
    const [showProductGroupMenu, setShowProductGroupMenu] = useState(true);
    const [tabShowed, setTabShowed] = useState(0);
    const [productGroupHistory, setProductGroupHistory] = useState([]);
    const [historyTotalRecords, setHistoryTotalRecords] = useState(0);
    const [group, setGroup] = useState({
        group_name: '',
        root_group: null,
        accounting_type: 'PIECE',
        identifier_name: '',
        description: '',
        is_active: null,
        fields: [],
    });
    const [tableFilters, setTableFilters] = useState(location.state?.productPageState !== undefined ? location.state?.productPageState?.filters : {
        product_name: { value: "", matchMode: 'contains' },
        product_group: { value: null, matchMode: 'equals', name: '' },
        product_article: { value: "", matchMode: 'contains' },
        created_at: { value: null, matchMode: 'btw' },
        created_by: { value: null, matchMode: 'equals' }
    });
    const [lazyState, setlazyState] = useState(location.state?.productPageState !== undefined ? location.state?.productPageState : {
        first: 0,
        rows: 10,
        page: 1,
        sortField: "created_at",
        sortOrder: 1,
        filters: {
            product_name: { value: "", matchMode: 'contains' },
            product_group: { value: null, matchMode: 'equals' },
            product_article: { value: "", matchMode: 'contains' },
            created_at: { value: null, matchMode: 'btw' },
            created_by: { value: null, matchMode: 'equals' }
        }
    });
    const [historyLazyState, setHistoryLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1   
    });

    addLocale('ua', UaLocale);
    locale('ua');

    useGetEnums(["product_groups"]);

    useEffect(() => {
        if (location.state !== null) {
            toast.current.show(location.state.toast);
        }
    }, [location.state]);

    useEffect(() => {

        const fetchData = async () => {

            const response = await fetchProducts(onlyActive, lazyState);

            if (response.status !== 200) {
                toast.current.show({ severity: "error", summary: "Помилка завантаження списку", detail: "Помилка сервера " + response.status, life: 3000 });
                setTotalRecords(0);
                setProducts([]);
            }
            else {
                setTotalRecords(response.data.count);
                setProducts(response.data.results);
            }

            setLoading(false);
        }

        if(productGroupsLoaded){
            fetchData();
        }

    }, [lazyState, reloadData, fetchProducts, onlyActive, productGroupsLoaded]);

    useEffect(() => {

        if (permCtx.permissions?.includes("products.view_productgroups")) {
            const getProductGroups = async () => {
                const response = await fetchProductsExtended();

                if (response.status === 200) {

                    console.log(response.data);
                    const groups = buildGroupHierarchy(response.data);

                    const buildTreeHierarchy = (groups) => {
                        return groups.map(group => {
                            const groupBlock = {
                                key: group.id,
                                label: group.group_name,
                                droppable: false,
                                is_active: group.is_active,
                                children: group.sub_groups.length > 0 ? buildTreeHierarchy(group.sub_groups) : undefined,
                            };

                            return groupBlock;
                        });
                    };

                    const hierarchy = buildTreeHierarchy(response.data || []);

                    setTreeHierarchy(hierarchy);
                    setSortedGroups(groups);
                    setProductCategoriesLoading(false);
                }
                else {
                    setSortedGroups([]);
                }

                setProductGroupsLoaded(true);
            }

            getProductGroups();
        }
    }, [fetchProductsExtended, reloadProductGroups, permCtx.permissions]);

    const productGroupformik = useFormik({
        initialValues: group,
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (values.group_name === null || values.group_name === "") {
                errors.group_name = "Назва групи не повинна бути пустою";
            }

            if (values.accounting_type === 'INDIVIDUAL' && values.identifier_name === '') {
                errors.identifier_name = "Поле для ідентифікації не повинне бути пустим для індивідуального обліку";
            }

            return errors;
        },
        onSubmit: async (values) => {

            if (values.root_group !== undefined && values.root_group !== null) {
                if (values.root_group.toString().includes('-')) {
                    var keys = values.root_group.split('-');
                    values.root_group = keys[(keys.length - 1)];
                }
            }
            else {
                values.root_group = null;
            }

            if (values.is_active === "Активний") {
                values.is_active = true;
            }
            else {
                values.is_active = false;
            }

            const response = productGroupEdit ? await updateProductGroup(values.id, values) : await createProductGroup(values);
            console.log(response);

            const successStatus = productGroupEdit ? 200 : 201;

            let toastMessage = {
                severity: "error", summary: productGroupEdit ? "Помилка редагування" : "Помилка створення",
                detail: "Групу не " + (productGroupEdit ? "відредаговано. " : " створено. ") + "Помилка сервера " + response.status, life: 3000
            };

            if (response.status === successStatus) {

                toastMessage = productGroupEdit ? { severity: "success", summary: "Успіх редагування", detail: "Групу " + values.group_name + " успішно відредаговано", life: 3000 } :
                    { severity: "success", summary: "Успіх створення", detail: "Групу " + values.group_name + " успішно створено", life: 3000 }

                productGroupformik.resetForm();
                HideSideBarWithReload();
            }

            toast.current.show(toastMessage);
        }
    });

    const fieldsFormik = useFormik({
        initialValues: {
            field_name: '',
            field_type: null,
            mandatory: false
        },
        validate: values => {
            const errors = {};

            if (values.field_name === null || values.field_name === "") {
                errors.field_name = "Назва поля не повинна бути пустою";
            }

            if (values.field_type === null || values.field_type === '') {
                errors.field_type = "Виберіть тип поля";
            }

            console.log(errors);

            return errors;
        },
        onSubmit: values => {
            console.log(values);

            if (productGroupformik.values.fields.length === 0) {
                productGroupformik.setFieldValue('fields', [values]);
            }
            else {
                productGroupformik.setFieldValue('fields', [...productGroupformik.values.fields, values])
            }
            fieldsFormik.resetForm();
            setDialogVisible(false);
        }
    });

    const ChangeProductGroupStatusHandler = (rowData) => {

        const action = rowData.is_active ? "deactivate" : "activate";

        const callApi = async () => {

            const response = await changeProductStatus(rowData.id, action);

            if (response.status !== 200) {
                toast.current.show({ severity: "error", summary: "Помилка зміни статусу", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else {
                toast.current.show({ severity: 'success', summary: rowData.is_active ? 'Деактивація' : "Активація", detail: rowData.is_active ? 'Товар деактивовано' : "Товар активовано", life: 3000 });
            }

            setReloadData(!reloadData);
        }

        callApi();
    }

    const DeleteProductHandler = (productIds, isMultiple) => {

        const deleteData = async () => {

            const response = await deleteProduct({ ids: productIds });

            if (response.status !== 204) {
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else {
                toast.current.show({ severity: "success", summary: 'Видалення', detail: `Товар успішно видалено`, life: 3000 });

                if (isMultiple) { setSelectedProducts(null); }
            }

            setReloadData(!reloadData);
        }

        deleteData();
    }

    const EditProductHandler = rowData => {
        const route = '/products/editproduct/' + rowData.id;
        navigate(route, { state: { id: rowData.id, productPageState: lazyState } });
    }

    const getProductHistory = async (id, lazyState) => {
        
        const response = await fetchProductGroupHistory(id, lazyState);

        console.log(response);

        if(response.status === 200){
            setProductGroupHistory(response.data.results);
            setHistoryTotalRecords(response.data.count);
        }
        else{
            toast.current.show({ severity: "error", summary: "Помилка отримання історії", detail: "Помилка сервера " + response.status, life: 3000 });
        }
    }

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const onHistoryPage = (event) => {
        event.page = event.page + 1;
        getProductHistory(productGroupformik.values?.id, event);

        setHistoryLazyState(event);
    }

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const onFilter = (event) => {
        event.page = 1;
        setTableFilters(event.filters);
    }

    const OnlyActiveHandler = (event) => {
        setOnlyActive(event);
    }

    const show = (position) => {
        setPosition(position);
        setDialogVisible(true);
    };

    const items = [
        {
            label: 'Налаштуванна полів',
            icon: 'pi pi-book',
            command: () => {
                setTabShowed(0);
            },
        },
        {
            label: 'Опис',
            icon: 'pi pi-comment',
            command: () => {
                setTabShowed(1);
            },
        },
        ...(group.group_name !== ""
            ? [
                {
                    label: 'Історія',
                    icon: 'pi pi-history',
                    command: () => {
                        setTabShowed(2);
                        getProductHistory(productGroupformik.values?.id, historyLazyState);
                    },
                },
            ]
            : []),
    ];

    const confirmDelete = (event, rowData) => {
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: 'Підтвердження видалення товару',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            accept: () => { DeleteProductHandler([rowData.id], false) }
        });
    }

    const confirmGroupDelete = (event, rowData) => {
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: 'Підтвердження видалення групи товарів',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            accept: () => { handleDelete(rowData) }
        });
    }

    const changeStatus = (event, rowData) => {
        console.log("Here!");
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: rowData.is_active ? 'Підтвердження деактивації товару' : 'Підтвердження активації товару',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            accept: () => { ChangeProductGroupStatusHandler(rowData) }
        });
    }

    const images = require.context('../../../../images', true);

    const getPicture = pictureName => {
        const path = './' + pictureName;

        return images(path);
    }

    const CreateProductHandler = () => {
        navigate('/products/createproduct', { state: { productPageState: lazyState } });
    }

    const formatFilterChips = () => {
        const element = [];

        Object.keys(lazyState.filters).forEach((filterKey) => {
            const filter = lazyState.filters[filterKey];

            if (filter.value && filter.value !== "") {

                let translatedKey = filterKey;
                let changedValue = filter.value;
                let removeValue = null;


                if (filterKey === "product_name") {
                    translatedKey = "Назва товару";
                    removeValue = "";
                }
                else if (filterKey === "product_group") {
                    translatedKey = "Назва групи";
                    changedValue = filter.name;
                }
                else if (filterKey === "product_article") {
                    translatedKey = "Артикул";
                    removeValue = "";
                }
                else if (filterKey === "created_at") {
                    translatedKey = "Дата";
                    changedValue = `з ${formatShortDate(filter.value[0])} до ${formatShortDate(filter.value[1] ?? filter.value[0])}`;
                }

                element.push(
                    <Chip
                        key={filterKey}
                        label={`${translatedKey}: ${changedValue}`}
                        removable
                        onRemove={() => clearFilterFromChips(filterKey, removeValue)} // Optional: To handle removal
                    />
                );
            }
        });

        return <div className="flex flex-wrap gap-2">{element}</div>;
    }

    const filterApply = () => {
        setlazyState(prevState => ({
            ...prevState,
            filters: tableFilters
        }))
    }

    const clearFilter = (paramName, value) => {
        setlazyState(prevState => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                [paramName]: {
                    ...prevState.filters[paramName],
                    value: value
                }
            }
        }));
    }

    const clearFilterFromChips = (paramName, value) => {
        clearFilter(paramName, value);
        setTableFilters(prevState => ({
            ...prevState,
            [paramName]: {
                value: value
            }
        }));
    }

    const applyFilterFromMenu = (node) => {
        const filters = tableFilters;
        filters.product_group.value = node.key;
        filters.product_group.name = node.label;
        setTableFilters(filters);

        const state = lazyState;
        state.filters.product_group.value = node.key;
        state.filters.product_group.name = node.label;
        setlazyState(state);

        setReloadData(!reloadData);
    }

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            {selectedProducts?.length > 0 && <Button label="Видалити" severity="danger" disabled={selectedProducts?.some(obj => obj.can_be_deleted === false)}
                className={selectedProducts?.some(obj => obj.can_be_deleted === false) ? commonStyle.closeButton : commonStyle.deleteButton}
                onClick={() => DeleteProductHandler(selectedProducts.map(item => item.id), true)} />}
            <Button label="Додати товар" severity="success" className={commonStyle.addButton} onClick={CreateProductHandler} />
        </div>)
    }

    const tableHeader = (
        <div className="flex justify-content-between align-items-center w-full">
            {formatFilterChips()}
            <div className="flex justify-content-center align-items-center gap-2">
                <InputSwitch checked={onlyActive} onChange={(e) => OnlyActiveHandler(e.value)} />
                <span>Лише активні</span>
            </div>
        </div>
    );

    const imageBodyTemplate = (product) => {
        return <img src={getPicture("Iphone14ProMax.jpg")} alt={product.image} className="w-8rem shadow-2 border-round" />;
    };

    const rootGroupTemplate = (rowData) => {
        return ctx.product_groups[rowData.product_group];
    }

    const actionBodyTemplate = (rowData) => {
        return (<div className="flex flex-wrap gap-2">
            {permCtx.permissions?.includes("products.change_products") && <Button
                icon={rowData.is_active ? "pi pi-times" : "pi pi-check"}
                className={`p-button-rounded ${rowData.is_active ? commonStyle.warningTag + " p-button-warning" : commonStyle.addButton + " p-button-success"}`}
                tooltip={rowData.is_active ? "Деактивувати" : "Активувати"}
                tooltipOptions={{ position: "top" }}
                onClick={(event) => changeStatus(event, rowData)}
            />}
            {permCtx.permissions?.includes("products.delete_products") && <Button
                icon="pi pi-trash"
                className={`p-button-rounded p-button-danger ${rowData.can_be_deleted ? commonStyle.deleteButton : commonStyle.closeButton}`}
                tooltip={rowData.can_be_deleted ? "Видалити" : "Неможливо видалити"}
                tooltipOptions={{ showOnDisabled: true, position: "top" }}
                disabled={!rowData.can_be_deleted}
                size="small"
                onClick={(event) => confirmDelete(event, rowData)}
            />}
            {permCtx.permissions?.includes("products.change_products") && <Button
                icon="pi pi-pencil"
                className={`p-button-rounded p-button-info ${commonStyle.editButton}`}
                tooltip="Редагувати"
                tooltipOptions={{ position: "top" }}
                onClick={() => EditProductHandler(rowData)}
            />}
        </div>
        );
    };

    const inputTemplate = (options) => {
        return (
            <InputText
                value={options.value}
                onChange={
                    (e) => options.filterApplyCallback(e.target.value)
                }
            />
        );
    }

    const handleGroupAdding = () => {
        setVisible(true);
    }

    const handleEdit = (node) => {

        const getProductGroupsData = async () => {

            const response = await fetchProductGroupDetails(getGroupCode(node.key));

            if (response.status === 200) {

                setProductGroupEdit(true);

                response.data.is_active = response.data.is_active ? "Активний" : "Деактивований";

                response.data.root_group = findGroupCode(sortedGroups, response.data.root_group);

                setGroup(response.data);

                setVisible(true);
            }
        }

        getProductGroupsData();
    };

    const HideSideBar = () => {
        setGroup({
            group_name: '',
            root_group: null,
            accounting_type: 'PIECE',
            identifier_name: '',
            description: '',
            is_active: null,
            fields: [],
        });
        setTabShowed(0);
        setProductGroupEdit(false);
        setVisible(false);
    }

    const HideSideBarWithReload = () => {
        setProductCategoriesLoading(true);
        setReloadProductGroups(!reloadProductGroups);

        HideSideBar();
    }

    const handleDelete = (node) => {
        const deleteData = async () => {

            const request = {
                ids: [getGroupCode(node.key)]
            }

            const response = await deleteProductGroup(request);

            if (response.status !== 204) {
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else {
                setProductCategoriesLoading(true);
                setReloadProductGroups(!reloadProductGroups);
                toast.current.show({ severity: "success", summary: 'Видалення', detail: 'Групу товарів успішно видалено', life: 3000 });
            }

            setReloadData(!reloadData);
        }

        deleteData();
    };

    const DeleteFieldHandler = (fieldName) => {

        const updatedFields = [...productGroupformik.values.fields];

        const index = productGroupformik.values.fields.findIndex(function (field) {
            return field.field_name === fieldName
        });

        updatedFields.splice(index, 1);
        productGroupformik.setFieldValue('fields', updatedFields);
    };

    const nodeTemplate = (node, options) => {
        return (
            <div className="flex justify-content-between align-items-center w-full">
                <div className="flex col-7 items-center">
                    <ProductStatusCircle rowData={node} type="statusCircleSmall" />
                    <span className="ml-2">{node.label}</span> {/* Optional margin for spacing */}
                </div>
                <div className="col-5 flex justify-content-end align-items-center">
                    {permCtx.permissions?.includes("products.change_productgroups") && <Button
                        icon="pi pi-pencil"
                        severity="secondary"
                        size="small"
                        text
                        tooltip="Редагувати групу"
                        tooltipOptions={{ position: "top" }}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(node);
                        }}
                    />}
                    {permCtx.permissions?.includes("products.delete_productgroups") && <Button
                        icon="pi pi-trash"
                        severity="secondary"
                        text
                        tooltip="Видалити групу"
                        tooltipOptions={{ position: "top" }}
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            confirmGroupDelete(e, node)
                        }}
                    />}
                </div>
            </div>
        );
    };

    const footer = `Загальна кількість товарів: ${totalRecords != null ? totalRecords : 0}`;

    const mainToolbarLeftTemplate = () => {

        if (productGroupEdit) {
            return <h3>Редагування групи товарів "{productGroupformik.values.group_name}"</h3>;
        }
        else {
            return (<h3>Створення групи товарів</h3>);
        }
    }

    const mainToolbarRightTemplate = () => {
        if (permCtx.permissions?.includes("products.change_productgroups") || permCtx.permissions?.includes("products.add_productgroups")) {
            return (
                <div className="flex flex-wrap gap-2">
                    <Button label="Зберегти" severity="success" className={commonStyle.addButton} type="submit" />
                </div>
            );
        }
    }

    const rightToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap justify-content-end">
                <Button label="Додати" icon="pi pi-plus" type="button" className={commonStyle.addButton} onClick={() => show('top')} severity="success" />
            </div>
        );
    };

    const ObligatoryBodyTemplate = (product) => {

        if (product.mandatory) {
            return <Tag className={commonStyle.successTag} value='Так' severity="success" style={{ width: '20%' }} />;
        }

        return <Tag className={commonStyle.deletedTag} value="Ні" severity="danger" style={{ width: '20%' }}></Tag>;
    };

    const fieldsActionBodyTemplate = (rowData) => {
        return (<div className="flex flex-wrap gap-2">
            <Button
                icon="pi pi-trash"
                type="button"
                className={`p-button-rounded p-button-danger ${commonStyle.deleteButton}`}
                onClick={() => DeleteFieldHandler(rowData.field_name)}
            />
        </div>
        );
    };

    const productGroupMenuClass = () => {
        if(!permCtx.permissions?.includes("products.view_productgroups")){
            return "col-12 justify-content-end";
        }
        else if(!showProductGroupMenu){
            return "col-12 flex flex-row";
        }
        else{
            return "col-7 md:col-8 xl:col-9 justify-content-end";
        }
    }

    return (<>
        <Toast ref={toast} />
        {permCtx.permissions?.includes("products.add_products") && <Toolbar className="mb-4" end={toolbarRightTemplate} />}
        <div className='grid'>
            {(permCtx.permissions?.includes("products.view_productgroups") && showProductGroupMenu) && <div className={`${showProductGroupMenu ? "col-5 md:col-4 xl:col-3" : "col-1"} ${productCategoriesLoading ? "flex align-items-center justify-content-center" : ""}`} style={{ height: '100vh' }}>
                {productCategoriesLoading ? (
                    <ProgressSpinner />
                ) : (
                    <>
                        <div className='flex justify-content-between mr-4 ml-4'>
                            <Button icon="pi pi-step-backward-alt"
                                severity="secondary" 
                                outlined 
                                tooltip="Приховати групи товарів"
                                tooltipOptions={{ position: "top" }}
                                onClick={() => setShowProductGroupMenu(!showProductGroupMenu)}
                                className='mr-1'
                            />
                            {(permCtx.permissions?.includes("products.add_productgroups") && showProductGroupMenu) && <Button icon="pi pi-plus"
                                severity="success"
                                tooltip="Додати групу"
                                tooltipOptions={{ position: "top" }}
                                onClick={handleGroupAdding}
                                className={`${commonStyle.addButton}`}
                            />}
                        </div>
                        <Tree
                            value={treeHierarchy}
                            selectionMode="single"
                            dragdropScope="demo"
                            onDragDrop={(e) => setTreeHierarchy(e.value)}
                            onSelect={e => applyFilterFromMenu(e.node)}
                            className="w-full"
                            filter
                            filterMode="lenient"
                            filterPlaceholder="Пошук групи"
                            nodeTemplate={nodeTemplate}  // Use the nodeTemplate here
                        />
                    </>
                )}
            </div>}
            <div className={productGroupMenuClass()}>
                {!showProductGroupMenu && <Button icon={`pi pi-step-forward-alt`}
                    severity="secondary"
                    outlined
                    tooltip={`${showProductGroupMenu ? "Приховати групи товарів" : "Показати групи товарів"}`}
                    tooltipOptions={{ position: "top" }}
                    onClick={() => setShowProductGroupMenu(!showProductGroupMenu)}
                />}
                {permCtx.permissions?.includes("products.view_products") && <DataTable value={products} stripedRows dataKey="id" lazy header={tableHeader} loading={loading} selectionMode="checkbox" selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                    paginator onPage={onPage} first={lazyState.first} totalRecords={totalRecords} rows={lazyState.rows} rowsPerPageOptions={[10, 20, 50]}
                    onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                    emptyMessage="Товарів не знайдено" footer={footer} onFilter={onFilter} filters={tableFilters} style={{ width: '100%' }} tableStyle={{ minWidth: '60rem' }}>
                    <Column selectionMode="multiple" headerStyle={{ width: '3%' }} />
                    <Column field="logo" body={imageBodyTemplate} style={{ width: '5%' }} />
                    <Column field="product_name" header="Назва товару" sortable filter filterElement={inputTemplate} onFilterApplyClick={filterApply} onFilterClear={() => clearFilter("product_name", "")} showFilterMatchModes={false} style={{ width: '20%' }} />
                    <Column field="product_group" header="Назва групи" sortable body={rootGroupTemplate} style={{ width: '15%' }} />
                    <Column field="product_article" header="Артикул" sortable filter filterElement={inputTemplate} onFilterApplyClick={filterApply} onFilterClear={() => clearFilter("product_article", "")} showFilterMatchModes={false} style={{ width: '15%' }} />
                    <Column field="price" header="Ціна" sortable body={rowData => formatCurrency(rowData.price, rowData.currency)} style={{ width: '10%' }} />
                    <Column field="priceUAH" header="Ціна, UAH" sortable body={rowData => formatCurrency(rowData.priceUAH, "UAH")} style={{ width: '12%' }} />
                    <Column field="is_active" header="Статус" body={rowData => <ProductStatusCircle rowData={rowData} type="statusCircle" />} style={{ width: '5%' }} />
                    {(permCtx.permissions?.includes("products.delete_products") || permCtx.permissions?.includes("products.change_products")) && <Column field="action" body={actionBodyTemplate} style={{ width: '15%' }} />}
                </DataTable>}
            </div>
            <ConfirmPopup group="headless" />
            <Sidebar visible={visible} position="right" className={commonStyle.pageSideBar} onHide={() => HideSideBar()}>
                <form onSubmit={productGroupformik.handleSubmit}>
                    <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate}></Toolbar>
                    <div className="grid">
                        <div className="leftMenu col-3">
                            <div className={commonStyle.formInput}>
                                <div className="p-inputgroup flex-column">
                                    <FloatLabel>
                                        <InputText
                                            name="group_name"
                                            value={productGroupformik.values.group_name}
                                            onChange={(e) => { productGroupformik.setFieldValue('group_name', e.target.value) }}
                                            className={productGroupformik.errors.group_name && productGroupformik.touched.group_name ? 'p-invalid' : ''}
                                        />
                                        <label>Назва групи</label>
                                    </FloatLabel>
                                    {productGroupformik.errors.group_name && productGroupformik.touched.group_name && (<small className={commonStyle.errorSmall}>{productGroupformik.errors.group_name}</small>)}
                                </div>
                            </div>
                            <div className={commonStyle.formInput}>
                                <div className="p-inputgroup flex flex-column">
                                    <FloatLabel>
                                        <TreeSelect name="root_group" value={productGroupformik.values.root_group} onChange={(e) => productGroupformik.setFieldValue('root_group', e.value)} options={sortedGroups} showClear className="w-full" />
                                        <label>Батьківська група</label>
                                    </FloatLabel>
                                </div>
                            </div>
                            <div className={commonStyle.formInput}>
                                <div className="p-inputgroup flex-1">
                                    <FloatLabel>
                                        <Dropdown name="status" value={productGroupformik.values.is_active} onChange={(e) => productGroupformik.setFieldValue('is_active', e.value)} options={statusList} showClear className="w-full" />
                                        <label>Статус</label>
                                    </FloatLabel>
                                </div>
                            </div>
                            <div className="radioButtonBlock">
                                <div className="accountingRadio">
                                    <RadioButton value="INDIVIDUAL" checked={productGroupformik.values.accounting_type === "INDIVIDUAL"} onChange={(e) => { productGroupformik.setFieldValue('accounting_type', "INDIVIDUAL"); }} />
                                    <label className="ml-2">Індивідуальний облік</label>
                                </div>
                                <div className="accountingRadio">
                                    <RadioButton value="PIECE" checked={productGroupformik.values.accounting_type === "PIECE"} onChange={(e) => { productGroupformik.setFieldValue('accounting_type', "PIECE"); }} />
                                    <label className="ml-2">Поштучний облік</label>
                                </div>
                            </div>
                            {productGroupformik.values.accounting_type === "INDIVIDUAL" && (<div>
                                <div className={commonStyle.formInput}>
                                    <div className="p-inputgroup flex flex-column gap-1">
                                        <FloatLabel>
                                            <InputText
                                                name="idField"
                                                value={productGroupformik.values.identifier_name}
                                                onChange={(e) => { productGroupformik.setFieldValue('identifier_name', e.target.value) }}
                                                className={productGroupformik.errors.identifier_name && productGroupformik.touched.identifier_name ? 'p-invalid' : ''}
                                            />
                                            <label >Поле для ідентифікації</label>
                                        </FloatLabel>
                                        {productGroupformik.errors.identifier_name && productGroupformik.touched.identifier_name && (<small className={commonStyle.errorSmall}>{productGroupformik.errors.identifier_name}</small>)}
                                    </div>
                                </div>
                            </div>)}
                        </div>
                        <div className="col-9">
                            <TabMenu model={items} />
                            {tabShowed === 0 && <DataTable className="tableStyle" header={rightToolbarTemplate} value={productGroupformik.values.fields} selection={selectedField} onSelectionChange={(e) => setSelectedField(e.value)} rows={10} tableStyle={{ minWidth: '60rem' }} >
                                <Column field="field_name" header="Назва поля" sortable></Column>
                                <Column field="field_type" header="Тип поля" sortable></Column>
                                <Column field="mandatory" dataType="bool" align="center" body={ObligatoryBodyTemplate} header="Обов'язковість" ></Column>
                                <Column field="action" body={fieldsActionBodyTemplate} />
                            </DataTable>}
                            {tabShowed === 1 && <div className="tableStyle"><InputTextarea value={productGroupformik.values.description} onChange={(e) => { productGroupformik.setFieldValue('description', e.target.value) }} rows={10} className={commonStyle.fullWidth}></InputTextarea></div>}
                            {tabShowed === 2 && <HistoryElement data={productGroupHistory} lazyState={historyLazyState} totalRecords={historyTotalRecords} onHistoryPage={onHistoryPage} isAct={false}/>}
                        </div>
                    </div>
                    <Dialog header="Додати поле до послуги"
                        visible={dialogVisible}
                        position={position}
                        style={{ width: '30vw', display: 'flex', flexDirection: 'column' }}
                        onHide={() => setDialogVisible(false)}
                        draggable={false}
                        resizable={false}
                    >
                        <form onSubmit={fieldsFormik.handleSubmit} style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                            <div className="dialogFormInput">
                                <div className="p-inputgroup flex flex-column gap-1 md:w-22rem">
                                    <FloatLabel>
                                        <InputText
                                            name="field_name"
                                            value={fieldsFormik.values.field_name}
                                            onChange={(e) => { fieldsFormik.setFieldValue('field_name', e.target.value) }}
                                            className={fieldsFormik.errors.field_name && fieldsFormik.touched.field_name ? 'p-invalid' : ''}
                                        />
                                        <label>Назва поля</label>
                                    </FloatLabel>
                                    {fieldsFormik.errors.field_name && fieldsFormik.touched.field_name && (<small className={commonStyle.errorSmall}>{fieldsFormik.errors.field_name}</small>)}
                                </div>
                            </div>
                            <div className="dialogFormInput">
                                <div className="p-inputgroup flex flex-column gap-1 md:w-22rem">
                                    <FloatLabel>
                                        <Dropdown
                                            name="field_type"
                                            value={fieldsFormik.values.field_type}
                                            onChange={(e) => fieldsFormik.setFieldValue('field_type', e.value)}
                                            options={fieldTypes}
                                            showClear
                                            className={fieldsFormik.errors.field_type && fieldsFormik.touched.field_type ? 'p-invalid w-full' : 'w-full'}
                                        />
                                        <label>Тип поля</label>
                                    </FloatLabel>
                                    {fieldsFormik.errors.field_type && fieldsFormik.touched.field_type && (<small className={commonStyle.errorSmall}>{fieldsFormik.errors.field_type}</small>)}
                                </div>
                            </div>
                            <div className="dialogFormInput">
                                <div className="checkboxStyle p-inputgroup flex-1 md:w-22rem">
                                    <Checkbox
                                        name="mandatory"
                                        onChange={(e) => { fieldsFormik.setFieldValue('mandatory', !fieldsFormik.values.mandatory) }}
                                        checked={fieldsFormik.values.mandatory}
                                    />
                                    <label>Обовязковість</label>
                                </div>
                            </div>
                            <div className="dialogSubmit">
                                <Button label="Додати поле" icon="pi pi-check" type="submit" className={commonStyle.addButton} severity="success" autoFocus />
                            </div>
                        </form>
                    </Dialog>
                </form>
            </Sidebar>
        </div>
    </>)

};

export default Products;