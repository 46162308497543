import EnumsContext from "../../../../context/enums-context";
import { convertSumWithLatestCurrencyRate, formatCurrency, systemCurrencies } from "../../../../Utils/Utils";
import { transactionTypeTranslation, TranslatePaymentMethod, UaLocale } from "../../../../Utils/TranslationUtils";
import { useContext, useEffect, useRef, useState } from "react";
import useApi from "../../../../Utils/BackendClient";
import useGetEnums from "../../../../Utils/EnumsUtils";

import { addLocale, locale } from "primereact/api";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Sidebar } from "primereact/sidebar";
import { Toast } from "primereact/toast";

import commonStyle from "../../CommonStyles.module.css";
import { SplitButton } from "primereact/splitbutton";
import { useFormik } from "formik";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";

const Transactions = () => {

    const toast = useRef();
    const ctx = useContext(EnumsContext);
    const { deletePaymentFromTransaction, distributePaymentOnTransaction, fetchPayments, fetchTransactionPayments, fetchTransactions } = useApi();

    const [transactions, setTransactions] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [payments, setPayments] = useState([]);
    const [reloadData, setReloadData] = useState(true);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [availablePaymentsSidebarVisible, setAvailablePaymentsSidebarVisible] = useState(false);
    const [amountToBindSideBarVisible, setAmountToBindSideBarVisible] = useState(false);
    const [paymentsToBind, setPaymentsToBind] = useState(null);
    const [paymentsToBindTotalRecords, setPaymentsToBindTotalRecords] = useState(0);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);
    const [tableFilters, setTableFilters] = useState({
        type: { value: null, matchMode: 'equals' },
        pay_status: { value: null, matchMode: 'equals'}
    });
    const [paymentstoBindState, setPaymentToBindState] = useState({
        first: 0,
        rows: 20,
        page: 1,
        filters: {
            cagent: { value: null, matchMode: 'equals' },
            status: { value: ["NOT_DISTRIBUTED", "PARTLY_DISTRIBUTED"], matchMode: 'equals' }
        }
    });
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "id",
        sortOrder: 1,
        filters:{
            type: { value: null, matchMode: 'equals' },
            pay_status: { value: null, matchMode: 'equals'}
        }
    });

    addLocale('ua', UaLocale);
    locale('ua');
    useGetEnums(["pay_statuses"]);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
           
            const response = await fetchTransactions(lazyState);

            if (response.status === 200) {
                setTotalRecords(response.data.count);
                setTransactions(response.data.results);
                console.log(response.data.results);
            }
            else{
                setTotalRecords(0);
                setTransactions([]);

                toast.current.show({ severity: "error", summary: "Помилка завантаження списку клієнтів", detail: "Помилка сервера " + response.status, life: 3000 });
            }

            setLoading(false);
        }

        fetchData();

    }, [reloadData, lazyState, fetchTransactions]);

    const formik = useFormik({
        initialValues: {
            currency: "UAH",
            transaction: null,
            payment: null,
            distribution_sum: "",
        },
        validate: values => {
            const errors = {};
            let maxSum = 0;

            if(values.transaction && values.payment)
            {
                maxSum = Math.min(convertSumWithLatestCurrencyRate(values.transaction?.currency, values.transaction.unpaid_balance), (convertSumWithLatestCurrencyRate(values.payment.currency, values.payment.undistributed_sum)));
            }

            console.log(maxSum);

            if(convertSumWithLatestCurrencyRate(values.currency, +values.distribution_sum) > maxSum){
                errors.higherAmount = `Сума привязки не повинна перевищувати ${maxSum}UAH`;
            }
            return errors;
        },
        onSubmit: async (values) => {

            const request = {
                transaction_id: values.transaction.id,
                payment_id: values.payment.id,
                distribution_sum: values.distribution_sum
            }

            const response = await distributePaymentOnTransaction(request);

            if(response.status === 200)
            {
                toast.current.show({ severity: "success", summary: "Платіж привязано", detail: "Платіж привязано від транзакції", life: 3000 });
            }
            else{
                toast.current.show({ severity: "error", summary: "Помилка привязки платежу", detail: "Платіж не привязано до транзакції. Код помилки: " + response.status, life: 3000 });
            }

            setAmountToBindSideBarVisible(false);
            formik.resetForm();
            setAvailablePaymentsSidebarVisible(false);
            setReloadData(!reloadData);
        }
    })

    const getTransactionPayments = (rowData) => {

        const fetchData = async () => {

            const response = await fetchTransactionPayments(rowData.id);

            setSelectedTransaction(rowData);

            if (response.status !== 200){
                toast.current.show({ severity: "error", summary: "Помилка завантаження списку", detail: "Помилка сервера " + response.status, life: 3000 });
                setPayments([]);
            }
            else
            {
                setPayments(response.data.results);
            }
        }

        fetchData();
        setVisible(true);
    }

    const UnbindPayment = async (paymentId) => {

        const request = {
            transaction_id: selectedTransaction.id,
            payment_id: paymentId
        }

        const response = await deletePaymentFromTransaction(request);

        console.log(response);
        if(response.status === 204){
            
            const index = payments.findIndex(payment => payment.payment_id === paymentId);
            if (index !== -1) {
                // Create a new array with the element removed
                const newPayments = [...payments.slice(0, index), ...payments.slice(index + 1)];

                setReloadData(!reloadData);
                
                // Set the updated state
                setPayments(newPayments);
            }

            toast.current.show({ severity: "success", summary: "Платіж відвязано", detail: "Платіж відвязано від акту", life: 3000 });

        }
        else{
            toast.current.show({ severity: "error", summary: "Помилка відвязки платежу", detail: "Платіж не відвязано від акту. Код помилки: " + response.status, life: 3000 });
        }
    }

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const onPaymentBindPage = (event) => {
        const newState = paymentstoBindState;
        newState.page = event.page + 1;
        newState.first = event.first;
        fetchAvailableToBindPayments(newState.filters.cagent.value, newState);
        setPaymentToBindState(newState);
    };

    const onFilter = (event) => {
        event.page = 1;
        setTableFilters(event.filters);
    }

    const filterApply = () => {
        const editedLazyState = lazyState;
        editedLazyState.filters = tableFilters;
        setlazyState(editedLazyState);
        setReloadData(!reloadData)
    }

    const clearFilter = (paramName, value) => {
        setlazyState(prevState => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                [paramName]: {
                    ...prevState.filters[paramName],
                    value: value
                }
            }
        }));
    }

    const clearFilterFromChips = (paramName, value) => {
        clearFilter(paramName, value);

        setTableFilters(prevState => ({
            ...prevState,
            [paramName]: {
                value: value
            }
        }));
    }

    const formatFilterChips = () => {
        const element = [];

        Object.keys(lazyState.filters).forEach((filterKey) => {
            const filter = lazyState.filters[filterKey];

            if (filter.value && filter.value !== "") {

                let translatedKey = filterKey;
                let changedValue = filter.value;
                let removeValue = null;

                if (filterKey === "type") {
                    translatedKey = "Тип";
                    changedValue = transactionTypeTranslation[changedValue];
                }
                else if (filterKey === "pay_status") {
                    translatedKey = "Статус оплати";
                    changedValue = ctx.pay_statuses[changedValue];
                }

                element.push(
                    <Chip
                        key={filterKey}
                        label={`${translatedKey}: ${changedValue}`}
                        removable
                        onRemove={() => clearFilterFromChips(filterKey, removeValue)} // Optional: To handle removal
                    />
                );
            }
        });

        return <div className="flex flex-wrap gap-2">{element}</div>;
    }
    
    const tableHeader = (
        <div className="flex justify-content-between align-items-center w-full">
            {formatFilterChips()}
        </div>
    );

    const closeSideBarHandler = () => {
        setVisible(false);
    }

    const fetchAvailableToBindPayments = async (id, state) => {

        const newState = state;
        newState.filters.cagent.value = id;
        setPaymentToBindState(newState);

        const response = await fetchPayments(newState);

        if(response.status === 200){
            setPaymentsToBind(response.data.results);
            setPaymentsToBindTotalRecords(response.data.count);
        }
        else{
            
        }

        setAvailablePaymentsSidebarVisible(true);
    }

    const GetAvailableToBindPayments = (rowData) => {
        setSelectedTransaction(rowData);
        fetchAvailableToBindPayments(rowData.cagent_id, paymentstoBindState);
    }

    const BindPaymentToTransaction = (paymentData) => {
        setAmountToBindSideBarVisible(true);

        formik.setFieldValue("currency", paymentData.currency);
        formik.setFieldValue("payment", paymentData);
        formik.setFieldValue("transaction", selectedTransaction);
    }

    const formatAdditionalButtons = (rowData) => {
        let buttons = [];
        if(rowData.pay_status !== "PAYED"){
            buttons.push({
                label: 'Привязати платіж',
                icon: 'pi pi-dollar',
                command: () => { GetAvailableToBindPayments(rowData) }
            });
        }

        buttons = buttons.length > 0 ? buttons : null;
        return buttons
    };

    const actionBodyTemplate = (rowData) => {
        const buttons = formatAdditionalButtons(rowData);

        return (
            <>
                {buttons !== null ? (
                    <SplitButton
                        label="Платежі"
                        icon="pi pi-eye"
                        onClick={() => getTransactionPayments(rowData)}
                        model={buttons}
                        text
                    />
                ) : (
                    <Button
                        label="Платежі"
                        icon="pi pi-eye"
                        onClick={() => getTransactionPayments(rowData)}
                        text
                    />
                )}
            </>
        );
    };

    const paymentActionBodyTemplate = (rowData) => {
        return (<Button
            icon="pi pi-times"
            type="button"
            className={`p-button-rounded p-button-danger ${commonStyle.deleteButton}`}
            tooltip="Відв'язати платіж"
            tooltipOptions={{ position: "top" }}
            onClick={() => UnbindPayment(rowData.payment_id)}
        />
        );
    }

    const dropDownTemplate = (options, values) => {
        const dropdownOptions = Object.entries(values).map(([key, value]) => ({
            value: key,
            label: value,
        }));
    
        return (
            <Dropdown
                value={options.value}
                onChange={(e) => options.filterApplyCallback(e.value)}
                options={dropdownOptions}
                placeholder="Виберіть значення"
                className="p-column-filter"
            />
        );
    };

    const paymentBindBodyTemplate = (rowData) => {
        return (<Button
            icon="pi pi-lock"
            type="button"
            className={`p-button-rounded p-button-success ${commonStyle.addButton}`}
            tooltip="Привязати платіж"
            tooltipOptions={{ position: "top" }}
            onClick={() => BindPaymentToTransaction(rowData)}
        />
        );
    }

    console.log(formik.values);

    return (<>
        <Toast ref={toast} />
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                <DataTable value={transactions} header={tableHeader} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage}
                    first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]} 
                    dataKey="id" onFilter={onFilter} filters={lazyState.filters} tableStyle={{ minWidth: '60rem' }} >
                    <Column field="act_number" header="Номер акту" />
                    <Column field="type" header="Тип" sortable filter filterElement={(options) => dropDownTemplate(options, transactionTypeTranslation)} onFilterApplyClick={filterApply}
                        onFilterClear={() => clearFilter("type", null)} showFilterMatchModes={false} body={rowData => transactionTypeTranslation[rowData.type]} style={{ width: '10%' }} />
                    <Column field="sum" header="Сума"
                    body={(rowData) => {
                        const color = rowData.type === "DEBIT" ? "#03C04A" : "#EF4444";
                        return (
                            <span style={{ color }}>
                            {formatCurrency(rowData.sum, rowData.currency)}
                            </span>
                        );}} />
                    <Column field="sumUAH" header="Сума, грн" 
                     body={(rowData) => {
                        const color = rowData.type === "DEBIT" ? "#03C04A" : "#EF4444";
                        return (
                            <span style={{ color }}>
                            {formatCurrency(rowData.sumUAH, "UAH")}
                            </span>
                        );}}/>
                    <Column field="cagent_name" header="Контрагент" />
                    <Column field="pay_status" header="Статус оплати" filter filterElement={(options) => dropDownTemplate(options, ctx.pay_statuses)} onFilterApplyClick={filterApply}
                        onFilterClear={() => clearFilter("pay_status", null)} showFilterMatchModes={false} body={rowData => ctx.pay_statuses[rowData.pay_status]} style={{ width: '15%' }}/>
                    <Column field="unpaid_balance" header="Неоплачений баланс" body={(rowData) => formatCurrency(rowData.unpaid_balance, rowData.currency)} />
                    <Column field="pay_due_date" header="Очікувана дата оплати"/>
                    <Column field="action" body={actionBodyTemplate} />
                </DataTable>
            </div>
        </div>
        <Sidebar visible={visible} position="right" onHide={() => closeSideBarHandler()} className={commonStyle.sidebarTable}>
            <h2>Список платежів</h2>
            <DataTable value={payments} stripedRows>                
                <Column field="payment_id" header="ID платежу"/>
                <Column field="distribution_sum" header="Розподілена сума" body={(rowData) => formatCurrency(rowData.distribution_sum, rowData.currency)} />
                <Column field="distribution_sum_UAH" header="Розподілена сума, грн" body={(rowData) => formatCurrency(rowData.distribution_sum_UAH, "UAH")} />
                <Column field="cagent_name" header="Контрагент" />
                <Column field="action" body={paymentActionBodyTemplate} style={{ width: '10%' }}/>
            </DataTable>
        </Sidebar>
        <Sidebar visible={availablePaymentsSidebarVisible} position="right" onHide={() => setAvailablePaymentsSidebarVisible(false)} className={`${commonStyle.shortSideBar} ${amountToBindSideBarVisible && isLargeScreen ? commonStyle.moveLeft : ''}`}>
            <DataTable value={paymentsToBind} stripedRows lazy paginator onPage={onPaymentBindPage} first={paymentstoBindState.first} rows={paymentstoBindState.rows} totalRecords={paymentsToBindTotalRecords} rowsPerPageOptions={[20, 50]}
                emptyMessage="Платежів по даному контрагенту не знайдено">
                <Column field="date" header="Дата" type="Дата"/>
                <Column field="cagent_name" header="Контрагент" />
                <Column field="method" header="Метод" body={rowData => TranslatePaymentMethod(rowData.method)} />
                <Column field="undistributed_sum" header="Нерозподілена сума" body={(rowData) => formatCurrency(rowData.undistributed_sum, rowData.currency)} />
                <Column field="action" body={paymentBindBodyTemplate} />
            </DataTable>
        </Sidebar>
        <Sidebar visible={amountToBindSideBarVisible} position="right" className={`${commonStyle.sidebarmoved} ${commonStyle.shortSideBar} ${!isLargeScreen && 'sidebar-mobile'}`} onHide={() => setAmountToBindSideBarVisible(false)}>
            <form onSubmit={formik.handleSubmit}>
                <div className={commonStyle.sideBarInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <InputText
                                name="transaction_id"
                                value={formik.values.transaction?.id}
                                disabled
                            />
                            <label>ID транзакції</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className={commonStyle.sideBarInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <InputText
                                name="payment_id"
                                value={formik.values.payment?.id}
                                disabled
                            />
                            <label>ID платежу</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className={`${commonStyle.sideBarInput}`}>
                    <div className="p-inputgroup py-0 flex-1">
                        <span className="currencySpan p-inputgroup-addon">
                            <Dropdown
                                className="currencyDropDown"
                                value={formik.values.currency}
                                disabled
                                options={systemCurrencies}
                            />
                        </span>
                        <FloatLabel>
                            <InputNumber 
                                name="distribution_sum"
                                value={formik.values.distribution_sum}
                                onChange={(e) => { formik.setFieldValue('distribution_sum', e.value) }}
                                maxFractionDigits={2}
                                className={formik.errors.higherAmount && formik.touched.distribution_sum ? 'p-invalid w-full' : "w-full"}
                            />
                            <label>Сума, що потрібно прив'язати</label>
                        </FloatLabel>
                    </div>
                    {formik.errors.higherAmount && formik.touched.distribution_sum && (<small className={`mt-1 ${commonStyle.errorSmall}`}>{formik.errors.higherAmount}</small>)}
                </div>
                <div>
                    <Button label="Прив'язати" severity="success" type="submit" className={`${commonStyle.sideBarButton} ${commonStyle.addButton}`} autoFocus />
                </div>
            </form>
        </Sidebar>
    </>)
}

export default Transactions;