import { findKeyByValue, formatShortDate } from "../../../../Utils/Utils";
import useApi from "../../../../Utils/BackendClient";
import { useState, useContext, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import EnumsContext from "../../../../context/enums-context";
import HistoryElement from "../../../UIElements/HistoryElement";
import useGetEnums from "../../../../Utils/EnumsUtils";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { TabMenu } from "primereact/tabmenu";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";

import commonStyle from '../../CommonStyles.module.css';

const NewCounterAgent = () => {

    const countries = ["Україна"];
    const ctx = useContext(EnumsContext);
    const location = useLocation();
    const navigate = useNavigate();
    const toast = useRef(null);

    const { createCagent, fetchCagentDetails, fetchCagentHistory, updateCagent } = useApi();

    const [cagentHistory, setCagentHistory] = useState(null);
    const [historyTotalRecords, setHistoryTotalRecords] = useState(0);
    const [tabShowed, setTabShowed] = useState(0);
    const [historyLazyState, setHistoryLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1   
    });
    const [cagentData, setCagentData] = useState({
        type: "INDIVIDUAL",
        division: null,
        phone: "",
        email: "",
        is_partner: !location.state.isClient,
        is_client: location.state.isClient,
        country: "Україна",
        region: null,
        city: "",
        street: "",
        building_number: "",
        apartment_number: "",
        notes: "",
        individual_cagent_data: {
            first_name: "",
            last_name: "",
            surname: "",
            date_of_birth: null
        },
        legal_cagent_data: {
            long_name: "",
            short_name: "",
            representative: "",
            edrpou: ""
        },
        cagent_financial_data: []
    });

    useGetEnums(["divisions", "regions"]);

    const items = [
        {
            label: 'Адреса',
            icon: 'pi pi-home',
            command: () => {
                setTabShowed(0);
            },
        },
        {
            label: 'Фінансові дані',
            icon: 'pi pi-money-bill',
            command: () => {
                setTabShowed(1);
            },
        },
        ...(location.state.id !== undefined
            ? [
                {
                    label: 'Історія',
                    icon: 'pi pi-history',
                    command: () => {
                        setTabShowed(2);
                        getCagentHistory(location.state.id, historyLazyState);
                    },
                },
            ]
            : []),
    ];

    useEffect(() => {
        if (location.state.id !== undefined) {

            const fetchData = async () => {

                const response = await fetchCagentDetails(location.state.id);

                if (response.status === 200) {

                    response.data.division = ctx.divisions[response.data.division];
                    response.data.country = "Україна";
                    response.data.region = ctx.regions[response.data.region];

                    if (response.data.individual_cagent_data != null) {
                        response.data.individual_cagent_data.date_of_birth = new Date(response.data.individual_cagent_data.date_of_birth);
                    }

                    setCagentData(response.data);
                }
            }

            fetchData();
        }
    }, [location.state, ctx.divisions, ctx.regions, fetchCagentDetails]);

    const getCagentHistory = async (id, state) => {
        
        const response = await fetchCagentHistory(id, state);

        console.log(response);

        if(response.status === 200){
            setCagentHistory(response.data.results);
            setHistoryTotalRecords(response.data.count);
        }
        else{
            toast.current.show({ severity: "error", summary: "Помилка отримання історії", detail: "Помилка сервера " + response.status, life: 3000 });
        }
    }

    const onHistoryPage = (event) => {
        event.page = event.page + 1;
        getCagentHistory(location.state.id, event);

        setHistoryLazyState(event);
    }

    const formik = useFormik({
        initialValues: cagentData,
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (values.type === "INDIVIDUAL") {

                if (values.individual_cagent_data.last_name === null || values.individual_cagent_data.last_name === "") {
                    errors.indLastName = "Прізвище контрагента не повинне бути пустим";
                }

                if (values.individual_cagent_data.first_name === null || values.individual_cagent_data.first_name === "") {
                    errors.indFirstName = "Імя контрагента не повинне бути пустим";
                }

            }
            else {

                if (values.legal_cagent_data.long_name === null || values.legal_cagent_data.long_name === "") {
                    errors.legalLongName = "Повна назва організації не повинна бути пустою";
                }

                if (values.legal_cagent_data.short_name === null || values.legal_cagent_data.short_name === "") {
                    errors.legalShortName = "Коротка назва організації не повинна бути пустою";
                }
                if (values.legal_cagent_data.edrpou === null || values.legal_cagent_data.edrpou === "") {
                    errors.legalEdrpou = "ЄДРПОУ не повинен бути пустим";
                }
                else if (isNaN(Number(values.legal_cagent_data.edrpou))) {
                    errors.egalEdrpou = 'ЄДРПОУ повинен бути числом';
                }
            }
            
            if (values.division === null) {
                errors.division = "Підрозділ не повинен бути пустим"
            }

            if (values.country === null || values.country === undefined) {
                errors.country = "Виберіть будь ласка країну"
            }

            if (values.region === null || values.region === undefined) {
                errors.region = "Виберіть будь ласка область"
            }

            if (values.city === null || values.city === "") {
                errors.city = "Населений пункт не повинен бути пустим"
            }

            if (!values.is_partner && !values.is_client) {
                errors.WrongContrAgentType = "Контрагент повинен бути клієнтом або постачальником";
            }

            values.cagent_financial_data.forEach((item, index) => {
                if (!item.data_type) {
                  if (!errors.cagent_financial_data) {
                    errors.cagent_financial_data = [];
                  }
                  errors.cagent_financial_data[index] = { data_type: "Data type is required" };
                }
                // Add other validations as needed
              });

            return errors;
        },
        onSubmit: async (values) => {

            values.division = findKeyByValue(ctx.divisions, values.division);
            values.country = "UKRAINE";

            values.region = findKeyByValue(ctx.regions, values.region);

            if (values.individual_cagent_data !== undefined && values.individual_cagent_data !== null) {
                values.individual_cagent_data.date_of_birth = formatShortDate(values.individual_cagent_data.date_of_birth);
            }

            const { type, individual_cagent_data, legal_cagent_data, ...rest } = values;

            values = {
                type,
                ...rest,
                ...(type === "INDIVIDUAL" ? { individual_cagent_data } : { legal_cagent_data })
            };

            console.log(values);

            const isEdit = location.state.id !== undefined;
            const response = isEdit ? await updateCagent(location.state.id, values) : await createCagent(values);

            let toastMessage = {
                severity: "error", summary: isEdit ? "Помилка редагування" : "Помилка створення",
                detail: "Контрагента не " + (isEdit ? "відредаговано. " : " створено. ") + " Код помилки: " + response.status, life: 3000
            };

            if (isEdit && response.status === 200) {
                toastMessage = { severity: "success", summary: "Успіх редагування", detail: "Контрагент успішно відредагований", life: 3000 }
            }
            else if (!isEdit && response.status === 201) {
                toastMessage = { severity: "success", summary: "Успіх створення", detail: "Контрагент успішно створений", life: 3000 }
            }
            
            const endpoint = location.state.isClient ? "/clients" : "/partners";
            navigate(endpoint, { state: { toast: toastMessage } });
        }
    });

    const ReturnButtonHandler = () => {
        const endpoint = location.state.isClient ? "/clients" : "/partners";
        navigate(endpoint);
    }

    const AddIBANButtonHandler = () => {
        const data = [...formik.values.cagent_financial_data,
        {
            data_type: "IBAN",
            iban_number: "",
            mfo: "",
            bank_name: "",
            card_number: "",
            description: "",
        }
        ];

        formik.setFieldValue('cagent_financial_data', data);

        console.log(formik.values);
    }

    const AddCardButtonHandler = () => {
        const data = [...formik.values.cagent_financial_data,
        {
            data_type: "CARD",
            iban_number: "",
            mfo: "",
            bank_name: "",
            card_number: "",
            description: "",
        }
        ];

        formik.setFieldValue('cagent_financial_data', data);
    }

    const mainToolbarLeftTemplate = () => {
        return (<h3>Створення нового контрагента</h3>);
    }

    const mainToolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Зберегти" severity="success" className={commonStyle.addButton} type="submit" />
            <Button label="Вийти" severity="secondary" type="button" onClick={ReturnButtonHandler} className={commonStyle.closeButton} />
        </div>)
    }

    return (<form onSubmit={formik.handleSubmit}>
        <Toast ref={toast}/>
        <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate}></Toolbar>
        <div className="grid nested-grid">
            <div className={`${commonStyle.leftMenu} col-3`}>
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex flex-column gap-1">
                        <FloatLabel>
                            <Dropdown name="division" 
                            value={formik.values.division} 
                            onChange={(e) => formik.setFieldValue('division', e.value)} 
                            options={Object.values(ctx.divisions)} 
                            showClear 
                            className={formik.errors.division && formik.touched.division ? 'p-invalid' : ''} />
                            <label>Підрозділ</label>
                        </FloatLabel>
                        {formik.errors.division && formik.touched.division && (<small className={commonStyle.errorSmall}>{formik.errors.division}</small>)}
                    </div>
                </div>
                <div className={commonStyle.radioButtonBlock}>
                    <div className={commonStyle.accountingRadio}>
                        <RadioButton value="INDIVIDUAL" checked={formik.values.type === "INDIVIDUAL"} onChange={(e) => { formik.setFieldValue('type', "INDIVIDUAL"); }} />
                        <label className="ml-2">Фізична особа</label>
                    </div>
                    <div className={commonStyle.accountingRadio}>
                        <RadioButton value="LEGAL" checked={formik.values.type === "LEGAL"} onChange={(e) => { formik.setFieldValue('type', "LEGAL"); }} />
                        <label className="ml-2">Юридична особа</label>
                    </div>
                </div>
                {formik.values.type === "INDIVIDUAL" && (<>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="last_name"
                                    value={formik.values.individual_cagent_data.last_name}
                                    onChange={(e) => { formik.setFieldValue('individual_cagent_data.last_name', e.target.value) }}
                                    className={formik.errors.indLastName && formik.touched.individual_cagent_data?.last_name ? 'p-invalid' : ''}
                                />
                                <label>Прізвище контрагента</label>
                            </FloatLabel>
                            {formik.errors.indLastName && formik.touched.individual_cagent_data?.last_name && (<small className={commonStyle.errorSmall}>{formik.errors.indLastName}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="first_name"
                                    value={formik.values.individual_cagent_data.first_name}
                                    onChange={(e) => { formik.setFieldValue('individual_cagent_data.first_name', e.target.value) }}
                                    className={formik.errors.indFirstName && formik.touched.individual_cagent_data?.first_name ? 'p-invalid' : ''}
                                />
                                <label>Імя контрагента</label>
                            </FloatLabel>
                            {formik.errors.indFirstName && formik.touched.individual_cagent_data?.first_name && (<small className={commonStyle.errorSmall}>{formik.errors.indFirstName}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="surname"
                                    value={formik.values.individual_cagent_data.surname}
                                    onChange={(e) => { formik.setFieldValue('individual_cagent_data.surname', e.target.value) }}
                                />
                                <label>По-батькові контрагента</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <Calendar
                                    value={formik.values.individual_cagent_data.date_of_birth}
                                    onChange={(e) => { formik.setFieldValue('individual_cagent_data.date_of_birth', e.target.value) }}
                                    showIcon
                                />
                                <label>Дата народження</label>
                            </FloatLabel>
                        </div>
                    </div>
                </>)}
                {formik.values.type === "LEGAL" && (<>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="long_name"
                                    value={formik.values.legal_cagent_data.long_name}
                                    onChange={(e) => { formik.setFieldValue('legal_cagent_data.long_name', e.target.value) }}
                                    className={formik.errors.legalLongName && formik.touched.legal_cagent_data?.long_name ? 'p-invalid' : ''}
                                />
                                <label>Повна назва контрагента</label>
                            </FloatLabel>
                            {formik.errors.legalLongName && formik.touched.legal_cagent_data?.long_name && (<small className={commonStyle.errorSmall}>{formik.errors.legalLongName}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="short_name"
                                    value={formik.values.legal_cagent_data.short_name}
                                    onChange={(e) => { formik.setFieldValue('legal_cagent_data.short_name', e.target.value) }}
                                    className={formik.errors.legalShortName && formik.touched.legal_cagent_data?.short_name ? 'p-invalid' : ''}
                                />
                                <label>Скорочена назва контрагента</label>
                            </FloatLabel>
                            {formik.errors.legalShortName && formik.touched.legal_cagent_data?.short_name && (<small className={commonStyle.errorSmall}>{formik.errors.legalShortName}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="representative"
                                    value={formik.values.legal_cagent_data.representative}
                                    onChange={(e) => { formik.setFieldValue('legal_cagent_data.representative', e.target.value) }}
                                />
                                <label>Представник</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="edrpou"
                                    value={formik.values.legal_cagent_data.edrpou}
                                    onChange={(e) => { formik.setFieldValue('legal_cagent_data.edrpou', e.target.value) }}
                                    className={formik.errors.legalEdrpou && formik.touched.legal_cagent_data?.edrpou ? 'p-invalid' : ''}
                                />
                                <label>ЄДРПОУ</label>
                            </FloatLabel>
                            {formik.errors.legalEdrpou && formik.touched.legal_cagent_data?.edrpou && (<small className={commonStyle.errorSmall}>{formik.errors.legalEdrpou}</small>)}
                        </div>
                    </div>
                </>)}
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <InputText
                                name="email"
                                value={formik.values.email}
                                onChange={(e) => { formik.setFieldValue('email', e.target.value) }}
                            />
                            <label>Email</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <InputText
                                name="phone"
                                value={formik.values.phone}
                                onChange={(e) => { formik.setFieldValue('phone', e.target.value) }}
                            />
                            <label>Телефон</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className={commonStyle.radioButtonBlock}>
                    <div className={commonStyle.accountingRadio}>
                        <Checkbox value="isPartner" checked={formik.values.is_partner}
                            onChange={(e) => { formik.setFieldValue('is_partner', !formik.values.is_partner) }}
                            className={formik.errors.WrongContrAgentType && (formik.touched.is_client || formik.touched.is_partner) ? 'p-invalid' : ''} />
                        <label className="ml-2">Постачальник</label>
                    </div>
                    <div className={commonStyle.accountingRadio}>
                        <Checkbox value="isClient" checked={formik.values.is_client}
                            onChange={(e) => { formik.setFieldValue('is_client', !formik.values.is_client) }}
                            className={formik.errors.WrongContrAgentType && (formik.touched.is_client || formik.touched.is_partner) ? 'p-invalid' : ''} />
                        <label className="ml-2">Клієнт</label>
                    </div>
                    {formik.errors.WrongContrAgentType && (formik.touched.is_client || formik.touched.is_partner) && (<small className={commonStyle.errorSmall}>{formik.errors.WrongContrAgentType}</small>)}
                </div>
            </div>
            <div className="col-9">
                <TabMenu model={items} />
                {tabShowed === 0 && (<><div className="grid">
                    <div className="col-4">
                        <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                            <FloatLabel>
                                <Dropdown name="country" value={formik.values.country}
                                    onChange={(e) => formik.setFieldValue('country', e.value)}
                                    options={countries}
                                    className={formik.errors.country ? 'p-invalid' : ''}
                                    showClear />
                                <label>Країна</label>
                            </FloatLabel>
                            {formik.errors.country && (<small className={commonStyle.errorSmall}>{formik.errors.country}</small>)}
                        </div>
                        <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                            <FloatLabel>
                                <InputText value={formik.values.street} onChange={(e) => { formik.setFieldValue('street', e.target.value) }} />
                                <label>Вулиця</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                            <FloatLabel>
                                <Dropdown name="region" value={formik.values.region}
                                    onChange={(e) => formik.setFieldValue('region', e.value)}
                                    options={Object.values(ctx.regions)}
                                    className={formik.errors.region && formik.touched.region ? 'p-invalid' : ''}
                                    showClear />
                                <label>Область</label>
                            </FloatLabel>
                            {formik.errors.region && formik.touched.region && (<small className={commonStyle.errorSmall}>{formik.errors.region}</small>)}
                        </div>
                        <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                            <FloatLabel>
                                <InputText value={formik.values.building_number} onChange={(e) => { formik.setFieldValue('building_number', e.target.value) }} />
                                <label>Номер будинку</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                            <FloatLabel>
                                <InputText value={formik.values.city}
                                    onChange={(e) => { formik.setFieldValue('city', e.target.value) }}
                                    className={formik.errors.city && formik.touched.city ? 'p-invalid' : ''} />
                                <label>Населений пункт</label>
                            </FloatLabel>
                            {formik.errors.city && formik.touched.city && (<small className={commonStyle.errorSmall}>{formik.errors.city}</small>)}
                        </div>
                        <div className={`${commonStyle.inputfields} p-inputgroup`}>
                            <FloatLabel>
                                <InputText value={formik.values.apartment_number} onChange={(e) => { formik.setFieldValue('apartment_number', e.target.value) }} />
                                <label>Квартира</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>
                    <div className={`${commonStyle.inputfields} p-inputgroup col-8`}>
                        <span className="p-float-label md:w-90">
                            <InputTextarea value={formik.values.description} onChange={(e) => { formik.setFieldValue('description', e.target.value) }} rows={10} cols={80} />
                            <label>Примітки</label>
                        </span>
                    </div>
                </>)}
                {tabShowed === 1 && (<>
                    {(formik.values.cagent_financial_data.map((finData, index) => (
                        <div className="grid" key={index}>
                            <div className="col-4">
                                {finData.data_type === "IBAN" && <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                                    <FloatLabel>
                                        <InputText value={finData.iban_number || ""} 
                                        onChange={(e) => formik.setFieldValue(`cagent_financial_data[${index}].iban_number`, e.target.value)} />
                                        <label>Номер IBAN</label>
                                    </FloatLabel>
                                </div>}
                                {finData.data_type === "CARD" && <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                                    <FloatLabel>
                                        <InputText value={finData.card_number || ""} 
                                        onChange={(e) => { formik.setFieldValue(`cagent_financial_data[${index}].card_number`, e.target.value) }} />
                                        <label>Номер картки</label>
                                    </FloatLabel>
                                </div>}
                            </div>
                            <div className="col-4">
                                {finData.data_type === "IBAN" && <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                                    <FloatLabel>
                                        <InputText value={finData.bank_name || ""} 
                                        onChange={(e) => { formik.setFieldValue(`cagent_financial_data[${index}].bank_name`, e.target.value) }} />
                                        <label>Назва банку</label>
                                    </FloatLabel>
                                </div>}
                            </div>
                            <div className="col-3">
                                {finData.data_type === "IBAN" && <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                                    <FloatLabel>
                                        <InputText value={finData.mfo || ""} 
                                        onChange={(e) => { formik.setFieldValue(`cagent_financial_data[${index}].mfo`, e.target.value) }} />
                                        <label>МФО</label>
                                    </FloatLabel>
                                </div>}
                            </div>
                            <div className="col-1">
                                <Button
                                    icon="pi pi-trash"
                                    className={`p-button-rounded p-button-danger ${commonStyle.deleteButton}`}
                                    style={{ marginTop: "2rem" }}
                                    tooltip="Видалити"
                                    tooltipOptions={{ showOnDisabled: true }}
                                    type="button"
                                    onClick={() => {
                                        const newArray = formik.values.cagent_financial_data.filter((_, i) => i !== index);
                                        formik.setFieldValue('cagent_financial_data', newArray);
                                    }}
                                />
                            </div>
                        </div>
                    )))}
                    <div className="col-12">
                        <Button icon="pi pi-credit-card" label="Додати картку" severity="success" className={`${commonStyle.addButton} ${commonStyle.addButtonNav}`} onClick={AddCardButtonHandler} type="button" />
                        <Button icon="pi pi-money-bill" label="Додати рахунок" severity="success" className={commonStyle.addButton} onClick={AddIBANButtonHandler} type="button" />
                    </div>
                    <div className={`${commonStyle.inputfields} p-inputgroup col-8`}>
                        <span className="p-float-label md:w-90">
                            <InputTextarea value={formik.values.cagent_financial_data.description} onChange={(e) => { formik.setFieldValue('cagent_financial_data.description', e.target.value) }} rows={10} cols={80} />
                            <label>Примітки</label>
                        </span>
                    </div>
                </>)}
                {tabShowed === 2 && <HistoryElement data={cagentHistory} lazyState={historyLazyState} totalRecords={historyTotalRecords} onHistoryPage={onHistoryPage} isAct={false}/>}
            </div>
        </div>
    </form>
    );
}

export default NewCounterAgent;