export const paymentMethodTranslations = {
    "INCOME": "Прихід",
    "OUTCOME": "Розхід"
}

const paymentTypeTranslations = {
    "CASH": "Готівка",
    "CARD": "Термінал",
    "IBAN": "Рахунок"
}

export const paymentStatusTranslation = {
    "DISTRIBUTED" : "Розподілено",
    "NOT_DISTRIBUTED" : "Не розподілено",
    "PARTLY_DISTRIBUTED": "Частково розподілено"
}

export const transactionTypeTranslation = {
    "DEBIT": "Дебет",
    "CREDIT": "Кредит"
}

const translations = {
    "accounting_type": "Тип обліку",
    "act_date": "Дата акту",
    "act_number": "Номер акту",
    "act_type": "Тип акту",
    "ADMIN_PANEL": "CRM",
    "admin_panel_access": "Доступ до адмінки",
    "apartment_number": "Номер квартири",
    "auth": "Користувачі та ролі",
    "building_number": "Номер будинку",
    "cagent": "Контрагент",
    "cagent_financial_data": "Фінансові дані",
    "cagents": "Контрагенти",
    "CASH_APP": "Каса",
    "cash_app_access": "Доступ до каси",
    "city": "Місто",
    "client_return_acts": "Акти повернення товару клієнтом",
    "country": "Країна",
    "CREATE": "Створення",
    "currency": "Валюта",
    "custom_sale_price": "Ціна продажу",
    "custom_sale_price_currency": "Валюта продажу",
    "dashboards": "Дашборди",
    "date": "Дата",
    "DELETE": "Видалення",
    "delete": "Видалення",
    "description": "Опис",
    "discount": "Знижка",
    "distributor_return_acts":"Акт повернення товару постачальнику",
    "division": "Підрозділ",
    "divisions": "Підрозділи",
    "edrpou": "ЄДРПОУ",
    "employee_position": "Посада",
    "fields": "Поля",
    "field_name": "Назва поля",
    "field_type": "Тип поля",
    "first_name": "Ім'я",
    "full_price": "Загальна ціна",
    "full_price_UAH": "Загальна ціна, грн",
    "group_name": "Назва групи",
    "identified_products": "Товари поштучного обліку",
    "identifier": "Ідентифікатор",
    "identifier_name": "Поле для ідентифікації",
    "INDIVIDUAL": "Індивідуальний",
    "is_active":"Активований",
    "is_client": "Є Клієнтом",
    "is_partner": "Є Партнером",
    "last_name": "Прізвище",
    "LOGIN_SUCCESS": "Успішний вхід",
    "long_name": "Повна назва",
    "mandatory": "Обов'язковість",
    "name": "Назва",
    "notes": "Опис",
    "note": "Опис",
    "organization": "Підрозділ",
    "organizations": "Організації",
    "pay_due_date": "Очікувана дата оплати",
    "pay_status": "Статус оплати",
    "payments": "Платежі",
    "phone": "Телефон",
    "PIECE": "Поштучний",
    "postal_code": "Поштовий індекс",
    "price": "Ціна",
    "priceUAH": "Ціна в гривні",
    "product": "Товар",
    "product_article": "Артикул",
    "product_fields_values": "Поля продукту",
    "product_group": "Група товарів",
    "product_name": "Назва товару",
    "products": "Товари",
    "receive_acts":"Акти отримання",
    "region": "Область",
    "remainings": "Залишки",
    "representative": "Представник",
    "roles": "Ролі",
    "root_division": "Батьківський підрозділ",
    "root_group": "Батьківська група",
    "sales_acts": "Акти продажу",
    "service_article": "Артикул",
    "service_field_values": "Поля",
    "service_group": "Батьківська група",
    "services": "Послуги",
    "short_name": "Скорочена назва",
    "status": "Статус",
    "street": "Вулиця",
    "transactions": "Транзакції",
    "type": "Тип",
    "unidentified_products": "Товари загального обліку",
    "UPDATE": "Оновлення",
    "use_product_sale_price": "Використовувати ціну з довідника",
    "user": "Користувач",
    "username": "Логін"
}

export const TranslatePaymentMethod = (name) => {
    return paymentMethodTranslations[name];
}

export const TranslatePaymentType = (name) => {
    return paymentTypeTranslations[name];
}

export const TranslatePaymentStatus = (name) => {
    return paymentStatusTranslation[name];
}

export const TranslateTransactionType = (name) => {
    return transactionTypeTranslation[name];
}

export const Translate = (name) => {
    return translations[name] === undefined ? name : translations[name]; 
}

export const UaLocale =
{
    startsWith: 'Починається з',
    contains: 'Містить',
    notContains: 'Не містить',
    endsWith: 'Закінчується на',
    equals: 'Рівно',
    notEquals: 'Не рівно',
    noFilter: 'Без фільтра',
    lt: 'Менше ніж',
    lte: 'Менше або рівно',
    gt: 'Більше ніж',
    gte: 'Більше або рівно',
    dateIs: 'Дата є',
    dateIsNot: 'Дата не є',
    dateBefore: 'Дата до',
    dateAfter: 'Дата після',
    clear: 'Очистити',
    apply: 'Застосувати',
    matchAll: 'Збігається з усім',
    matchAny: 'Збігається з будь-яким',
    addRule: 'Додати правило',
    removeRule: 'Видалити правило',
    accept: 'Так',
    reject: 'Ні',
    choose: 'Вибрати',
    upload: 'Завантажити',
    cancel: 'Скасувати',
    dayNames: [
        'Неділя', 'Понеділок', 'Вівторок', 'Середа', 'Четвер', 'П’ятниця', 'Субота'
    ],
    dayNamesShort: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    monthNames: [
        'Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень',
        'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'
    ],
    monthNamesShort: ['Січ', 'Лют', 'Бер', 'Квіт', 'Трав', 'Чер', 'Лип', 'Серп', 'Вер', 'Жов', 'Лис', 'Гру']
}
