import React, { useContext, useState } from 'react';
import { UserPermissionContext } from '../../context/UserPermissionProvider';

import { PanelMenu } from 'primereact/panelmenu';
import { useNavigate } from 'react-router-dom';

import './NavigationBar.module.css';

const NavigationBar = params => {

    const [expandedKeys, setExpandedKeys] = useState({});

    const permCtx = useContext(UserPermissionContext);
    
    const navigate = useNavigate();

    const closeSideBar = () =>{
        params.switchPage();
    }

    const IncludeDashboards = mainItems => {

        const dashBoardSection = {
            key: "1",
            label: 'Дашборд',
            icon: 'pi pi-fw pi-chart-line',
            items: []
        }

        if (permCtx.permissions?.includes("dashboards.view_dashboard")) {
            dashBoardSection.items.push({
                label: 'Віджети',
                command: () => {
                    navigate('/widgets');
                    closeSideBar();
                }
            });
        }

        /*{
            label: 'Динаміка продажів',
        },
        {
            label: 'Порівняння проданих товарів',
        },
        {
            label: 'Фінансовий результат',
        }*/

        if (dashBoardSection.items.length > 0) {
            mainItems.push(dashBoardSection);
        }
    }

    const IncludeProductsAndServices = mainItems => {

        const productAndServicesSection = {
            key: "2",
            label: 'Товари та послуги',
            icon: 'pi pi-shopping-cart',
            items: []
        }

        if (permCtx.permissions?.includes("products.view_productgroups") ||
            permCtx.permissions?.includes("products.view_products")) {
            productAndServicesSection.items.push({
                label: 'Товари',
                command: () => {
                    navigate('/products');
                    closeSideBar();
                }
            });
        }

        if (permCtx.permissions?.includes("services.view_servicegroup") ||
            permCtx.permissions?.includes("services.view_service")) {
            productAndServicesSection.items.push({
                label: 'Послуги',
                command: () => {
                    navigate('/services');
                    closeSideBar();
                }
            });
        }

        /*{
            label: 'Групи товарів',
                command: () => {
                navigate('/productgroups');
                closeSideBar();
                }
        },
        {
            label: 'Групи послуг',
                command: () => {
                navigate('/servicegroups');
                closeSideBar();
            }
        },*/

        if (productAndServicesSection.items.length > 0) {
            mainItems.push(productAndServicesSection);
        }
    }
    
    const IncludeAccounting = mainItems => {

        const accountingSection = {
            key: "3",
            label: "Облік",
            icon: 'pi pi-fw pi-calendar',
            items: []
        }

        if (permCtx.permissions?.includes("receive_acts.view_receive_act")) {
            accountingSection.items.push({
                label: 'Прийом товарів',
                command: () => {
                    navigate('/productsreception');
                    closeSideBar();
                }
            });
        }

        if (permCtx.permissions?.includes("remainings.view_remainings")) {
            accountingSection.items.push({
                label: 'Залишки',
                command: () => {
                    navigate('/leftovers');
                    closeSideBar();
                }
            });
        }

        if (permCtx.permissions?.includes("distributor_return_acts.view_distributor_return_act")) {
            accountingSection.items.push({
                label: 'Повернення постачальнику',
                command: () => {
                    navigate('/distributorreturns');
                    closeSideBar();
                }
            });
        }

        /*{
            label: 'Переміщення товарів',
            command: () => {
            navigate('/productsmove');
            } 
        }, */

        if (permCtx.permissions?.includes("write_off_acts.view_write_off_act")) {
            accountingSection.items.push({
                label: 'Списання',
                command: () => {
                    navigate('/writeoff');
                    closeSideBar();
                }
            });
        }

        /*{
            label: 'Зовнішні товари',
        }*/

        if (accountingSection.items.length > 0) {
            mainItems.push(accountingSection);
        }
    }

    const IncludeSales = mainItems => {

        const salesSection = {
            key: "4",
            label: "Продажі",
            icon: 'pi pi-fw pi-money-bill',
            items: []
        }

        if (permCtx.permissions?.includes("sales_acts.view_sale_act")) {
            salesSection.items.push({
                label: 'Продажі та послуги',
                command: () => {
                    navigate('/sales');
                    closeSideBar();
                }
            });
        }

        if (permCtx.permissions?.includes("client_return_acts.view_client_return_act")) {
            salesSection.items.push({
                label: 'Повернення клієнтів',
                command: () => {
                    navigate('/productsreturn');
                    closeSideBar();
                }
            });
        }

        if(salesSection.items.length > 0){
            mainItems.push(salesSection);
        }
    }

    const IncludeCounterAgents = mainItems => {

        const counterAgentSection = {
            key: "5",
            label: "Контрагенти ",
            icon: 'pi pi-fw pi-comment',
            items: []
        }

        if (permCtx.permissions?.includes("cagents.view_cagent_partner")) {
            counterAgentSection.items.push({
                label: 'Постачальники',
                command: () => {
                    navigate('/partners');
                    closeSideBar();
                }
            });
        }

        if(permCtx.permissions?.includes("cagents.view_cagent_client")) {
            counterAgentSection.items.push({
                label: 'Клієнти',
                command: () => {
                    navigate('/clients');
                    closeSideBar();
                }
            });
        }

        if(counterAgentSection.items.length > 0){
            mainItems.push(counterAgentSection);
        }
    }
    
    const IncludeBookkeeping = mainItems => {
        
        const bookkeepingSection = {
            key: "6",
                label: "Бухгалтерія",
                icon: 'pi pi-fw pi-paperclip',
                items: []
        };

        console.log(permCtx.permissions);

        if(permCtx.permissions?.includes("payments.view_payment")){
            bookkeepingSection.items.push({
                label: 'Платежі',
                command: () => {
                    navigate("/payments");
                    closeSideBar();
                }
            });
        }
    
        if (permCtx.permissions?.includes("transactions.view_transaction")) {
            bookkeepingSection.items.push({
                label: 'Транзакції',
                command: () => {
                    navigate("/transactions");
                    closeSideBar();
                }
            });
        }

        if (permCtx.permissions?.includes("cagents.view_cagents_settlements")) {
            bookkeepingSection.items.push({
                label: "Розрахунки з контрагентами",
                command: () => {
                    navigate("/settlements");
                    closeSideBar();
                }
            });
        }
        
        if(bookkeepingSection.items.length > 0){
            mainItems.push(bookkeepingSection);
        }
    }

    const IncludeSettings = mainItems => {

        const settingsSection = {
            key: "7",
            label: "Налаштування",
            icon: 'pi pi-fw pi-cog',
            items: []
        };

        if(permCtx.permissions?.includes("organizations.view_organizations")){
            settingsSection.items.push({
                label: 'Налаштування організації',
                command: () => {
                    navigate('/organization');
                    closeSideBar();
                }
            });
        }

        if(permCtx.permissions?.includes("auth.view_user")){
            settingsSection.items.push({
                label: 'Користувачі',
                command: () => {
                    navigate('/users');
                    closeSideBar();
                }
            });
        }

        if(permCtx.permissions?.includes("auth.view_group")){
            settingsSection.items.push({
                label: 'Ролі користувача',
                command: () => {
                    navigate('/roles');
                    closeSideBar();
                }
            });
        }

        if(permCtx.permissions?.includes("divisions.view_division")){
            settingsSection.items.push({
                label: 'Ієрархія підрозділів',
                command: () => {
                    navigate('/divisions');
                    closeSideBar();
                }
            });
        }

        if(permCtx.permissions?.includes("currency.view_exchangerate")){
            settingsSection.items.push({
                label: 'Валюта',
                command: () => {
                    navigate('/currencies');
                    closeSideBar();
                }
            });
        }

        /*{
            label: 'Маркетплейс',
        }*/

        if(settingsSection.items.length > 0){
            mainItems.push(settingsSection);
        }

    }

    const GetNavBarItems = () => {
        const items = [];
        IncludeDashboards(items);
        IncludeProductsAndServices(items);
        IncludeAccounting(items);
        IncludeSales(items);
        IncludeCounterAgents(items);
        IncludeBookkeeping(items);
        IncludeSettings(items);
        return items;
    }
  
    return <div className="card flex justify-content-left">
        <PanelMenu
            model={GetNavBarItems()}
            expandedKeys={expandedKeys} 
            onExpandedKeysChange={setExpandedKeys}
            pt={{
                root: { className: 'w-full' },
                headerAction: ({ context }) => ({ className: context.active ? 'bg-primary-200' : undefined }),
                action: ({ context }) => ({ className: context.active ? 'bg-primary-100' : undefined })
            }}
        />
    </div>
}

export default NavigationBar;