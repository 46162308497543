import ActStatusCircle from "../../../UIElements/StatusCircle";
import EnumsContext from "../../../../context/enums-context";
import { findKeyByValue, formatCurrency } from "../../../../Utils/Utils";
import PaymentSideBar from "../../../UIElements/PaymentSidebar";
import StickerPrintSidebar from "../../../UIElements/StickerPrintSidebar";
import useApi from "../../../../Utils/BackendClient";
import { useContext, useEffect, useRef, useState } from "react";
import useGetEnums from "../../../../Utils/EnumsUtils";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { FloatLabel } from "primereact/floatlabel";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from 'primereact/toast';
import { Toolbar } from "primereact/toolbar";

import commonStyle from "../../CommonStyles.module.css";


const ProductsReception = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const toast = useRef(null);
    const buttonRef = useRef(null);
    const ctx = useContext(EnumsContext);

    const { deleteReceptionAct, fetchReceptionActDetails, fetchReceptionActs, finalizeReceptionAct } = useApi();

    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    const [acts, setActs] = useState(null);
    const [visible, setVisible] = useState(false);
    const [stickerSidebarVisible, setStickerSidebarVisible] = useState(false);
    const [productsToPrint, setProductsToPrint] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 932);
    const [showFilters, setShowFilters] = useState(false);
    const [tableFilters, setTableFilters] = useState({
        cagent: { value: null, matchMode: 'contains' },
        division: { value: null, matchMode: 'contains' },
        pay_status: { value: null, matchMode: 'contains' }
    });
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "created_at",
        sortOrder: 1,
        filters: {
            cagent: { value: null, matchMode: 'contains' },
            division: { value: null, matchMode: 'contains' },
            pay_status: { value: null, matchMode: 'contains' }
        }
    });
    const [reloadData, setReloadData] = useState(true);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 932);
      };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    useGetEnums(["divisions", "partners", "pay_statuses"]);

    const showToast = (msg) => {
        toast.current.show(msg);
    }

    useEffect(() => {
        if (location.state !== null) {
            toast.current.show(location.state.toast);
        }
        location.state = null;
    }, [location.state, location]);

    useEffect(() => {
        const fetchData = async () => {

            const response = await fetchReceptionActs(lazyState);

            if (response.status === 200) {

                setTotalRecords(response.data.count);
                setActs(response.data.results);

            }
            else {
                setActs([]);
                toast.current.show({ severity: "error", summary: "Помилка завантаження списку актів", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            setLoading(false);
        }

        fetchData();

    }, [reloadData, lazyState, fetchReceptionActs]);

    const hideSidebar = () => {
        setVisible(false);
    };

    const FinalizeActHandler = (rowData) => {

        const finalizeAct = async () => {

            const response = await finalizeReceptionAct(rowData.id);

            if (response.status !== 200) {
                toast.current.show({ severity: "error", summary: "Помилка фіналізації", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else {
                toast.current.show({ severity: 'success', summary: 'Фіналізація', detail: 'Акт фіналізовано', life: 3000 });
            }

            setReloadData(!reloadData);
        }

        finalizeAct();
    }

    const DeleteButtonHandler = rowData => {
        const deleteAct = async () => {

            const response = await deleteReceptionAct(rowData.id);

            if (response.status !== 204) {
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else {
                toast.current.show({ severity: "success", summary: 'Видалення', detail: 'Акт успішно видалено', life: 3000 });
            }
            setReloadData(!reloadData);
        }

        deleteAct();
    }

    const PaymentHandler = (rowData) => {
        setSelectedItem(rowData);
        setVisible(true);
    }

    const CreateNewSaleButtonHandler = () => {
        navigate("/productsreception/newreception");
    }

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const onFilter = (event) => {
        event.page = 1;
        setTableFilters(event.filters);
    }

    const confirmDelete = (rowData) => {
        confirmDialog({
            group: 'headless',
            header: 'Підтвердження',
            message: 'Підтвердіть видалення акту',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            defaultFocus: 'accept',
            accept: () => { DeleteButtonHandler(rowData) }
        });
    }

    const confirmFinalize = (event, rowData) => {
        confirmDialog({
            group: 'headless',
            header: 'Підтвердження',
            message: 'Після фіналізації акту, буде відсутня можливість його редагування',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            defaultFocus: 'accept',
            accept: () => { FinalizeActHandler(rowData) }
        });
    }

    const EditProductHandler = rowData => {
        const route = '/productsreception/editreception/' + rowData.id;
        navigate(route, { state: { id: rowData.id } });
    }

    const filterApply = () => {
        const editedLazyState = lazyState;
        editedLazyState.filters = tableFilters;
        setlazyState(editedLazyState);
        setReloadData(!reloadData)
    }

    const clearFilter = (paramName, value) => {
        setlazyState(prevState => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                [paramName]: {
                    ...prevState.filters[paramName],
                    value: value
                }
            }
        }));
    }

    const clearFilterFromChips = (paramName, value) => {
        clearFilter(paramName, value);
        setTableFilters(prevState => ({
            ...prevState,
            [paramName]: {
                value: value
            }
        }));
    }

    const formatFilterChips = () => {
        const element = [];

        Object.keys(lazyState.filters).forEach((filterKey) => {
            const filter = lazyState.filters[filterKey];

            if (filter.value && filter.value !== "") {

                let translatedKey = filterKey;
                let changedValue = filter.value;
                let removeValue = null;

                if (filterKey === "cagent") {
                    translatedKey = "Постачальник";
                    changedValue = ctx.partners[changedValue];
                    removeValue = "";
                }
                else if (filterKey === "division") {
                    translatedKey = "Підрозділ";
                    changedValue = ctx.divisions[changedValue];
                    removeValue = "";
                }
                else if (filterKey === "pay_status") {
                    translatedKey = "Статус оплати";
                    changedValue = ctx.pay_statuses[changedValue];
                    removeValue = "";
                }

                element.push(
                    <Chip
                        key={filterKey}
                        label={`${translatedKey}: ${changedValue}`}
                        removable
                        onRemove={() => clearFilterFromChips(filterKey, removeValue)} // Optional: To handle removal
                    />
                );
            }
        });

        return <div className="flex flex-wrap gap-2">{element}</div>;
    }

    const setStickerValues = async (rowData) => {
        const response = await fetchReceptionActDetails(rowData.id);

        if(response.status === 200){
            const productsToPrint = [];

            if (response.data.identified_products !== undefined && response.data.identified_products.length > 0) {
                response.data.identified_products.forEach(product => {
                    const newProduct = {};
                    newProduct.id = product.remaining_id;
                    newProduct.product_name = product.product;
                    newProduct.total_quantity = 1;
                    newProduct.identifier = product.identifier;
                    productsToPrint.push(newProduct);
                });
            }

            if (response.data.unidentified_products !== undefined && response.data.unidentified_products.length > 0) {
                response.data.unidentified_products.forEach(product => {
                    const newProduct = {};
                    newProduct.id = product.remaining_id;
                    newProduct.product_name = product.product;
                    newProduct.total_quantity = +product.quantity;
                    console.log(newProduct);
                    productsToPrint.push(newProduct);
                });
            }

            setProductsToPrint(productsToPrint);
        }
    }

    const HandleStickers = (rowData) => {
        setStickerValues(rowData);
        setStickerSidebarVisible(true);
    }

    const handleMobileFilterChange = (key, value) => {
        setTableFilters((prevFilters) => ({
            ...prevFilters,
            [key]: { ...prevFilters[key], value: value }
        }));
    }

    const clearMobileFilters = () => {
        setTableFilters({
            cagent: { value: null, matchMode: 'contains' },
            division: { value: null, matchMode: 'contains' },
            pay_status: { value: null, matchMode: 'contains' }
        });

        setlazyState(prevState => ({
            ...prevState,
            filters: {
                cagent: { value: null, matchMode: 'contains' },
                division: { value: null, matchMode: 'contains' },
                pay_status: { value: null, matchMode: 'contains' }
            }
        }));
    }

    const tableHeader = (
        <div className="flex justify-content-between align-items-center w-full">
            {formatFilterChips()}
        </div>
    );

    const toolbarLeftTemplate = () => {
        return (isMobile && <Button icon={`${!showFilters ? "pi pi-filter" : "pi pi-filter-slash"}`} severity="secondary" outlined onClick={() => setShowFilters(!showFilters)} />)
    }

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Додати акт прийому товару" severity="success" className={commonStyle.addButton} onClick={CreateNewSaleButtonHandler} />
        </div>)
    }

    const formatAdditionalButtons = (rowData) => {
        let buttons = [];
        if (rowData.status !== "FINALIZED") {
            buttons.push({
                label: 'Фіналізувати',
                icon: 'pi pi-lock',
                command: (event) => { confirmFinalize(event, rowData) }
            });
        }
        else{
            if (rowData.pay_status !== "PAYED") {
                buttons.push({
                    label: 'Оплатити',
                    icon: 'pi pi-money-bill',
                    command: () => { PaymentHandler(rowData) }
                });
            }

            buttons.push({
                label: 'Друкувати цінники',
                icon: 'pi pi-barcode',
                command: () => { HandleStickers(rowData) }
            });
        }

        if (rowData.can_be_deleted) {
            buttons.push({
                label: 'Видалити',
                icon: 'pi pi-trash',
                command: () => { confirmDelete(rowData) }
            });
        }

        buttons = buttons.length > 0 ? buttons : null;
        return buttons
    };

    const actionBodyTemplate = (rowData) => {
        const buttons = formatAdditionalButtons(rowData);

        const buttonLabel = rowData.status !== "FINALIZED" ? "Редагувати" : "Деталі акту";
        const buttonIcon = rowData.status !== "FINALIZED" ? "pi pi-pencil" : "pi pi-eye";

        return (
            <>
                {buttons !== null ? (
                    <SplitButton
                        label={!isMobile ? buttonLabel : null}
                        icon={buttonIcon}
                        onClick={() => EditProductHandler(rowData)}
                        model={buttons}
                        text
                        ref={buttonRef} // Set the ref here
                    />
                ) : (
                    <Button
                        label={!isMobile ? buttonLabel : null}
                        icon={buttonIcon}
                        onClick={() => EditProductHandler(rowData)}
                        text
                    />
                )}
            </>
        );
    };

    const dropDownTemplate = (options, values) => {
        const dropdownOptions = Object.entries(values).map(([key, value]) => ({
            value: key,
            label: value,
        }));

        return (
            <Dropdown
                value={options.value}
                onChange={(e) => options.filterApplyCallback(e.value)}
                options={dropdownOptions}
                placeholder="Виберіть значення"
                className="p-column-filter"
            />
        );
    };

    const mobileActBody = rowData => {

        const color = rowData.pay_status === "PAYED" ? "#03C04A" : "#EF4444";

        return (
            <div className="flex justify-content-between flex-wrap">
                <div className="grid w-8">
                    <div className="col-2 flex justify-content-center align-items-center">
                        <ActStatusCircle rowData={rowData} />
                    </div>
                    <div className="col-10">
                        <div className="flex-column">
                            <div>Акт №{rowData.act_number}</div>
                            <div><i className="pi pi-clock"/> {rowData.act_date}</div>
                            <div style={{ color }}><i className={`pi ${rowData.pay_status === "PAYED" ? "pi-check" : "pi-times"}`}/> {ctx.pay_statuses[rowData.pay_status]}</div>
                            <div><i className="pi pi-user"/> {ctx.partners[rowData.cagent]}</div>
                            <div><i className="pi pi-money-bill"/> {formatCurrency(rowData.amountUAH, "UAH")}</div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-content-center align-items-center">
                    {actionBodyTemplate(rowData)}
                </div>
            </div>
        )
    }

    const mobileTableHeader = () => {
        return (
            showFilters && <div className="grid">
                <div className="col-12 mt-3">
                    <FloatLabel>
                        <Dropdown
                            value={ctx.partners[tableFilters.cagent.value]}
                            onChange={e => handleMobileFilterChange("cagent", findKeyByValue(ctx.partners, e.value))}
                            options={Object.values(ctx.partners)}
                            placeholder="Виберіть значення"
                            className="p-column-filter w-full"
                            showClear
                        />
                        <label>Постачальник</label>
                    </FloatLabel>
                </div>
                <div className="col-12 mt-3">
                    <FloatLabel>
                        <Dropdown
                            value={ctx.pay_statuses[tableFilters.pay_status.value]}
                            onChange={e => handleMobileFilterChange("pay_status", findKeyByValue(ctx.pay_statuses, e.value))}
                            options={Object.values(ctx.pay_statuses)}
                            placeholder="Виберіть значення"
                            className="p-column-filter w-full"
                            showClear
                        />
                        <label>Статус оплати</label>
                    </FloatLabel>
                </div>
                <div className="col-6 flex justify-content-center">
                    <Button severity="secondary" outlined style={{ width: "98%" }} onClick={() => clearMobileFilters()} label="Очистити фільтри" />
                </div>
                <div className="col-6 flex justify-content-center">
                    <Button severity="success" outlined style={{ width: "98%" }} onClick={() => filterApply()} label="Застосувати" />
                </div>
            </div>
        );
    }

    return (<>
        <Toast ref={toast} />
        <Toolbar start={toolbarLeftTemplate} end={toolbarRightTemplate}></Toolbar>
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                {!isMobile && <DataTable value={acts} header={tableHeader} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage} onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                    first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]}
                    dataKey="id" onFilter={onFilter} filters={lazyState.filters} emptyMessage="Актів не знайдено" tableStyle={{ minWidth: '60rem' }} >
                    <Column field="act_number" header="Номер акту" sortable />
                    <Column field="act_date" dataType="date" header="Дата акту" sortable />
                    <Column field="cagent" header="Постачальник" sortable filter filterElement={(options) => dropDownTemplate(options, ctx.partners)} onFilterApplyClick={filterApply}
                        onFilterClear={() => clearFilter("cagent", null)} showFilterMatchModes={false} body={rowData => ctx.partners[rowData.cagent]} style={{ width: '13%' }} />
                    <Column field="division" header="Підрозділ" sortable filter filterElement={(options) => dropDownTemplate(options, ctx.divisions)} onFilterApplyClick={filterApply}
                        onFilterClear={() => clearFilter("division", null)} showFilterMatchModes={false} body={rowData => ctx.divisions[rowData.division]} style={{ width: '10%' }} />
                    <Column field="amountUAH" header="Сума акту, грн" body={rowData => formatCurrency(rowData.amountUAH, "UAH")} />
                    <Column field="pay_status" header="Статус оплати" filter filterElement={(options) => dropDownTemplate(options, ctx.pay_statuses)} onFilterApplyClick={filterApply}
                        onFilterClear={() => clearFilter("pay_status", null)} showFilterMatchModes={false} body={rowData => ctx.pay_statuses[rowData.pay_status]} style={{ width: '10%' }} />
                    <Column field="pay_due_date" header="Дата оплати" />
                    <Column field="status" header="Статус" style={{ width: '4%' }} body={rowData => <ActStatusCircle rowData={rowData} />} sortable />
                    <Column field="action" body={actionBodyTemplate} />
                </DataTable>}
                {isMobile && <DataTable value={acts} header={mobileTableHeader} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage} onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                    first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]} paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                    dataKey="id" onFilter={onFilter} filters={lazyState.filters} emptyMessage="Актів не знайдено" >
                        <Column field="act_number" headerStyle={{ display: 'none' }} body={mobileActBody}/>
                    </DataTable>}
            </div>
            <ConfirmDialog group="headless" />
        </div>
        <PaymentSideBar visible={visible} onHide={hideSidebar} onMessage={showToast} data={selectedItem} moneyMovementType="OUTCOME" reloadData={() => setReloadData(!reloadData)}/>
        <StickerPrintSidebar visible={stickerSidebarVisible} onHide={() => setStickerSidebarVisible(false)} data={productsToPrint}/>   
    </>);
}

export default ProductsReception;