import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import EnumsContext from "../../../../context/enums-context";
import useGetEnums from "../../../../Utils/EnumsUtils";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Menubar } from "primereact/menubar";
import { RadioButton } from "primereact/radiobutton";
import { Toolbar } from "primereact/toolbar";

import commonStyle from '../../CommonStyles.module.css';
import { findKeyByValue } from "../../../../Utils/Utils";
import useAuthenticatedRequest from "../../../../Utils/TokenrRefreshUtils";

const NewCounterAgent = () => {

    const countries = ["Україна"];
    const ctx = useContext(EnumsContext);
    const authenticatedRequest = useAuthenticatedRequest();

    const navigate = useNavigate();
    const location = useLocation();

    const [showFinData, setShowFinData] = useState(false);
    const [cagentData, setCagentData] = useState({
        type: "INDIVIDUAL",
        division: null,
        phone: "",
        email: "",
        is_partner: !location.state.isClient,
        is_client: location.state.isClient,
        country: "Україна",
        region: null,
        city: "",
        street: "",
        building_number: "",
        apartment_number: "",
        notes: "",
        individual_cagent_data: {
            first_name: "",
            last_name: "",
            surname: "",
            date_of_birth: null
        },
        legal_cagent_data: {
            long_name: "",
            short_name: "",
            representative: "",
            edrpou: ""
        },
        cagent_financial_data: []
    });

    useGetEnums(["divisions", "regions"]);

    const menuItems = [
        {
            label: "Адреса",
            className: !showFinData ? commonStyle["active-menu-item"] : "",
            command: () => {
                setShowFinData(false);
            }

        },
        {
            label: "Фінансові дані",
            className: showFinData ? commonStyle["active-menu-item"] : "",
            command: () => {
                setShowFinData(true);
            }
        }
    ]

    useEffect(() => {
        if (location.state.id !== undefined) {

            const fetchData = async () => {

                const url = process.env.REACT_APP_BACKEND_URL + "cagents/get_details/" + location.state.id + "/";
                const options = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };

                const response = await authenticatedRequest(url, options);
                const parsedResponse = await response.json();
                //logging to remove later
                console.log(parsedResponse);

                parsedResponse.division = ctx.divisions[parsedResponse.division];
                parsedResponse.country = "Україна";
                parsedResponse.region = ctx.regions[parsedResponse.region];

                if (parsedResponse.individual_cagent_data != null) {
                    parsedResponse.individual_cagent_data.date_of_birth = new Date(parsedResponse.individual_cagent_data.date_of_birth);
                }

                setCagentData(parsedResponse);
            }

            fetchData();
        }
    }, [location.state, ctx.divisions, ctx.regions, authenticatedRequest]);

    const formatDate = (date) => {
        if (!date) return null;
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const formik = useFormik({
        initialValues: cagentData,
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (values.type === "INDIVIDUAL") {

                if (values.individual_cagent_data.last_name === null || values.individual_cagent_data.last_name === "") {
                    errors.indLastName = "Прізвище контрагента не повинне бути пустим";
                }

                if (values.individual_cagent_data.first_name === null || values.individual_cagent_data.first_name === "") {
                    errors.indFirstName = "Імя контрагента не повинне бути пустим";
                }

            }
            else {

                if (values.legal_cagent_data.long_name === null || values.legal_cagent_data.long_name === "") {
                    errors.legalLongName = "Повна назва організації не повинна бути пустою";
                }

                if (values.legal_cagent_data.short_name === null || values.legal_cagent_data.short_name === "") {
                    errors.legalShortName = "Коротка назва організації не повинна бути пустою";
                }
                if (values.legal_cagent_data.edrpou === null || values.legal_cagent_data.edrpou === "") {
                    errors.legalEdrpou = "ЄДРПОУ не повинен бути пустим";
                }
                else if (isNaN(Number(values.legal_cagent_data.edrpou))) {
                    errors.egalEdrpou = 'ЄДРПОУ повинен бути числом';
                }
            }

            if (values.country === null || values.country === undefined) {
                errors.country = "Виберіть будь ласка країну"
            }

            if (values.region === null || values.region === undefined) {
                errors.region = "Виберіть будь ласка область"
            }

            if (values.city === null || values.city === "") {
                errors.city = "Населений пункт не повинен бути пустим"
            }

            if (!values.is_partner && !values.is_client) {
                errors.WrongContrAgentType = "Контрагент повинен бути клієнтом або постачальником";
            }

            values.cagent_financial_data.forEach((item, index) => {
                if (!item.data_type) {
                  if (!errors.cagent_financial_data) {
                    errors.cagent_financial_data = [];
                  }
                  errors.cagent_financial_data[index] = { data_type: "Data type is required" };
                }
                // Add other validations as needed
              });

            return errors;
        },
        onSubmit: (values) => {

            values.division = findKeyByValue(ctx.divisions, values.division);
            values.country = "UKRAINE";

            values.region = findKeyByValue(ctx.regions, values.region);

            if (values.individual_cagent_data !== undefined && values.individual_cagent_data !== null) {
                values.individual_cagent_data.date_of_birth = formatDate(values.individual_cagent_data.date_of_birth);
            }

            const { type, individual_cagent_data, legal_cagent_data, ...rest } = values;

            values = {
                type,
                ...rest,
                ...(type === "INDIVIDUAL" ? { individual_cagent_data } : { legal_cagent_data })
            };

            console.log(values);

            const addCounterAgentHandler = async event => {

                const url = location.state.id !== undefined ? process.env.REACT_APP_BACKEND_URL + "cagents/update/" + location.state.id + "/" : process.env.REACT_APP_BACKEND_URL + "cagents/create/";
                const method = location.state.id !== undefined ? "PUT" : "POST";

                const options = {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                };

                const response = await authenticatedRequest(url, options);
                console.log(response);

                ReturnButtonHandler();
            }

            addCounterAgentHandler();
        }
    });

    const ReturnButtonHandler = () => {
        if (location.state.isClient) {
            navigate('/clients');
        }
        else {
            navigate('/partners');
        }
    }

    const AddIBANButtonHandler = () => {
        const data = [...formik.values.cagent_financial_data,
        {
            data_type: "IBAN",
            iban_number: "",
            mfo: "",
            bank_name: "",
            card_number: "",
            description: "",
        }
        ];

        formik.setFieldValue('cagent_financial_data', data);

        console.log(formik.values);
    }

    const AddCardButtonHandler = () => {
        const data = [...formik.values.cagent_financial_data,
        {
            data_type: "CARD",
            iban_number: "",
            mfo: "",
            bank_name: "",
            card_number: "",
            description: "",
        }
        ];

        formik.setFieldValue('cagent_financial_data', data);
    }

    const mainToolbarLeftTemplate = () => {
        return (<h3>Створення нового контрагента</h3>);
    }

    const mainToolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Зберегти" severity="success" className={commonStyle.addButton} type="submit" />
            <Button label="Вийти" severity="secondary" type="button" onClick={ReturnButtonHandler} className={commonStyle.closeButton} />
        </div>)
    }

    return (<form onSubmit={formik.handleSubmit}>
        <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate}></Toolbar>
        <div className="grid nested-grid">
            <div className={`${commonStyle.leftMenu} col-3`}>
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex flex-column gap-1">
                        <FloatLabel>
                            <Dropdown name="division" value={formik.values.division} onChange={(e) => formik.setFieldValue('division', e.value)} options={Object.values(ctx.divisions)} showClear className="w-full" />
                            <label>Підрозділ</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className={commonStyle.radioButtonBlock}>
                    <div className={commonStyle.accountingRadio}>
                        <RadioButton value="INDIVIDUAL" checked={formik.values.type === "INDIVIDUAL"} onChange={(e) => { formik.setFieldValue('type', "INDIVIDUAL"); }} />
                        <label className="ml-2">Фізична особа</label>
                    </div>
                    <div className={commonStyle.accountingRadio}>
                        <RadioButton value="LEGAL" checked={formik.values.type === "LEGAL"} onChange={(e) => { formik.setFieldValue('type', "LEGAL"); }} />
                        <label className="ml-2">Юридична особа</label>
                    </div>
                </div>
                {formik.values.type === "INDIVIDUAL" && (<>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="last_name"
                                    value={formik.values.individual_cagent_data.last_name}
                                    onChange={(e) => { formik.setFieldValue('individual_cagent_data.last_name', e.target.value) }}
                                    className={formik.errors.indLastName && formik.touched.individual_cagent_data?.last_name ? 'p-invalid' : ''}
                                />
                                <label>Прізвище контрагента</label>
                            </FloatLabel>
                            {formik.errors.indLastName && formik.touched.individual_cagent_data?.last_name && (<small className={commonStyle.errorSmall}>{formik.errors.indLastName}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="first_name"
                                    value={formik.values.individual_cagent_data.first_name}
                                    onChange={(e) => { formik.setFieldValue('individual_cagent_data.first_name', e.target.value) }}
                                    className={formik.errors.indFirstName && formik.touched.individual_cagent_data?.first_name ? 'p-invalid' : ''}
                                />
                                <label>Імя контрагента</label>
                            </FloatLabel>
                            {formik.errors.indFirstName && formik.touched.individual_cagent_data?.first_name && (<small className={commonStyle.errorSmall}>{formik.errors.indFirstName}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="surname"
                                    value={formik.values.individual_cagent_data.surname}
                                    onChange={(e) => { formik.setFieldValue('individual_cagent_data.surname', e.target.value) }}
                                />
                                <label>По-батькові контрагента</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <Calendar
                                    value={formik.values.individual_cagent_data.date_of_birth}
                                    onChange={(e) => { formik.setFieldValue('individual_cagent_data.date_of_birth', e.target.value) }}
                                    showIcon
                                />
                                <label>Дата народження</label>
                            </FloatLabel>
                        </div>
                    </div>
                </>)}
                {formik.values.type === "LEGAL" && (<>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="long_name"
                                    value={formik.values.legal_cagent_data.long_name}
                                    onChange={(e) => { formik.setFieldValue('legal_cagent_data.long_name', e.target.value) }}
                                    className={formik.errors.legalLongName && formik.touched.legal_cagent_data?.long_name ? 'p-invalid' : ''}
                                />
                                <label>Повна назва контрагента</label>
                            </FloatLabel>
                            {formik.errors.legalLongName && formik.touched.legal_cagent_data?.long_name && (<small className={commonStyle.errorSmall}>{formik.errors.legalLongName}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="short_name"
                                    value={formik.values.legal_cagent_data.short_name}
                                    onChange={(e) => { formik.setFieldValue('legal_cagent_data.short_name', e.target.value) }}
                                    className={formik.errors.legalShortName && formik.touched.legal_cagent_data?.short_name ? 'p-invalid' : ''}
                                />
                                <label>Скорочена назва контрагента</label>
                            </FloatLabel>
                            {formik.errors.legalShortName && formik.touched.legal_cagent_data?.short_name && (<small className={commonStyle.errorSmall}>{formik.errors.legalShortName}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="representative"
                                    value={formik.values.legal_cagent_data.representative}
                                    onChange={(e) => { formik.setFieldValue('legal_cagent_data.representative', e.target.value) }}
                                />
                                <label>Представник</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="edrpou"
                                    value={formik.values.legal_cagent_data.edrpou}
                                    onChange={(e) => { formik.setFieldValue('legal_cagent_data.edrpou', e.target.value) }}
                                    className={formik.errors.legalEdrpou && formik.touched.legal_cagent_data?.edrpou ? 'p-invalid' : ''}
                                />
                                <label>ЄДРПОУ</label>
                            </FloatLabel>
                            {formik.errors.legalEdrpou && formik.touched.legal_cagent_data?.edrpou && (<small className={commonStyle.errorSmall}>{formik.errors.legalEdrpou}</small>)}
                        </div>
                    </div>
                </>)}
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <InputText
                                name="email"
                                value={formik.values.email}
                                onChange={(e) => { formik.setFieldValue('email', e.target.value) }}
                            />
                            <label>Email</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <InputText
                                name="phone"
                                value={formik.values.phone}
                                onChange={(e) => { formik.setFieldValue('phone', e.target.value) }}
                            />
                            <label>Телефон</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className={commonStyle.radioButtonBlock}>
                    <div className={commonStyle.accountingRadio}>
                        <Checkbox value="isPartner" checked={formik.values.is_partner}
                            onChange={(e) => { formik.setFieldValue('is_partner', !formik.values.is_partner) }}
                            className={formik.errors.WrongContrAgentType && (formik.touched.is_client || formik.touched.is_partner) ? 'p-invalid' : ''} />
                        <label className="ml-2">Постачальник</label>
                    </div>
                    <div className={commonStyle.accountingRadio}>
                        <Checkbox value="isClient" checked={formik.values.is_client}
                            onChange={(e) => { formik.setFieldValue('is_client', !formik.values.is_client) }}
                            className={formik.errors.WrongContrAgentType && (formik.touched.is_client || formik.touched.is_partner) ? 'p-invalid' : ''} />
                        <label className="ml-2">Клієнт</label>
                    </div>
                    {formik.errors.WrongContrAgentType && (formik.touched.is_client || formik.touched.is_partner) && (<small className={commonStyle.errorSmall}>{formik.errors.WrongContrAgentType}</small>)}
                </div>
            </div>
            <div className="col-9">
                <Menubar model={menuItems} />
                {!showFinData && (<><div className="grid">
                    <div className="col-4">
                        <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                            <FloatLabel>
                                <Dropdown name="country" value={formik.values.country}
                                    onChange={(e) => formik.setFieldValue('country', e.value)}
                                    options={countries}
                                    className={formik.errors.country ? 'p-invalid' : ''}
                                    showClear />
                                <label>Країна</label>
                            </FloatLabel>
                            {formik.errors.country && (<small className={commonStyle.errorSmall}>{formik.errors.country}</small>)}
                        </div>
                        <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                            <FloatLabel>
                                <InputText value={formik.values.street} onChange={(e) => { formik.setFieldValue('street', e.target.value) }} />
                                <label>Вулиця</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                            <FloatLabel>
                                <Dropdown name="region" value={formik.values.region}
                                    onChange={(e) => formik.setFieldValue('region', e.value)}
                                    options={Object.values(ctx.regions)}
                                    className={formik.errors.region && formik.touched.region ? 'p-invalid' : ''}
                                    showClear />
                                <label>Область</label>
                            </FloatLabel>
                            {formik.errors.region && formik.touched.region && (<small className={commonStyle.errorSmall}>{formik.errors.region}</small>)}
                        </div>
                        <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                            <FloatLabel>
                                <InputText value={formik.values.building_number} onChange={(e) => { formik.setFieldValue('building_number', e.target.value) }} />
                                <label>Номер будинку</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                            <FloatLabel>
                                <InputText value={formik.values.city}
                                    onChange={(e) => { formik.setFieldValue('city', e.target.value) }}
                                    className={formik.errors.city && formik.touched.city ? 'p-invalid' : ''} />
                                <label>Населений пункт</label>
                            </FloatLabel>
                            {formik.errors.city && formik.touched.city && (<small className={commonStyle.errorSmall}>{formik.errors.city}</small>)}
                        </div>
                        <div className={`${commonStyle.inputfields} p-inputgroup`}>
                            <FloatLabel>
                                <InputText value={formik.values.apartment_number} onChange={(e) => { formik.setFieldValue('apartment_number', e.target.value) }} />
                                <label>Квартира</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>
                    <div className={`${commonStyle.inputfields} p-inputgroup col-8`}>
                        <span className="p-float-label md:w-90">
                            <InputTextarea value={formik.values.description} onChange={(e) => { formik.setFieldValue('description', e.target.value) }} rows={10} cols={80} />
                            <label>Примітки</label>
                        </span>
                    </div>
                </>)}
                {showFinData && (<>
                    {(formik.values.cagent_financial_data.map((finData, index) => (
                        <div className="grid" key={index}>
                            <div className="col-4">
                                {finData.data_type === "IBAN" && <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                                    <FloatLabel>
                                        <InputText value={finData.iban_number || ""} 
                                        onChange={(e) => formik.setFieldValue(`cagent_financial_data[${index}].iban_number`, e.target.value)} />
                                        <label>Номер IBAN</label>
                                    </FloatLabel>
                                </div>}
                                {finData.data_type === "CARD" && <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                                    <FloatLabel>
                                        <InputText value={finData.card_number || ""} 
                                        onChange={(e) => { formik.setFieldValue(`cagent_financial_data[${index}].card_number`, e.target.value) }} />
                                        <label>Номер картки</label>
                                    </FloatLabel>
                                </div>}
                            </div>
                            <div className="col-4">
                                {finData.data_type === "IBAN" && <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                                    <FloatLabel>
                                        <InputText value={finData.bank_name || ""} 
                                        onChange={(e) => { formik.setFieldValue(`cagent_financial_data[${index}].bank_name`, e.target.value) }} />
                                        <label>Назва банку</label>
                                    </FloatLabel>
                                </div>}
                            </div>
                            <div className="col-3">
                                {finData.data_type === "IBAN" && <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                                    <FloatLabel>
                                        <InputText value={finData.mfo || ""} 
                                        onChange={(e) => { formik.setFieldValue(`cagent_financial_data[${index}].mfo`, e.target.value) }} />
                                        <label>МФО</label>
                                    </FloatLabel>
                                </div>}
                            </div>
                            <div className="col-1">
                                <Button
                                    icon="pi pi-trash"
                                    className={`p-button-rounded p-button-danger ${commonStyle.deleteButton}`}
                                    style={{ marginTop: "2rem" }}
                                    tooltip="Видалити"
                                    tooltipOptions={{ showOnDisabled: true }}
                                    type="button"
                                    onClick={() => {
                                        const newArray = formik.values.cagent_financial_data.filter((_, i) => i !== index);
                                        formik.setFieldValue('cagent_financial_data', newArray);
                                    }}
                                />
                            </div>
                        </div>
                    )))}
                    <div className="col-12">
                        <Button icon="pi pi-credit-card" label="Додати картку" severity="success" className={`${commonStyle.addButton} ${commonStyle.addButtonNav}`} onClick={AddCardButtonHandler} type="button" />
                        <Button icon="pi pi-money-bill" label="Додати рахунок" severity="success" className={commonStyle.addButton} onClick={AddIBANButtonHandler} type="button" />
                    </div>
                    <div className={`${commonStyle.inputfields} p-inputgroup col-8`}>
                        <span className="p-float-label md:w-90">
                            <InputTextarea value={formik.values.cagent_financial_data.description} onChange={(e) => { formik.setFieldValue('cagent_financial_data.description', e.target.value) }} rows={10} cols={80} />
                            <label>Примітки</label>
                        </span>
                    </div>
                </>)}
            </div>
        </div>
    </form>
    );
}

export default NewCounterAgent;